<template>

<div class="panel">

	<com-chat />
	<com-announcements />
    <com-marking v-if="hasMarking && isAccepted && $isEventMode" />
    <com-users v-if="$isWebinar && isLead" />
    <com-extend v-if="canExtend" />

</div>

</template>

<script>

import comChat from './panel/Chat';
import comMarking from './panel/Marking'
import comUsers from './panel/Users'
import comAnnouncements from './panel/Announcements'
import comExtend from './panel/Extend'

export default {

	components: {
		'com-chat': comChat,
		'com-marking': comMarking,
		'com-users': comUsers,
		'com-announcements': comAnnouncements,
		'com-extend': comExtend
	},

	computed: {

        hasMarking: function() {

            return (this.isExaminer || this.isLayExaminer) && this.$store.getters['event/marking/enabled']

        },

		canExtend: function() {

			return this.isActive && this.hasPermission(this.$constants.observer.permission.extend)

		}

	}

}

</script>