<template>

<com-card :onboardingPrefix="onboardingPrefix" class="file" :expanded="expanded" :class="{'is-image': item.image, 'is-video': item.video}" v-on:expand="$emit('expand')" :type="$constants.card.type.file" :expandable="true" :title="item.title" :subtitle="item.subtitle">

	<iframe class="file-iframe" :src="item.iframe" v-if="item.iframe" />

	<com-shredded :shredded="item.shredded" :showNote="showNote" v-if="item.shredded" />

	<img class="file-image" :src="item.image" v-if="item.image" />

	<video class="file-video" controls v-if="item.video">
		<source :src="item.video" type="video/mp4">
	</video>

</com-card>

</template>

<script>

import comCard from './Card'
import comShredded from './../common/Shredded'

export default {

	props: ['item', 'showNote', 'expanded', 'onboardingPrefix'],

	components: {
		'com-card': comCard,
		'com-shredded': comShredded
	}

}

</script>

<style scoped>

.file {
	background-color: #fff;
}

.file.is-image >>> .card-body,
.file.is-video >>> .card-body {
	background-color: #000;
	cursor: pointer;
}

.file-iframe {
	width: 100%;
	height: 100%;
}

.file-image {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.file-video {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>