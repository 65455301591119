<template>

<div class="paused">


	<div class="paused-icon"><i class="fa fa-pause-circle"></i></div>

	<div class="paused-text" v-if="!isEnding">Event paused<small>We have needed to pause the exam. We will update you shortly. Your exam time will not be affected.</small></div>
	<div class="paused-text" v-if="isEnding">Event resuming in {{ resuming }}<small>The exam will resume automatically at the same moment it was paused at.</small></div>

	<div class="paused-messages" v-if="messages.length">

		<div class="paused-messages-head">Updates</div>

		<div class="paused-messages-item" v-for="(message, index) in messages" :key="index">

			<div class="paused-messages-item-text">{{ message.text }}</div>
			<div class="paused-messages-item-time">{{ message.time | fromNow }}</div>

		</div>

	</div>

</div>

</template>s

<script>

export default {

	computed: {

		messages: function() {

			return this.$store.getters['event/paused/updates']

		},

		isEnding: function() {

			return this.$store.getters['event/paused/end']

		},

		resuming: function() {

			return (this.isEnding) ? this.countdown(this.isEnding, false, true) : false

		}

	}

}

</script>

<style scoped>

.paused {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.paused-icon {
	font-size: 64px;
	color: #237DC6;
}

.paused-text {
	margin-top: 10px;
	font-size: 24px;
	text-align: center;
	font-weight: 400;
	max-width: 640px;
}

.paused-text small {
	font-size: 16px;
	font-weight: 300;
	margin-top: 4px;
	display: block;
}

.paused-messages {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	max-width: 640px;
	width: 100%;
	overflow: hidden;
}

.paused-messages-head {
	background-color: #237DC6;
	color: #fff;
	padding: 10px;
	border-radius: 4px 4px 0px 0px;
	font-weight: 400;
}

.paused-messages-item {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 10px;
	border: 1px solid #237DC6;
	border-top: 0px;
}

.paused-messages-item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.paused-messages-item-text {
	color: #333;
	border-radius: 4px;
	font-weight: 400;
	width: 100%;
}

.paused-messages-item-time {
	font-size: 12px;
	color: #333;
	font-weight: 300;
	margin-top: 5px;
	text-align: left;
}

</style>
