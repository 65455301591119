<template>
    
<div class="marking" :style="styleMarking" :class="{'is-active': is.expanded}">

	<div class="marking-buttons">

		<div class="marking-buttons-item" v-if="is.expanded && !currentSheet && canChange && canClose" v-on:click="onExpandClick" v-tooltip.left="'Close marking panel'"><i class="fa fa-remove"></i></div>
		<div class="marking-buttons-item" :class="{'is-saved': isSaved}" v-if="!is.questionExpanded && is.expanded && currentSheet && canChange" v-on:click="onBackClick" v-tooltip.left="'View all candidates'"><i class="fa fa-caret-left"></i></div>
		<div class="marking-buttons-item" :class="{'is-saved': isSaved}" v-if="is.questionExpanded && is.expanded && currentSheet" v-on:click="onCollapseClick" v-tooltip.left="'Back to questions'"><i class="fa fa-caret-left"></i></div>

	</div>

	<div class="marking-content">

		<template v-for="identity in candidates">

			<com-sheet v-on:expand="is.questionExpanded = $event" v-on:collapse="is.questionExpanded = false" :expanded="is.questionExpanded" :candidate="sheetCandidate(identity)" :identity="identity" :key="identity" :validSave="validSave[identity]" :session="sheets.session[identity]" :saved="sheets.saved[identity]" v-if="currentSheet === identity" v-on:saved="onSheetSaved" v-on:change="onSheetChanged" />

		</template>

		<com-candidates :validSave="validSave" :session="sheets.session" :saved="sheets.saved" v-if="!currentSheet" v-on:change="onCandidateChange" />

	</div>

	<div class="marking-resize" v-tooltip="'Resize marking'" v-on:mousedown="onResizeDown" v-if="is.expanded"><i class="fa fa-arrows-h"></i></div>

</div>

</template>

<script>

import Vue from 'vue'
import comSheet from './marking/Sheet'
import comCandidates from './marking/Candidates'

export default {

    components: {
		'com-sheet': comSheet,
		'com-candidates': comCandidates
	},

	data: function() {

		return {
			currentSheet: false,
			sheets: {
				saved: {},
				session: {}
			},
			is: {
				resizing: false,
				questionExpanded: false,
				expanded: false
			},
			view: 'candidates'
		}

	},

	created: function() {

		this.sheets.saved = JSON.parse(JSON.stringify(this.$store.getters['marking/saved']))
		this.sheets.session = JSON.parse(JSON.stringify(this.$store.getters['marking/session']))

		if (this.isStation) {

			this.currentSheet = (this.currentSchedule.candidate) ? this.currentSchedule.identity : false
			this.is.expanded = true

		}	

		this.$pubsub.$on('marking.toggle', this.onExpandClick.bind(this))
		
		window.addEventListener('mousemove', this.onResizeMove.bind(this))
		window.addEventListener('mouseup', this.onResizeUp.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('marking.toggle', this.onExpandClick.bind(this))
		
		window.removeEventListener('mousemove', this.onResizeMove.bind(this))
		window.removeEventListener('mouseup', this.onResizeUp.bind(this))

	},

	watch: {

		currentSchedule: function(n, o) {

			if (n) {

				if (n.identity !== o.identity) {

					this.is.expanded = false

				}

			} else {

				if (this.isSaved) this.is.expanded = false

			}

		},

		isRoleplaying: function(n) {

			if (n) {
				
				this.currentSheet = (this.currentSchedule.candidate) ? this.currentSchedule.identity : false
				this.is.expanded = true

			}

		}

	},

	computed: {

		candidates: function() {

			var candidates = []

			this.$_.each(this.$store.getters['marking/candidates'], function(candidate) {

				candidates.push(candidate.identity)

			}.bind(this))

			return candidates


		},

		canChange: function() {

			return !this.isRoleplaying || !this.currentSchedule.candidate

		},

		canClose: function() {

			return !this.isRoleplaying 

		},

		isRoleplaying: function() {

			if (this.currentSchedule) {

				if (this.currentSchedule.type === this.$constants.schedule.type.station) {

					return this.time >= this.currentSchedule.roleplay && this.$isActiveReady

				} else {

					return false

				}

			} else {

				return false

			}

		},

		currentSchedule: function() {

			return this.$store.getters['schedule/active']

		},

		isSaved: function() {

			if (this.currentSheet) {

				return this.validSave[this.currentSheet] && this.$_.isEqual(this.sheets.saved[this.currentSheet], this.sheets.session[this.currentSheet])

			} else {

				return false

			}

		},

		validSave: function() {

			var valid = {}

			this.$_.each(this.sheets.saved, function(sheet, schedule) {

				var total = 0
				var answered = 0

				this.$_.each(this.$store.getters['marking/questions'][schedule], function(question) {

					if (question.required) {

						total++

						if (sheet[question.identity] !== null) {

							answered++

						}

					}

				}.bind(this))

				Vue.set(valid, schedule, total === answered)

			}.bind(this))

			return valid

		},

		styleMarking: function() {

			var width = this.$store.getters['marking/width']

			return {
				width: width.toString() + 'px',
				right: (0 - width).toString() + 'px',
			}

		}

	},

	methods: {

		onResizeDown: function() {

			this.is.resizing = true

		},

		onResizeMove: function(e) {

			if (this.is.resizing) {

				var width = this.window.width - e.x
				if (width > 512) width = 512
				if (width < 256) width = 256

				this.$store.commit('marking/width', width)

			}

		},

		onResizeUp: function() {

			this.is.resizing = false

		},

		sheetCandidate: function(identity) {

			return (this.$store.getters['schedule/associated'][identity]) ? this.$store.getters['schedule/associated'][identity].candidate : false

		},

		onExpandClick: function() {

			if (!this.is.expanded) {

				this.currentSheet = false

			}

			this.is.expanded = !this.is.expanded

		},

		onBackClick: function() {

			this.currentSheet = false

		},

		onCollapseClick: function() {

			console.log(this.is.questionExpanded)
			this.is.questionExpanded = false
			
		},

		onSheetSaved: function(e) {

			// this.sheets.saved[e.schedule] = e.sheet
			Vue.set(this.sheets.saved, e.schedule, e.sheet)

			this.$store.commit('marking/marks', {
                identity: e.schedule,
                marks: e.sheet
            })

		},

		onSheetChanged: function(e) {

			// this.sheets.session[e.schedule] = e.sheet
			Vue.set(this.sheets.session, e.schedule, e.sheet)

			this.$store.commit('marking/session', {
                identity: e.schedule,
                marks: e.sheet
            })

		},

		onCandidateChange: function(identity) {

			this.currentSheet = identity

		}

	}
	
}

</script>

<style scoped>

.marking-resize {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: -20px;
	width: 20px;
	height: 40px;
	margin-top: -20px;
	background-color: #89b3d5;
	color: #fff;
	line-height: 40px;
	text-align: center;
	border-radius: 10px 0px 0px 10px;
	cursor: ew-resize;
}

.marking {
	position: fixed;
	top: 0px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
	user-select: none;
}

.marking.is-active {
    right: 0px!important;
}

.marking-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.marking-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #3b8ed2;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.marking:not(.is-active) .marking-buttons-item {
    background-color: #237DC6;
}

.marking-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.marking:not(.is-active) .marking-buttons-item:not(.is-active):hover {
    background-color: #3b8ed2;
}

.marking-buttons-item.is-saved {
    background-color: #38b56a;
}

.marking-buttons-item.is-saved:hover {
    background-color: #48cc7d;
}

.marking-content {
	width: 100%;
	height: 100%;
}

</style>