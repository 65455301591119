<template>

<div class="icons" :onboarding="onboarding">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['onboarding']

}

</script>

<style scoped>

.icons {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}

</style>
