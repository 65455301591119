<template>

<button class="button" v-on:click="onClick" :class="{'is-small': small, 'is-loading': loading, 'is-disabled': disabled, [themeClass]: true}"><span><slot></slot></span></button>

</template>

<script>

export default {

	props: ['loading', 'disabled', 'theme', 'small'],

	computed: {

		themeClass: function() {

			return (this.theme) ? 'theme-' + this.theme : 'theme-blue'

		}

	},

	methods: {

		onClick: function() {

			this.$emit('click')

		}

	}

}

</script>

<style scoped>

.button {
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	font-weight: 400;
	cursor: pointer;
	padding: 0px 20px;
	text-transform: uppercase;
}

.button.is-small {
	line-height: 32px;
}

.button.is-loading {
	pointer-events: none;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.button.is-loading span {
	visibility: hidden;
}

.button.is-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.button.theme-blue {
	background-color: #237DC6;
	color: #fff;
}

.button.theme-blue:hover {
	background-color: #1b649e;
}

.button.theme-blue.is-loading {
	background-color: #1b649e;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAABtknv///zt5q0R/ryxwpfj6+////9Ti7ImvzKjD2VeNt9/p8bvR4oKqyaTB2FSKttzm7/r7/LjO4DBypzx7rCNqocvc6Dl4qiVroluPuW+cwUmDsSltpAAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.button.theme-white {
	background-color: #ffffff;
	color: #237DC6;
}

.button.theme-white:hover {
	background-color: #ffffff;
}

.button.theme-white.is-loading {
	background-color: #ffffff;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.button.theme-red {
	background-color: #c62323;
	color: #fff;
}

.button.theme-red:hover {
	background-color: #b01818;
}

.button.theme-red.is-loading {
	background-color: #c62323;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.button.theme-yellow {
	background-color: #FBB516;
	color: #fff;
}

.button.theme-yellow:hover {
	background-color: #cb9722;
}

.button.theme-yellow.is-loading {
	background-color: #FBB516;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

</style>
