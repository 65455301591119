<template>
    
<div class="question" :class="{'is-answered': isAnswered, 'is-expanded': expanded, 'is-row': question.type === $constants.marking.question.type.check}">

    <div class="question-text" v-html="question.text" v-if="!expanded"></div>

    <div class="question-scale" v-if="question.type === $constants.marking.question.type.scale">

        <div class="question-scale-item" :class="{'is-active': parseInt(value) - 1 === answer}" v-for="value in question.scale + 1" :key="value - 1" v-on:click="onScaleClick(parseInt(value) - 1)">
            {{ value - 1 }}
        </div>

    </div>

    <div class="question-check" v-if="question.type === $constants.marking.question.type.check" :class="{'is-active': answer}" v-on:click="onCheckClick(parseInt(value))">
        <i class="fa fa-check"></i>    
    </div>

    <div class="question-toggle" :class="{'is-stacked': question.options.length > 4}" v-if="isToggle">

        <div class="question-toggle-item" :class="{'is-active': isValue(option.value)}" v-for="(option, index) in question.options" :key="index" v-on:click="onToggleClick(option.value)">
            {{ option.text }}
        </div>

    </div>

	<div class="question-dropdown" v-if="isDropdown">

		<div class="question-dropdown-value" :class="{'is-active': answer}" v-on:click="onToggleDropdown" v-if="!expanded">
			
			<template v-if="valueText && !isMulti">{{ valueText }}</template>
			<template v-if="isMulti">{{ answer.length }} / {{ question.options.length }} selected</template>
			<template v-if="!valueText && !isMulti">Nothing selected.</template>

			<small>Click to change</small>

		</div>

		<div class="question-dropdown-options" v-if="expanded">

			<div v-on:click="onDropdownClick(option.value)" class="question-dropdown-options-item" :class="{'is-active': isValue(option.value)}" v-for="(option, index) in question.options" :key="index">
				
				<div class="question-dropdown-options-item-icon"><i class="fa fa-check-circle"></i></div>
				<div class="question-dropdown-options-item-text">{{ option.text }}</div>
				
			</div>

		</div>

	</div>

    <textarea v-autogrow ref="textarea" class="question-textarea" v-on:contextmenu="$allowContext" spellcheck="false" v-if="question.type === $constants.marking.question.type.text" :class="{'is-active': answer}" v-on:change="onTextChange" v-on:keydown="onTextChange" v-on:up="onTextChange" v-model="answer"></textarea>

</div>

</template>

<script>

import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'

export default {

    props: ['question', 'value', 'expanded'],

    directives: {
		'autogrow': TextareaAutogrowDirective
    },

    data: function() {

        return {
			is: {
				expanded: false
			},
			text: '',
            answer: false
        }

    },

	computed: {

		isAnswered: function() {

			return (this.isMulti) ? this.answer.length : this.answer

		},

		valueText: function() {

			if (!this.answer || (!this.isToggle && !this.isDropdown) || this.isMulti) return false

			return this.$_.findWhere(this.question.options, {
				value: this.answer
			}).text

		},

		isMulti: function() {

			return this.question.multi

		},

		isToggle: function() {

			return this.$_.contains([
				this.$constants.marking.question.type.toggle,
				this.$constants.marking.question.type.pbf,
				this.$constants.marking.question.type.epbf,
				this.$constants.marking.question.type.cpfc,
				this.$constants.marking.question.type.cpbbfc,
				this.$constants.marking.question.type.ucbpc
			], this.question.type)

		},

		isDropdown: function() {

			return this.$_.contains([
				this.$constants.marking.question.type.dropdown
			], this.question.type)

		}

	},

    watch: {

        answer: function() {

			var answer = this.answer

			if (this.isMulti && this.$_.isArray(answer)) {

				answer = (answer.length) ? answer.join(',') : null

			}

            this.$emit('change', {
                identity: this.question.identity,
                value: answer
            })

        },
        
        value: function() {

			this.applyValue()

        }

    },

    created: function() {

		this.applyValue()

    },

    methods: {

		applyValue: function() {

			if (this.isMulti) {

				this.answer = (this.value === null) ? [] : this.value.toString().split(',')

				this.answer = this.answer.map(function (x) { 
					return parseInt(x); 
				})

			} else {

				this.answer = this.value

			}

			this.text = this.value

		},

		onToggleDropdown: function() {

			this.$emit('expand')

		},

		isValue: function(value) {

			if (this.isMulti) {

				return this.$_.contains(this.answer, parseInt(value))

			} else {

				return parseInt(value) === this.answer

			}

		},

		onDropdownClick: function(value) {

			value = parseInt(value)

			if (this.isMulti) {

				if (this.$_.contains(this.answer, value)) {

					this.answer.splice(this.answer.indexOf(value), 1)

				} else {

					this.answer.push(value)

				}

			} else {

				this.answer = (this.isValue(value)) ? null :value

				this.$emit('collapse')

			}

		},

        onCheckClick: function() {

            this.answer = !this.answer

        },

        onToggleClick: function(value) {

            this.answer = (this.isValue(value)) ? null : parseInt(value)

        },

		onScaleClick: function(value) {

            this.answer = (value === this.answer) ? null : value

        },

        onTextChange: function() {

            this.answer = this.$refs.textarea.value

        }

    }

}

</script>

<style scoped>

.question {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
    user-select: none;
    transition: background-color 100ms linear;
}

.question.is-expanded {
	padding: 0px;
}

.question.is-row {
    flex-direction: row;
}

.question:not(.is-expanded).is-answered {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid transparent;
}

.question-text {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.question-text >>> p,
.question-text >>> ul {
	margin-bottom: 16px;
}

.question-text >>> li {
	margin-bottom: 16px;
}

.question-text >>> li:last-child,
.question-text >>> p:last-child,
.question-text >>> ul:last-child {
	margin-bottom: 0px;
}

.question.is-answered .question-text {
    color: rgba(255, 255, 255, 0.75);
}

.question.is-row .question-text {
    flex-grow: 1;
    margin-bottom: 0px;
}

.question-textarea {
    width: 100%;
    min-height: 96px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    resize: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #fff;
    padding: 8px 10px;
    transition: all 100ms linear;
}

.question-textarea.is-active {
    background-color: #fff;
    color: #333;
}

.question-check {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    line-height: 34px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    transition: all 100ms linear;
}

.question-check:hover,
.question-check.is-active {
    background-color: #fff;
    color: #237DC6;
}

.sheet.is-saved .question-check.is-active {
    color: #27a258;
}

.question-toggle,
.question-scale {
    display: flex;
    flex-direction: row;
    height: 32px;
}

.question-toggle.is-stacked {
	flex-direction: column;
	height: auto;
}

.question-toggle-item,
.question-scale-item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 1px solid #237DC6;
    cursor: pointer;
    transition: all 100ms linear;
}

.sheet.sheet.is-saved .question-toggle-item,
.sheet.sheet.is-saved .question-scale-item {
	border-color: #27a258;
}

.question.is-answered .question-toggle-item:not(.is-active),
.question.is-answered .question-scale-item:not(.is-active) {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.75);
}

.question-toggle-item.is-active,
.question-toggle-item:hover,
.question-scale-item.is-active,
.question-scale-item:hover {
    background-color: #fff;
    color: #237DC6;
}

.sheet.is-saved .question-toggle-item:hover,
.sheet.is-saved .question-scale-item:hover,
.sheet.is-saved .question-toggle-item.is-active,
.sheet.is-saved .question-scale-item.is-active {
    color: #27a258;
}

.question.is-answered .question-toggle-item:not(.is-active):hover,
.question.is-answered .question-scale-item:not(.is-active):hover {
    background-color:rgba(0, 0, 0, 0.1);
}

.question-toggle-item:first-child,
.question-scale-item:first-child {
    border-radius: 4px 0px 0px 4px;
}

.question-toggle-item:last-child,
.question-scale-item:last-child {
    border-radius: 0px 4px 4px 0px;
}

.question-toggle-item.is-active,
.question-scale-item.is-active {
    border-radius: 4px;
}

.question-dropdown-value {
    line-height: 18px;
	padding: 7px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
	border-radius: 4px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 1px solid #237DC6;
    cursor: pointer;
    transition: all 100ms linear;
}

.question-dropdown-value.is-active {
	background-color: #fff;
    color: #237DC6;
}

.sheet.is-saved .question-dropdown-value.is-active {
    color: #27a258;
}

.question-dropdown-value small {
	font-size: 11px;
	display: block;
	opacity: 0.75;
	line-height: 11px;
}

.question-dropdown-value:not(.is-active):hover {
    background-color:rgba(0, 0, 0, 0.2);
}

.question-dropdown-options-item {
	padding: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	cursor: pointer;
}

.question-dropdown-options-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.question-dropdown-options-item.is-active {
	background-color: rgba(0, 0, 0, 0.3);
}

.question-dropdown-options-item-icon {
	font-size: 24px;
	margin-right: 10px;
	color: rgba(255, 255, 255, 0.15);
}

.question-dropdown-options-item.is-active .question-dropdown-options-item-icon {
	color: #fff;
}

.question-dropdown-options-item-text {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	line-height: 18px;
}

</style>