<template>

<div class="schedule" onboarding="sidebar.schedule">

	<div class="schedule-item schedule-item-head">

		<div class="schedule-item-name">Schedule</div>

	</div>

	<app-scroll class="schedule-scroll" :class="{'has-online': schedule.chaperoned}">

		<div :onboarding="'sidebar.schedule.' + item.id" v-for="(item, index) in schedule" :key="index" class="schedule-item" :class="{'is-break': item.type === 'break', 'is-next': item.status === 'next' || item.status === 'current', 'is-completed': item.status === 'completed'}">
		
			<div class="schedule-item-name">{{ item.name }}</div>
			<div class="schedule-item-status">{{ itemStatus(item) }}</div>

			<div class="schedule-item-check fa fa-check-circle" v-if="item.status === 'completed'"></div>

			<div class="schedule-item-check fa fa-coffee" v-if="item.status !== 'completed' && item.type === 'break'"></div>

		</div>

	</app-scroll>

	<div class="schedule-item is-online" v-if="schedule.chaperoned">

		<com-online :current="schedule" />

	</div>

</div>

</template>

<script>

import comOnline from './Online'

export default {

	props: ['schedule', 'initTime'],

	components: {
		'com-online': comOnline
	},

	methods: {

		itemStatus: function(item) {

			var time = item.time + this.initTime

			if (item.status === 'next') {

				var difference = time - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				}

				return 'Begins in ' + remaining

			} else if (item.status === 'completed') {

				return 'Completed'

			} else if (item.status === 'current') {

				return 'In progress'

			} else {

				return this.$moment.unix(time).utc().tz('Europe/London').format('h:mma')

			}

		}

	}

}

</script>

<style scoped>

.schedule {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 4px;
	user-select: none;
	margin-top: 10px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.schedule-scroll {
	overflow: hidden;
	flex-grow: 1;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
	border-top: 0px;
}

.schedule-scroll.has-online {
	border-radius: 0px;
	border-bottom-width: 0px;
}

.schedule-item {
	color: #333;
	padding: 10px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #ddd;
}

.schedule-item.is-break {
	background-color: #f1f1f1;
}

.schedule-item:last-child {
	border-radius: 0px 0px 4px 4px;
	border-bottom: 0px;
}

.schedule-item-head {
	border-top: 0px;
	background-color: #237DC6;
	color: #fff;
	border-color: #237DC6;
	height: 40px;
}

.schedule-item-name {
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 4px;
}

.schedule-item-head .schedule-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

.schedule-item-status {
	font-size: 12px;
	font-weight: 300;
}

.schedule-item.is-completed {
	color: #999;
}

.schedule-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #10ab6b;
}

.schedule-item-check.fa-coffee {
	color: #237DC6;
	font-size: 20px;
	margin-top: -8px;
}

.schedule-item-countdown {
	font-size: 16px;
	line-height: 36px;
	font-weight: 300;
	color: #666;
	position: absolute;
	right: 10px;
	top: 10px;
}

.schedule-item.is-online {
	height: auto;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
}

</style>
