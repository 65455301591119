<template>

<div class="examiners">

	<div v-for="(examiner, index) in examiners" class="examiners-item" :key="index" :class="{'has-colour': $colour}" :style="{borderColor: $colour}">

		<div class="examiners-item-head">
			
			<div class="examiners-item-head-text">{{ emptyCheck(examiner.name, 'No name') }} <small>{{ emptyCheck(examiner.label, 'No Label') }}</small></div>
			
		</div>

		<div class="examiners-item-sheets">

			<div class="examiners-item-sheet" :data-status="status(sheet)" v-for="sheet in sheets(examiner.identity)" :key="sheet.identity">

				<div class="examiners-item-sheet-name">{{ name(sheet.candidate) }} <small>{{ status(sheet, true) }}</small></div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			timer: false
		}

	},

	created: function() {

		this.update()

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	computed: {

		people: function() {

			return this.$_.filter(this.$store.getters['people'], function(item) {

				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false

			}.bind(this))

		},

		examiners: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.examiner || person.role === this.$constants.role.layexaminer) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		}

	},

	methods: {

		update: function() {

			clearTimeout(this.timer)

			this.$api.request('marking/status').then(function(json) {

				this.$store.commit('marking/examiners', json.examiners)

				this.timer = this.$_.delay(this.update.bind(this), 30000)

			}.bind(this))

		},

		status: function(sheet, asLabel) {

			var schedule = this.$store.getters['overview/associated'][sheet.identity]
			var status = 0

			asLabel = asLabel || false

			if (schedule.start > this.time) {

				status = this.$constants.marksheet.status.locked
				
			} else if (sheet.status === this.$constants.marksheet.status.saved || sheet.status === this.$constants.marksheet.status.changed) {

				status = sheet.status

			} else {

				if (schedule.end > this.time) {

					status = this.$constants.marksheet.status.active

				} else {

					status = this.$constants.marksheet.status.unsaved

				}

			}

			return (asLabel) ? this.$constants.marksheet.statusLabel[status] : status

		},

		name: function(identity) {

			return this.$store.getters['people'][identity].name || 'No name'

		},

		label: function(identity) {

			return this.$store.getters['people'][identity].label || 'No label'

		},

		sheets: function(id) {

			return this.$store.getters['marking/examiners'][id]

		},

		emptyCheck: function(text, defaultText) {

			return text || defaultText

		}

	}

}

</script>

<style scoped>

.examiners {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
}

.examiners-item {
	display: flex;
	flex-direction: column;
	margin: 0px 10px 10px 0px;
}

.examiners-item.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px;
}

.examiners-item-head {
	background-color: #237DC6;
	color: #fff;
	font-size: 16px;
	height: 48px;
	padding: 0px 0px 0px 10px;
	border-radius: 4px;
	font-weight: 400;
	align-items: center;
	display: flex;
	border-left: 1px solid #fff;
}

.examiners-item-head-text {
	flex-grow: 1;
}

.examiners-item-head-text small {
	display: block;
	font-size: 12px;
	margin-top: 2px;
	font-weight: 300;
}

.examiners-item-head-actions-item {
	width: 48px;
	text-align: center;
	cursor: pointer;
	border-left: 1px solid #fff;
}

.examiners-item-sheets {
	display: flex;
	flex-direction: row;
	border-radius: 0px 0px 4px 4px;
	border-top-width: 0px;
	flex-wrap: wrap;
}

.examiners-item.has-colour .examiners-item-sheets {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

.examiners-item-sheet {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	height: 48px;
	border-radius: 4px;
	width: 20%;
	padding: 0px 10px;
	border-left: 1px solid #fff;
	border-top: 1px solid #fff;
	color: #fff;
}

.examiners-item-sheet[data-status="2"] {
	background-color: #27a258;
}

.examiners-item-sheet[data-status="1"] {
	background-color: #1c6aa9;
	color: #5ea9e6;
}

.examiners-item-sheet[data-status="3"] {
	background-color: #cc3939;
}

.examiners-item-sheet[data-status="5"] {
	background-color: #ce9921;
}

.examiners-item-sheet[data-status="4"] {
	background-color: #56aef5;
}

.examiners-item-sheet:nth-child(5n) {
	border-right-width: 0px;
}

.examiners-item-sheet-name {
	font-size: 16px;
	font-weight: 400;
	flex-grow: 1;
}

.examiners-item-sheet-name small {
	display: block;
	font-size: 12px;
	font-weight: 400;
	margin-top: 2px;
}

</style>