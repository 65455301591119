<template>

<div class="rollcall">

	<template v-for="(rollcall, index) in rollcalls">

	<div class="rollcall-item" :key="index" v-if="peopleType(rollcall.type).length" :class="{'has-colour': $colour}" :style="{borderColor: $colour}">

		<div class="rollcall-item-head">{{ rollcall.text }}</div>

		<div class="rollcall-item-people">

			<div class="rollcall-item-person" v-for="(person, index) in peopleType(rollcall.type)" :key="index">

				<div class="rollcall-item-person-name">{{ emptyCheck(person.name, 'No name') }} <small>{{ emptyCheck(person.label, 'No Label') }}</small></div>
				<div class="rollcall-item-person-status" :class="{'is-online': isOnline(person.identity), 'is-ready': isReady(person.identity), 'is-offline': isOffline(person.identity)}"></div>

				<div v-if="person.role !== $constants.role.observer || person.chaperone" class="rollcall-item-person-verify" :class="{'is-verified': isVerified(person)}" v-on:click="onVerifyClick(person.identity)">

					<i class="fa fa-user-check"></i>

				</div>

			</div>

		</div>

	</div>

	</template>

</div>

</template>

<script>

export default {

	data: function() {

		return {

			rollcalls: []

		}

	},

	created: function() {

		this.rollcalls = [
				{
					text: this.$language.role.candidates,
					type: 'candidates'
				},
				{
					text: 'Chaperones', //this.$language.role.chaperones,
					type: 'chaperones'
				},
				{
					text: this.$language.role.roleplayers,
					type: 'roleplayers'
				},
				{
					text: this.$language.role.examiners,
					type: 'examiners'
				},
				{
					text: this.$language.role.layexaminers,
					type: 'layexaminers'
				},
				{
					text: 'Observers',
					type: 'observers'
				}
			]

	},

	computed: {

		people: function() {

			return this.$_.filter(this.$store.getters['people'], function(item) {
				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false
			}.bind(this))

		},

		candidates: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.candidate && !person.empty) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		observers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.observer && !person.chaperone) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		chaperones: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.observer && person.chaperone) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		examiners: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.examiner) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		},

		layexaminers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.layexaminer) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		},

		roleplayers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.roleplayer) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		}

	},

	methods: {

		emptyCheck: function(text, defaultText) {

			return text || defaultText

		},

		onVerifyClick: function(person) {

			this.$api.request('login/verify', {
				identity: person
			}).then(function(data) {

				this.$store.commit('people/update', data.person)

			}.bind(this))

		},

		peopleType: function(type) {

			return this[type]

		},

		isOnline: function(person) {

			return this.$_.contains(this.$store.getters['online'], person) 

		},

		isOffline: function(person) {

			return !this.$_.contains(this.$store.getters['online'], person)

		},

		isReady: function(person) {

			return this.$_.contains(this.$store.getters['online/ready'], person)

		},

		isVerified: function(person) {

			return person.verified

		}

	}

}

</script>

<style scoped>

.rollcall {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
}

.rollcall-item {
	display: flex;
	flex-direction: column;
	margin: 0px 10px 10px 0px;
}

.rollcall-item.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.rollcall-item-head {
	background-color: #237DC6;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px;
	margin-left: 1px;
	font-weight: 400;
}

.rollcall-item-people {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.rollcall-item.has-colour .rollcall-item-people {
	border-left: 0px;
}

.rollcall-item-person {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	line-height: 20px;
	height: 52px;
	border-radius: 4px;
	width: 20%;
	border: 1px solid #ddd;
	padding: 6px 10px;
	margin: 1px 0px 0px 1px;
}

.rollcall-item-person-name {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	padding-left: 16px;
}

.rollcall-item-person-name small {
	display: block;
	font-size: 12px;
	font-weight: 300;
}

.rollcall-item-person-verify {
	flex-shrink: 0;
	width: 32px;
	line-height: 40px;
	font-size: 24px;
	cursor: pointer;
	text-align: right;
	color: #ccc;
}

.rollcall-item-person-verify.is-verified {
	color: #10ab6b;
}

.rollcall-item-person-status {
	color: #fff;
	font-weight: 500;
	position: absolute;
	left: 6px;
	top: 9px;
	width: 12px;
	height: 12px;
	border-radius: 6px;
}

.rollcall-item-person-status.is-online {
	background-color: #f5c638;
}

.rollcall-item-person-status.is-ready {
	background-color: #1ed43f;
}

.rollcall-item-person-status.is-offline {
	background-color: #c62323;
}

</style>