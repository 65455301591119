<template>
    
<div class="question" :onboarding="'panel.marksheet.questions.' + question.identity" :class="{'is-answered': question.value, 'is-row': question.type === 'check'}">

    <div class="question-text" v-html="question.text"></div>

    <div class="question-scale" v-if="question.type === 'scale'">

        <div class="question-scale-item" :class="{'is-active': parseInt(value) - 1 === question.value}" v-for="value in question.scale + 1" :key="value - 1">
            {{ value - 1 }}
        </div>

    </div>

    <div class="question-check" v-if="question.type === $constants.marking.question.type.check" :class="{'is-active': question.value}">
        <i class="fa fa-check"></i>    
    </div>

    <div class="question-toggle" v-if="question.type === 'toggle'">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === question.value}" v-for="(text, value) in toggle" :key="value">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle" v-if="question.type === 'pbf'">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === question.value}" v-for="(text, value) in pbf" :key="value">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle" v-if="question.type === 'epbf'">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === question.value}" v-for="(text, value) in epbf" :key="value">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle" v-if="question.type === 'cpfc'">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === question.value}" v-for="(text, value) in cpfc" :key="value">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle is-stacked" v-if="question.type === 'cpbbfc'">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === question.value}" v-for="(text, value) in cpbbfc" :key="value">
            {{ text }}
        </div>
    </div>

    <textarea v-autogrow ref="textarea" class="question-textarea" v-on:contextmenu="$allowContext" spellcheck="false" v-if="question.type === 'text'" :class="{'is-active': question.answered}" v-model="question.value"></textarea>

</div>

</template>

<script>

import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'

export default {

    props: ['question'],

    directives: {
		'autogrow': TextareaAutogrowDirective
    },

    data: function() {

        return {
            toggle: {
                1: 'Yes',
                2: 'No'
			},
			pbf: {
				1: 'Pass',
				2: 'Borderline',
				3: 'Fail'
			},
			epbf: {
				1: 'Excellent Pass',
				2: 'Pass',
				3: 'Borderline',
				4: 'Fail'
			},
			cpfc: {
				1: 'Clear Pass',
				2: 'Pass',
				3: 'Fail',
				4: 'Clear Fail'
			},
			cpbbfc: {
				1: 'Clear Pass',
				2: 'Pass',
				3: 'Bare Pass',
				4: 'Bare Fail',
				5: 'Fail',
				6: 'Clear Fail'
			}
        }

    }

}

</script>

<style scoped>

.question {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
    user-select: none;
    transition: all 100ms linear;
}

.question.is-row {
    flex-direction: row;
}

.question.is-answered {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid transparent;
}

.question-text {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.question-text >>> p {
	margin-bottom: 16px;
}

.question.is-answered .question-text {
    color: rgba(255, 255, 255, 0.75);
}

.question.is-row .question-text {
    flex-grow: 1;
    margin-bottom: 0px;
}

.question-textarea {
    width: 100%;
    min-height: 96px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    resize: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #fff;
    padding: 8px 10px;
    transition: all 100ms linear;
}

.question-textarea.is-active {
    background-color: #fff;
    color: #333;
}

.question-check {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    line-height: 34px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    transition: all 100ms linear;
}

.question-check:hover,
.question-check.is-active {
    background-color: #fff;
    color: #237DC6;
}

.sheet.is-saved .question-check.is-active {
    color: #27a258;
}

.question-toggle,
.question-scale {
    display: flex;
    flex-direction: row;
    height: 32px;
}

.question-toggle.is-stacked {
	flex-direction: column;
	height: auto;
}

.question-toggle-item,
.question-scale-item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 1px solid #237DC6;
    cursor: pointer;
    transition: all 100ms linear;
}

.sheet.sheet.is-saved .question-toggle-item,
.sheet.sheet.is-saved .question-scale-item {
	border-color: #27a258;
}

.question.is-answered .question-toggle-item:not(.is-active),
.question.is-answered .question-scale-item:not(.is-active) {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.75);
}

.question-toggle-item.is-active,
.question-toggle-item:hover,
.question-scale-item.is-active,
.question-scale-item:hover {
    background-color: #fff;
    color: #237DC6;
}

.sheet.is-saved .question-toggle-item:hover,
.sheet.is-saved .question-scale-item:hover,
.sheet.is-saved .question-toggle-item.is-active,
.sheet.is-saved .question-scale-item.is-active {
    color: #27a258;
}

.question.is-answered .question-toggle-item:not(.is-active):hover,
.question.is-answered .question-scale-item:not(.is-active):hover {
    background-color:rgba(0, 0, 0, 0.1);
}

.question-toggle-item:first-child,
.question-scale-item:first-child {
    border-radius: 4px 0px 0px 4px;
}

.question-toggle-item:last-child,
.question-scale-item:last-child {
    border-radius: 0px 4px 4px 0px;
}

.question-toggle-item.is-active,
.question-scale-item.is-active {
    border-radius: 4px;
}

</style>