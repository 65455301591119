<template>

<div class="message">

	<div class="message-title" v-if="line.title">{{ line.title }}</div>
	<div class="message-text" v-html="line.text" />
	<div class="message-foot">Click anywhere to proceed</div>
	
</div>

</template>

<script>

export default {

	props: ['line']

}

</script>

<style scoped>

.message {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	background-color: #237DC6;
	color: #fff;
	padding: 20px;
	width: 480px;
	text-align: center;
	border-radius: 4px;
}

.message-title {
	font-size: 24px;
	margin-bottom: 20px;
}

.message-text {
	font-size: 16px;
	line-height: 24px;
}

.message-text >>> p {
	margin-bottom: 20px;
}

.message-text >>> p:last-child {
	margin-bottom: 0px;
}

.message-foot {
	font-size: 12px;
	margin-top: 20px;
}

</style>