
import api from '@/service/api'

export default {

	namespaced: true,

	state: {
		
		cache: {}

	},

	getters: {

	},

	mutations: {

	},

	actions: {

		cache: function(context, data) {

			return new Promise(function(resolve) {

				var identity = data.identity
				var type = data.type

				var url = ['shredded', type, identity]

				if (data.brief) url.push(data.brief)

				url = url.join('/')

				var extension = (context.rootGetters['event/shredding/enabled']) ? 'json' : 'pdf'

				url = api.base() + url + '.' + extension + '?key=' + context.rootGetters['session/key']

				window.fetch(url).then(function(response) {

					response.json().then(function(json) {

						resolve(json.data)
	
					}, function() {
	
						resolve()
	
					})

				}, function() {

					resolve()

				})

			})

		}

	}

}