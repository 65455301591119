export default {

	namespaced: true,

	state: {

        schedule: [],
		tracking: []

	},

	getters: {

		get: function(state) {

			return state.schedule

        },
		
		tracking: function(state) {

			return state.tracking

		}

	},

	mutations: {

		set: function(state, data) {

            state.schedule = data.schedule
			state.tracking = data.tracking

        }

	},

	actions: {

		init: function(context, data) {

			if (data) context.commit('set', data)

		}

	}

}