import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'
import 'whatwg-fetch'
import store from '@/store'

var api = document.getElementById('app').getAttribute('data-api')

export default {

	busy: false,
	controller: false,
	signal: false,

	base: function() {

		return api

	},

	isBusy: function() {

		return this.busy

	},

	request: function(url, params) {

		params = params || {}

		if (this.busy && !params.silent) {

			// this.controller.abort()

		}

		if (store.getters['event/identity']) {

			params.event = store.getters['event/identity']

		}

		params.key = store.getters['session/key']

		this.controller = new AbortController()
		this.signal = this.controller.signal

		this.busy = true

		url = api + url + '.json'

		var self = this

		return new Promise(function(resolve, reject) {

			window.fetch(url, {
				synchronous: false,
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					'x-requested-with': 'xmlhttprequest',
					'Authorization': store.getters['key']
				},
				credentials: 'include',
				signal: self.signal,
				body: JSON.stringify(params)
			}).then(function(response) {

				self.busy = false

				if (!response.ok) {

					response.json().then(function(json) {

						reject(json.data)

					}, function(error) {

						reject(error)

					})

				} else {

					return response.json().then(function(json) {

						if (json.time) store.commit('time/offset', json.time - store.getters['time/raw'])

						resolve(json.data)

					}, function(error) {

						reject(error)

					})

				}

			}, function(error) {

				reject(error)

			})

		})

	}

}