<template>

<div class="stream" :class="{'is-local': isLocal, 'is-hidden': isHidden}" ref="stream">

	<div class="stream-screen" ref="screen" v-show="localScreen && isLocal">

	</div>

	<div class="stream-person" ref="person" v-show="(!localScreen || !isLocal) && !isHidden">

		<video ref="video" loop="true" muted="true" autoplay="true" src="" />

	</div>

	<div :onboarding="onboardingPrefix + '.muted'" class="stream-muted" v-if="isMuted && !miniview" v-tooltip="'Audio muted'"><i class="fa fa-microphone-slash"></i></div>

	<div class="stream-hidden" v-if="isHidden && !audioOnly && !miniview"><i class="fa fa-video-slash"></i></div>

	<div class="stream-hidden" v-if="isHidden && audioOnly && !miniview"><i class="fa fa-phone"></i></div>

	<div class="stream-speaking" v-if="isSpeaking && !miniview"><i class="fa fa-microphone"></i></div>

	<div class="stream-quality" v-if="(!isMuted || !isHidden) && qualityScore && !miniview" v-tooltip="'Connection quality'" :class="{[classQuality]: true}">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>

</div>

</template>

<script>

export default {

	props: ['local', 'onboardingPrefix', 'muted', 'video', 'speaking', 'miniview', 'audioOnly', 'quality'],

	computed: {

		localVideo: function() {

			return this.$store.getters['device/video']

		},

		localScreen: function() {

			return false

		},

		isLocal: function() {

			return this.local

		},

		isMuted: function() {

			return this.muted

		},

		isHidden: function() {

			return this.hidden

		},

		isSpeaking: function() {

			return this.speaking

		},

		qualityScore: function() {

			return this.quality

		},

		classQuality: function() {

			return 'quality-' + this.qualityScore.toString() 

		}

	},

	created: function() {

		this.$nextTick(function() {

			if (this.isLocal) {

				if (this.localVideo) this.attachLocalVideo(this.localVideo)

			} else {

				if (this.video) this.$refs.video.setAttribute('src', this.video)

			}

		})

	},

	methods: {

		attachLocalVideo: function(track) {

			var el = track.attach()

			this.$emit('attached', el)
			
			this.$refs.person.appendChild(el)

		}

	}

}

</script>

<style scoped>

.stream {
	width: 100%;
	height: 100%;
	background-color: #000;
	background-image: url('data:image/gif;base64,R0lGODlhKwALAPEAAAAAAP///3x8fP///yH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAAKAAEALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQACgACACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkEAAoAAwAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.stream.is-hidden {
	background-color: #237DC6;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: none;
}

.stream-screen,
.stream-person {
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: #000;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.stream-person {
	z-index: 2;
}

.stream-person.is-small {
	width: 96px;
	height: 96px;
	left: 0px;
	top: 0px;
	border-radius: 4px 0px 4px 0px;
	overflow: hidden;
}

.stream >>> video {
	width: auto;
	z-index: 1;
	display: block;
	height: 100%;
	max-height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.stream.is-hidden >>> video {
	visibility: hidden;
}

.stream-speaking {
	position: absolute;
	z-index: 3;
	left: 10px;
	bottom: 10px;
	font-size: 16px;
	width: 32px;
	height: 32px;
	background-color: #237DC6;
	color: #fff;
	line-height: 32px;
	text-align: center;
	border-radius: 16px;
}

.stream-muted {
	position: absolute;
	z-index: 3;
	right: 10px;
	bottom: 10px;
	font-size: 16px;
	width: 32px;
	height: 32px;
	background-color: #c62323;
	color: #fff;
	line-height: 32px;
	text-align: center;
	border-radius: 16px;
}

.is-local .stream-muted {
	cursor: pointer;
}

.stream-hidden {
	font-size: 96px;
	color: #fff;
}

.stream-quality {
	position: absolute;
	right: 0px;
	top: 0px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding: 4px;
	width: 22px;
	z-index: 3;
	border-radius: 0px 4px 0px 4px;
	height: 23px;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.stream-quality > div {
	width: 2px;
	background-color: rgba(255, 255, 255, 0.1);
	margin-right: 1px;
	transition: all 300ms ease-in-out;
}

.stream-quality > div:last-child {
	margin-right: 0px;
}

.stream-quality.quality-1 > div:nth-child(1),
.stream-quality.quality-2 > div:nth-child(1),
.stream-quality.quality-3 > div:nth-child(1),
.stream-quality.quality-4 > div:nth-child(1),
.stream-quality.quality-5 > div:nth-child(1),
.stream-quality.quality-2 > div:nth-child(2),
.stream-quality.quality-3 > div:nth-child(2),
.stream-quality.quality-4 > div:nth-child(2),
.stream-quality.quality-5 > div:nth-child(2),
.stream-quality.quality-3 > div:nth-child(3),
.stream-quality.quality-4 > div:nth-child(3),
.stream-quality.quality-5 > div:nth-child(3),
.stream-quality.quality-4 > div:nth-child(4),
.stream-quality.quality-5 > div:nth-child(4),
.stream-quality.quality-5 > div:nth-child(5) {
	background-color: rgba(255, 255, 255, 1);
}

.stream-quality > div:nth-child(1) {
	height: 3px;
}

.stream-quality > div:nth-child(2) {
	height: 6px;
}

.stream-quality > div:nth-child(3) {
	height: 9px;
}

.stream-quality > div:nth-child(4) {
	height: 12px;
}

.stream-quality > div:nth-child(5) {
	height: 15px;
}

</style>