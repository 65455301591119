import Pusher from 'pusher-js'
import constants from '@/constants'
import store from '@/store'

Pusher.logToConsole = true

Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest()
	xhr.withCredentials = true
	return xhr
}

var instance = false
var channels = {}

var api = document.getElementById('app').getAttribute('data-api')

export default {

	init: function(key) {

		instance = new Pusher(key, {
			cluster: constants.pusher.cluster,
			authEndpoint: api + 'pusher/auth',
            auth: {
                headers: {
                    Authorization: store.getters['session/key']
				}
			}
		})

	},

	subscribe: {

		presence: function(identity) {

			return this.do('presence-person', identity, 'presence')

		},

		server: function() {

			return this.do('server', 'global', 'server')

		},

		event: function(identity) {

			return this.do('event', identity, 'event')

		},

		observers: function(identity, name) {

			return this.do('observers', identity, name)

		},

		user: function(identity, name) {

			return this.do('user', identity, name)

		},

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		room: function(identity, name) {

			return this.do('room', identity, name)

		},

		schedule: function(identity, name) {

			return this.do('schedule', identity, name)

		},

		chat: function(identity, name) {

			return this.do('chat', identity, name)

		},

		do: function(prefix, identity, name) {

			name = name || prefix + '.' + identity
			if (!channels[name]) channels[name] = instance.subscribe(prefix + '.' + identity)

			return channels[name]

		},

		private: function(prefix, identity, name) {

			name = 'private-' + prefix + '.' + identity
			if (!channels[name]) channels[name] = instance.subscribe('private-' + prefix + '.' + identity)

			return channels[name]

		}

	},

	unsubscribe: {

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		do: function(prefix, identity, name) {

			name = name || prefix + '.' + identity

			if (channels[name]) {

				channels[name] = instance.unsubscribe(prefix + '.' + identity)
				delete channels[name]

			}

		},

		private: function(prefix, identity, name) {

			name = 'private-' + prefix + '.' + identity

			if (channels[name]) {

				instance.unsubscribe('private-' + prefix + '.' + identity)
				delete channels[name]

			}

		}

	},

	emit: {

		inventory: function(identity, event, data) {

			return this.do('inventory', identity, event, data)

		},

		do: function(prefix, identity, event, data) {

			var name = 'private-' + prefix + '.' + identity
			data = data || {}
			channels[name].trigger('client-' + event, data)

		}

	},

	on: function(channel, event, handler) {

		channels[channel].bind(event, handler)
		
		return channels[channel]

	},

	onPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].bind('client-' + event, handler)
		
		return channels[channel]

	},

	offPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].unbind('client-' + event, handler)
		
		return channels[channel]

	}

}