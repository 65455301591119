<template>
    
<div class="candidates">

    <div class="candidates-head">

        <div class="candidates-head-title">{{ $language.role.candidates }}</div>

    </div>

    <div class="candidates-context">

        <div>{{ date | formatDate('DD/MM/YYYY') }}</div>
        <div>{{ candidates.length }} <template v-if="candidates.length === 1">{{ $language.role.candidate }}</template><template v-if="candidates.length !== 1">{{ $language.role.candidates }}</template></div>

    </div>

    <app-scroll class="candidates-body">

		<div class="candidates-item" :class="{'is-inactive': (item.status === 3 && !isUnlocked) || item.reference === false}" v-for="item in candidates" :key="item.identity" v-on:click="onCandidateClick(item.identity)">

			<div class="candidates-item-text">
				<div class="candidates-item-text-name" v-if="item.reference !== false">{{ $language.role.candidate }} #{{ item.reference }}</div>
				<div class="candidates-item-text-name" v-if="item.reference === false">No {{ $language.role.candidate }}</div>
				<div class="candidates-item-text-time">{{ item.time | formatDate('h:mma') }}</div>
			</div>

			<div class="candidates-item-status" v-if="item.reference !== false" :class="{'is-changed': item.status === 4, 'is-unsaved': item.status === 2, 'is-saved': item.status === 1, 'is-future': item.status === 3, 'is-active': item.status === 5}">
				{{ item.label }}
			</div>

		</div>

    </app-scroll>

</div>

</template>

<script>

export default {

	props: ['saved', 'session', 'validSave'],

	computed: {
		
		isUnlocked: function() {

			return this.$store.getters['event'].marking.unlocked

		},

        date: function() {

			return this.$store.getters['event'].date
			
		},

		candidates: function() {

			var candidates = []

			this.$_.each(this.$store.getters['marking/candidates'], function(candidate) {

				var status = 0
				var label = ''

				if (candidate.start > this.time) {

					label = 'Not started'
					status = 3

				} else if (this.validSave[candidate.identity]) {

					if(this.$_.isEqual(this.saved[candidate.identity], this.session[candidate.identity])) {

						label = 'Completed'
						status = 1

					} else {

						label = 'Changed'
						status = 4

					}

				} else {

					if (candidate.end > this.time) {

						label = 'In progress'
						status = 5

					} else {

						label = 'Not saved'
						status = 2

					}

				}

				candidates.push({
					identity: candidate.identity,
					reference: (candidate.candidate) ? this.$store.getters['people'][candidate.candidate].reference : false,
					time: candidate.start,
					status: status,
					label: label
				})

			}.bind(this))

			return candidates

		}

	},

	methods: {

		onCandidateClick: function(identity) {

			this.$emit('change', identity)

		}

	}

}

</script>

<style scoped>

.candidates {
	width: 100%;
	user-select: none;
	height: 100%;
	display: flex;
    background-color: #237DC6;
	transition: background-color 100ms linear;
    flex-direction: column;
}

.candidates-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.candidates-head-title {
    flex-grow: 1;
}

.candidates-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.candidates-body {
    flex-grow: 1;
    flex-basis: 0;
}

.candidates-item {
	border-bottom: 1px solid rgb(0, 0, 0, 0.1);
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.candidates-item.is-inactive {
	pointer-events: none;
}

.candidates-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: transparent;
}

.candidates-item:last-child {
	border-bottom-width: 0px;
}

.candidates-item-text {
	color: #fff;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
}

.candidates-item-text-name {
	font-size: 14px;
	font-weight: 300;
}

.candidates-item-text-time {
	font-size: 12px;
	font-weight: 300;
	opacity: 0.75;
}

.candidates-item-status {
	color: #fff;
	border-radius: 4px; 
	padding: 2px 4px;
	font-weight: 400;
	font-size: 12px;
}

.candidates-item-status.is-saved {
	background-color: #27a258;
}

.candidates-item-status.is-future {
	background-color: #1c6aa9;
	color: #5ea9e6;
}

.candidates-item-status.is-unsaved {
	background-color: #cc3939;
}

.candidates-item-status.is-changed {
	background-color: #ce9921;
}

.candidates-item-status.is-active {
	background-color: #56aef5;
}

</style>