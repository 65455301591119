<template>

<div class="chaperones">

	<div v-for="(chaperone, index) in chaperones" class="chaperones-item" :key="index" :class="{'has-colour': $colour}" :style="{borderColor: $colour}">

		<div class="chaperones-item-head">
			
			<div class="chaperones-item-head-text">{{ chaperone.name }}</div>
			<div class="chaperones-item-head-actions">

				<div class="chaperones-item-head-actions-item" v-tooltip="'Observe ' + $language.role.candidates" v-on:click="onObserveClick(chaperone.identity)"><i class="fa fa-user-secret"></i></div>

			</div>
			
		</div>

		<div class="chaperones-item-people">

			<div class="chaperones-item-person" v-for="(person, index) in chaperone.participants" :key="index">

				<div class="chaperones-item-person-name">{{ name(person) }} <small>{{ label(person) }}</small></div>
				<div class="chaperones-item-person-status" :class="{'is-online': isOnline(person), 'is-ready': isReady(person), 'is-offline': isOffline(person)}"></div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				joining: false
			}
		}

	},

	computed: {

		chaperones: function() {

			return this.$_.filter(this.$store.getters['chaperone/list'], function(item) {

				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false

			}.bind(this))

		}

	},

	methods: {

		name: function(identity) {

			return this.$store.getters['people'][identity].name || 'No name'

		},

		label: function(identity) {

			return this.$store.getters['people'][identity].label || 'No label'

		},

		isOnline: function(identity) {

			return this.$_.contains(this.$store.getters['online'], identity) 

		},

		isOffline: function(identity) {

			return !this.$_.contains(this.$store.getters['online'], identity)

		},

		isReady: function(identity) {

			return this.$_.contains(this.$store.getters['online/ready'], identity)

		},

		onObserveClick: function(identity) {

			this.$emit('join', identity)

		}

	}

}

</script>

<style scoped>

.chaperones {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
}

.chaperones-item {
	display: flex;
	flex-direction: column;
	margin: 0px 10px 10px 0px;
}

.chaperones-item.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.chaperones-item-head {
	background-color: #237DC6;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 0px 0px 10px;
	border-radius: 4px 4px 0px 0px;
	font-weight: 400;
	display: flex;
}

.chaperones-item.has-colour .chaperones-item-head {
	border-radius: 0px 4px 0px 0px;
}

.chaperones-item-head-text {
	flex-grow: 1;
}

.chaperones-item-head-actions {
	flex-shrink: 0;
	display: flex;
	justify-content: flex-end;;
}

.chaperones-item-head-actions-item {
	width: 48px;
	text-align: center;
	cursor: pointer;
	border-left: 1px solid #fff;
}

.chaperones-item-people {
	display: flex;
	flex-direction: row;
	border: 1px solid #ddd;
	border-radius: 0px 0px 4px 4px;
	border-top-width: 0px;
	flex-wrap: wrap;
}

.chaperones-item.has-colour .chaperones-item-people {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

.chaperones-item-person {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	line-height: 20px;
	height: 52px;
	width: 20%;
	padding: 6px 10px;
	border-right: 1px solid #ddd;
}

.chaperones-item-person:nth-child(5n) {
	border-right-width: 0px;
}

.chaperones-item-person-name {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	padding-left: 16px;
}

.chaperones-item-person-name small {
	display: block;
	font-size: 12px;
	font-weight: 300;
}

.chaperones-item-person-verify {
	flex-shrink: 0;
	width: 32px;
	line-height: 40px;
	font-size: 24px;
	cursor: pointer;
	text-align: right;
	color: #ccc;
}

.chaperones-item-person-verify.is-verified {
	color: #10ab6b;
}

.chaperones-item-person:last-child {
	border-radius: 0px 0px 4px 4px;
}

.chaperones-item-person-status {
	color: #fff;
	font-weight: 500;
	position: absolute;
	left: 6px;
	top: 9px;
	width: 12px;
	height: 12px;
	border-radius: 6px;
}

.chaperones-item-person-status.is-online {
	background-color: #f5c638;
}

.chaperones-item-person-status.is-ready {
	background-color: #1ed43f;
}

.chaperones-item-person-status.is-offline {
	background-color: #c62323;
}

</style>