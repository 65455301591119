<template>

<div class="meeting" onboarding="meeting">

	<app-room-grid v-if="scene.setup.status === 'connected'" :participantsCurrent="$_.keys(participants)" :cardsCurrent="$_.keys(cards)">

		<com-participant v-for="participant in participants" :expanded="scene.setup.expanded === participant.id" :key="participant.id" :person="participant" :speaking="participant.id === scene.setup.speaking" />

		<template v-for="card in cardsByType('file')">

			<com-file :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :showNote="card.id === scene.setup.expanded || participants.length < 9" :expanded="card.id === scene.setup.expanded" :item="card" />

		</template>

		<template v-for="card in cardsByType('notepad')">

			<com-notepad :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :canExpand="true" :item="card" :expanded="card.id === scene.setup.expanded" />

		</template>

	</app-room-grid>

	<app-room-status v-if="scene.setup.status === 'connecting'" :type="$constants.status.loading" text="Connecting, please wait..." />

</div>

</template>

<script>

import comParticipant from './meeting/Participant'

export default {

	props: ['scene', 'initTime'],

	components: {
		'com-participant': comParticipant
	},

	computed: {

		participants: function() {

			return this.scene.setup.participants

		},

		cards: function() {

			return this.scene.setup.cards

		}

	},

	methods: {

		cardsByType: function(type) {

            return this.$_.where(this.cards, {
                type: type
            })

		}

	}

}

</script>

<style scoped>

.meeting {
	width: calc(100% + 10px);
	height: calc(100% + 10px);
	z-index: 1;	
}

</style>