<template>

<div class="observer">

	<com-circuits v-if="isExam && !is.joining" />

	<app-waiting v-if="is.joining" text="One moment, requesting to join room..." />

	<div class="observer-content" ref="content" v-if="!is.joining" v-on:scroll="onContentScroll">
		<com-rollcall v-if="section === $constants.observer.rollcall" /> 
		<com-chaperones v-if="section === $constants.observer.chaperones" v-on:join="onJoin" />
		<com-marking v-if="section === $constants.observer.marking" />
		<com-schedule v-if="section === $constants.observer.schedule" v-on:join="onJoin" />
		<com-incidents v-if="section === $constants.observer.incidents && canIncidents" />
	</div>

</div>

</template>

<script>

import comCircuits from './observer/Circuits'
import comRollcall from './observer/Rollcall'
import comSchedule from './observer/Schedule'
import comIncidents from './observer/Incidents'
import comChaperones from './observer/Chaperones'
import comMarking from './observer/Marking'

export default {

	components: {
		'com-circuits': comCircuits,
		'com-rollcall': comRollcall,
		'com-schedule': comSchedule,
		'com-chaperones': comChaperones,
		'com-marking': comMarking,
		'com-incidents': comIncidents
	},

	data: function() {

		return {
			is: {
				joining: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('observer.section', this.onSectionChange.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('observer.section', this.onSectionChange.bind(this))

	},

	computed: {

		section: function() {

			return this.$store.getters['session/observer/section']

		},

		canIncidents: function() {

			return this.hasPermission(this.$constants.observer.permission.incidents)

		},

		circuits: function() {

			return this.$store.getters['circuits']

		}

	},

	methods: {

		onContentScroll: function(e) {

			if (this.section === this.$constants.observer.schedule && e.target.scrollTop) this.$store.commit('session/observer/scroll', e.target.scrollTop)

		},

		onJoin: function(identity) {

			this.is.joining = true

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('room/join', {
				room: identity
			}).then(function() {

				this.is.joining = false

			}.bind(this))

		},

		onSectionChange: function(section) {

			this.$store.commit('session/observer/section', section)

			this.$nextTick(function() {

				if (this.section === this.$constants.observer.schedule) this.$refs.content.scrollTop = this.$store.getters['session/observer/scroll']

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.observer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.observer-content {
	flex-grow: 1;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	z-index: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 0px 4px 4px 4px;
	background-color: #fff;
}

</style>
