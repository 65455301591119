<template>

<app-actions-icons class="actions" :class="{'is-disabled': isPaused || !$isActiveReady, [clsCount]: true}" v-if="canDoSomething">

	<app-actions-icon icon="hand-paper" name="Raise hand" v-if="canRaiseHand && !isHighlighted" v-on:click="onRaiseHand" :loading="is.raising" />

	<app-actions-icon icon="hand-rock" name="Lower hand" v-if="canRaiseHand && isHighlighted" v-on:click="onRaiseHand" :loading="is.raising" />

	<app-actions-icon icon="user-friends" :name="'Start video chat with ' + $language.role.candidate" v-if="!isTalking && canTalk" v-on:click="onStartTalk" />

	<app-actions-icon icon="user-slash" :name="'End video chat with ' + $language.role.candidate" v-if="isTalking && canTalk" v-on:click="onStopTalk" />

	<app-actions-icon icon="microphone" name="Mute microphone" v-if="!isMuted && canToggleMicrophone" v-on:click="onMute" />

	<app-actions-icon icon="microphone-slash" name="Unmute microphone" v-if="isMuted && canToggleMicrophone" v-on:click="onUnmute" />

	<app-actions-icon icon="video" name="Hide camera" v-if="!isHidden && canToggleVideo" v-on:click="onHide" />

	<app-actions-icon icon="video-slash" name="Show camera" v-if="isHidden && canToggleVideo" v-on:click="onShow" />

	<app-actions-icon icon="desktop" name="Start screenshare" v-if="canScreenShare && !isScreenSharing" v-on:click="onScreenshare" />

	<app-actions-icon icon="desktop" :slash="true" name="Stop screenshare" v-if="canScreenShare && isScreenSharing" v-on:click="onScreenshare" />

	<app-actions-icon :icon="candidateIcon" :name="candidateName" v-if="canReadCandidateBrief" v-on:click="onCandidateBrief" />

	<app-actions-icon :icon="roleplayerIcon" :name="roleplayerName" v-if="canReadRoleplayerBrief" v-on:click="onRoleplayerBrief" />

	<app-actions-icon :icon="examinerIcon" :name="examinerName" v-if="canReadExaminerBrief" v-on:click="onExaminerBrief" />

	<app-actions-icon icon="pencil-alt" name="Notepad" v-if="canMakeNotes" v-on:click="onNotepad" />

	<app-actions-icon icon="marker" name="Toggle marking panel" v-if="canMark" v-on:click="onMarking" />

	<app-actions-icon icon="users" name="Toggle attendee panel" v-if="canUsers" v-on:click="onUsers" />

	<app-actions-icon icon="clock" name="Extend running time" v-if="canExtend" v-on:click="onExtend" />

	<app-actions-icon icon="user-plus" :name="participateName" v-if="canParticipate" v-on:click="onParticipate" :loading="is.participate" />

	<app-actions-icon icon="user-secret" name="Back to observing only" v-if="canObserve" v-on:click="onObserve" :loading="is.observe" />

	<app-actions-icon icon="door-open" :loading="is.leave" name="Leave room" v-if="canLeave" v-on:click="onLeave" />

</app-actions-icons>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				participate: false,
				observe: false,
				leave: false,
				raising: false
			}
		}

	},

	computed: {

		clsCount: function() {

			var count = 0

			if (this.canMark) count++
			if (this.canParticipate) count++
			if (this.canObserve) count++
			if (this.canToggleVideo) count++
			if (this.canToggleMicrophone) count++
			if (this.canScreenShare) count++
			if (this.canReadCandidateBrief) count++
			if (this.canReadRoleplayerBrief) count++
			if (this.canReadExaminerBrief) count++
			if (this.canMakeNotes) count++
			if (this.canTalk) count++
			if (this.canLeave) count++
			if (this.canRaiseHand) count++
			if (this.canUsers) count++
			if (this.canExtend) count++

			return 'count-' + count.toString() + ' rows-' + Math.ceil(count / 5).toString()

		},

		canDoSomething: function() {

			return this.canExtend || this.canRaiseHand || this.canUsers || this.canScreenShare || this.canToggleVideo || this.canTalk || this.canMark || this.canParticipate || this.canObserve || this.canToggleMicrophone || this.canReadExaminerBrief || this.canReadCandidateBrief || this.canReadRoleplayerBrief || this.canMakeNotes || this.canLeave

		},

		canScreenShare: function() {

			var canShare = this.isLead || this.isRoamingObserver

			if (this.isActiveStation) {

				if (this.$store.getters['schedule/active/permissions'].screenshare === 2) {

					canShare = this.isLead || this.isCandidate || this.isRoamingObserver

				}

			}

			return !this.$isActivePlayback && this.isParticipating && canShare && (this.isActiveMeeting || this.$isWebinar || (this.isActiveStation && !this.isPreparation)) && this.$store.getters['schedule/active/permissions'].screenshare && (!this.$store.getters['room/screensharing'] || this.$store.getters['device/screen'])

		},

		isScreenSharing: function() {

			return this.$store.getters['device/screen']

		},

		canTalk: function() {

			return this.isChaperone && (!this.isActiveStation || this.isPreparation) && !this.isActiveMeeting && !this.isChaperoneGroup && !this.$isEventFinished

		},

		isTalking: function() {

			return this.$store.getters['chaperone/talking']

		},

		isHighlighted: function() {

			return (this.isActiveMeeting) ? this.$_.contains(this.isActive.highlighted, this.$store.getters['people/self'].identity) : false

		},
		
		canMark: function() {

			return (this.isExaminer || this.isLayExaminer) && (!this.isActiveStation || this.time < this.active.roleplay || (!this.isActive.candidate && this.$store.getters['event/marking/empty'])) && this.$isMarkingEnabled

		},

		canToggleVideo: function() {

			return this.isParticipating && this.isActiveMeeting && this.$store.getters['schedule/active/permissions'].togglevideo

		},

		canToggleMicrophone: function() {

			return !this.$isActivePlayback && this.isParticipating && (this.isActiveMeeting || (this.isActiveStation && !this.isPreparation))

		},

		canRaiseHand: function() {

			return this.isObserving && this.$isWebinar

		},

		canExtend: function() {

			return this.isActive && this.hasPermission(this.$constants.observer.permission.extend)

		},

		canUsers: function() {

			return this.isLead && this.$isWebinar

		},

		candidateIcon: function() {

			return (this.isCandidate) ? 'file-alt' : 'user-tie'

		},

		roleplayerIcon: function() {

			return (this.isRoleplayer) ? 'file-alt' : 'user'

		},

		examinerIcon: function() {

			return 'user'

		},

		participateName: function() {

			return (this.$isWebinar) ? 'Speaker ONLY participate in video chat' : 'Participate in video chat'

		},

		candidateName: function() {

			return (this.isCandidate) ? 'Brief' : this.$language.role.candidate + ' brief'

		},

		roleplayerName: function() {

			return (this.isRoleplayer) ? 'Brief' : this.$language.role.roleplayer + ' brief'

		},

		examinerName: function() {

			return this.$language.role.examiner + ' brief'

		},

		active: function() {

			return this.$store.getters['schedule/active']

        },
        
        canMakeNotes: function() {

            return this.isActiveStation && this.isCandidate && this.$store.getters['event/notes/enabled'] && !this.isPreparation

        },

		canObserve: function() {

			return this.isParticipating && (this.isObserver || this.isChaperone) && !this.$isInterval

		},

		canParticipate: function() {

			return !this.$isActivePlayback && this.isObserving && (this.isObserver || this.isChaperone) && (this.isActiveMeeting || (this.isActiveStation && !this.isPreparation))

		},

		canLeave: function() {

			return !this.$isActivePlayback && (this.isRoamingObserver && (this.$store.getters['schedule/next'] || this.isActive)) || (this.isRoamingChaperone && this.$store.getters['schedule/active'].type !== this.$constants.schedule.type.chaperone)

		},

		canReadCandidateBrief: function() {

			return this.isActiveStation && !this.isPreparation

		},

		canReadExaminerBrief: function() {

			return this.isActiveStation && !this.isCandidate && !this.isPreparation && this.isStationExaminerOnly

		},

		canReadRoleplayerBrief: function() {

			return this.isActiveStation && !this.isCandidate && !this.isPreparation && !this.isStationExaminerOnly

		},

		isActiveStation: function() {

			return (this.active) ? this.active.type === this.$constants.schedule.type.station : false

		},

		isActiveMeeting: function() {

			return (this.active) ? this.active.type === this.$constants.schedule.type.meeting : false

		},

		isMuted: function() {

			return !this.$store.getters['settings/audio']

		},

		isHidden: function() {

			return !this.$store.getters['settings/video']

		}	

	},

	created: function() {

		this.checkFinished()

	},

	watch: {

		active: function() {
	
			this.checkFinished()

		}

	},

	methods: {

		onStartTalk: function() {

			this.$api.request('observer/video', {
				start: true,
				identity: (this.isRoamingChaperone) ? this.active.candidate : this.$store.getters['session/chaperoneWith']
			}).then(function() {

				if (this.isRoamingChaperone) {

					this.$store.commit('chaperone/chaperone', this.active.candidate)

				}

				this.$chaperone.startTalking()

			}.bind(this))

		},

		onStopTalk: function() {

			this.$api.request('observer/video', {
				identity: (this.isRoamingChaperone) ? this.active.candidate : this.$store.getters['session/chaperoneWith']
			}).then(function() {

				if (this.isRoamingChaperone) {

					this.$store.commit('chaperone/chaperone', false)


				}

				this.$chaperone.stopTalking()

			}.bind(this))

		},

		checkFinished: function() {

			if ((this.isRoamingObserver && !this.isActive) || (this.isRoamingChaperone && !this.isActive && this.$store.getters['schedule/active'].type !== this.$constants.schedule.type.chaperone)) {

				this.$store.commit('session/observer/section', this.$constants.observer.schedule)

				if(!this.isRoamingObserver || this.$store.getters['schedule'].length === 1) this.onLeave()

			}

		},

		onMarking: function() {

			this.$pubsub.$emit('marking.toggle')

		},

		onUsers: function() {

			this.$pubsub.$emit('users.toggle')

		},

		onExtend: function() {

			this.$pubsub.$emit('extend.toggle')

		},

		onParticipate: function() {

			if (!this.is.observe) {

				this.is.participate = true

				this.$api.request('room/participate', {
					room: this.isActive.identity
				}).then(function() {

					this.$store.commit('settings/video', true)
					this.$store.commit('settings/audio', true)

					this.is.participate = false

				}.bind(this), function() {

					this.is.participate = false

				}.bind(this))

			}

		},

		onObserve: function() {

			if (!this.is.observe) {

				this.is.observe = true

				this.$api.request('room/observe', {
					room: this.isActive.identity
				}).then(function() {

					this.$store.commit('settings/video', false)
					this.$store.commit('settings/audio', false)

					this.is.observe = false

				}.bind(this), function() {

					this.is.observe = false

				}.bind(this))
			
			}

		},

		onLeave: async function() {

			if (!this.is.leave && this.$store.getters['schedule'].length) {

				if (this.isRoamingChaperone) {

					this.$chaperone.stopTalking()
				
					this.$chaperone.disconnect(false, true)

				}	

				this.is.leave = true

				this.$api.request('room/leave', {
					room: (this.isRoamingChaperone || !this.isActive) ? this.$store.getters['schedule/last'].identity : this.isActive.identity
				}).then(function() {

					this.$store.commit('settings/video', false)
					this.$store.commit('settings/audio', false)

					this.is.leave = false

				}.bind(this), function() {

					this.is.leave = false

				}.bind(this))
			
			}

		},

		onScreenshare: function() {

			this.$store.dispatch('device/screenshare')

		},

		onHide: function() {

			this.$store.commit('settings/video', false)

		},

		onShow: function() {

			this.$store.commit('settings/video', true)

        },

		onMute: function() {

			this.$store.commit('settings/audio', false)

		},

		onUnmute: function() {

			this.$store.commit('settings/audio', true)

        },
        
        onNotepad: function() {

            this.$pubsub.$emit('action.notepad')
            
        },

		onCandidateBrief: function() {

			this.$pubsub.$emit('action.brief', 'candidate')

		},

		onRoleplayerBrief: function() {

			this.$pubsub.$emit('action.brief', 'roleplayer')

		},

		onExaminerBrief: function() {

			this.$pubsub.$emit('action.brief', 'examiner')

		},

		onRaiseHand: function() {

			this.is.raising = true

			this.$api.request('room/highlight', {
				room: this.isActive.identity
			}).then(function() {

				this.is.raising = false

			}.bind(this), function() {

				this.is.raising = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.actions.is-disabled {
	opacity: 0.35;
	pointer-events: none;
}

.actions {
	flex-wrap: wrap;
}

.actions >>> .icon {
	border-radius: 0px;
	margin-right: 0px;
	border-right-width: 0px;
	width: 20%;
	flex-shrink: 0;
	line-height: 44px!important;
}

.actions >>> .icon:nth-child(1) {
	border-top-left-radius: 4px;
}

.actions.rows-1 >>> .icon:nth-child(1) {
	border-bottom-left-radius: 4px;
}

.actions >>> .icon:nth-child(6),
.actions >>> .icon:nth-child(7),
.actions >>> .icon:nth-child(8),
.actions >>> .icon:nth-child(9),
.actions >>> .icon:nth-child(10) {
	border-top-width: 0px;
}

.actions >>> .icon:nth-child(5) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.count-10 >>> .icon:nth-child(5) {
	border-bottom-right-radius: 0px;
}

.actions >>> .icon:nth-child(6) {
	border-bottom-left-radius: 4px;
}

.actions >>> .icon:last-child {
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.rows-2 >>> .icon:last-child {
	width: calc(20% + 1px);
}

.actions.rows-1 >>> .icon:last-child {
	border-top-right-radius: 4px;
	width: 20%;
}

.actions.count-10 >>> .icon:last-child {
	width: 20%;
}

</style>
