<template>

<app-page>

	<com-setup v-if="!isInitiated" />
	<com-welcome v-if="isInitiated && !isAccepted" v-on:proceed="readyTick" />

	<template v-if="isInitiated && isAccepted">

		<com-sidebar />
		<com-content />

	</template>

	<com-panel />
	<com-modal />

	<com-foot />

</app-page>

</template>

<script>

import comSetup from './event/Setup';
import comSidebar from './event/Sidebar';
import comContent from './event/Content';
import comFoot from './event/Foot'

import comModal from './shared/Modal'
import comWelcome from './shared/Welcome';
import comPanel from './shared/Panel'

export default {

	components: {
		'com-setup': comSetup,
		'com-sidebar': comSidebar,
		'com-content': comContent,
		'com-welcome': comWelcome,
		'com-foot': comFoot,
		'com-panel': comPanel,
		'com-modal': comModal
	},

	data: function() {

		return {
			timer: false,
			gapMin: 180000,
			gapMax: 300000,
			stationApproachTime: 30
		}

	},

	created: function() {

		this.readyTick()

		if (this.isExaminer || this.isRoleplayer || this.isLayExaminer || (this.isChaperone && !this.isRoamingChaperone)) {

			var schedule = this.$_.findWhere(this.$store.getters['schedule'], function(item) {

				return item.type === this.$constants.schedule.type.station

			}.bind(this))

			if (schedule) {

				if (!this.isRoleplayer) this.$store.dispatch('shredded/cache', {
					type: 'station',
					brief: 'candidate',
					identity: schedule.station
				})

				if (!this.isStationExaminerOnly) this.$store.dispatch('shredded/cache', {
					type: 'station',
					brief: 'roleplayer',
					identity: schedule.station
				})

				if (this.isStationExaminerOnly) this.$store.dispatch('shredded/cache', {
					type: 'station',
					brief: 'examiner',
					identity: schedule.station
				})
				
			}

		}

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	computed: {

		stationApproaching: function() {

			var next = this.$store.getters['schedule/next']

			if (next) {

				return (next.type === this.$constants.schedule.type.station && next.start <= this.$store.getters['time'] + this.stationApproachTime) ? next.identity : false

			} else {

				return false

			}

		}

	},

	watch: {

		stationApproaching: function(n) {

			if (n && this.isCandidate) this.cacheBriefs(n)

		}

	},

	methods: {

		cacheBriefs: function(identity) {

			this.$store.dispatch('shredded/cache', {
				type: 'brief',
				brief: 'candidate',
				identity: identity
			})

		},

		readyTick: function() {

			clearTimeout(this.timer)

			if (this.$store.getters['session/accepted']) {

				this.$api.request('login/ready').then(function() {

					this.timer = setTimeout(this.readyTick.bind(this), this.$_.random(this.gapMin, this.gapMax))

				}.bind(this))
				
			} else {

				this.timer = setTimeout(this.readyTick.bind(this), this.gapMin)

			}

		}

	}

}

</script>
