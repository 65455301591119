<template>

<div>

	<app-actions-items v-if="isRoamingObserver && !hasNext">

		<app-actions-item icon="users" :active="isRollcall" name="Users" v-on:click="onSectionClick($constants.observer.rollcall)" />

		<app-actions-item icon="clock" :active="isSchedule" name="Schedule" v-on:click="onSectionClick($constants.observer.schedule)" />

		<app-actions-item v-if="canMarking" icon="marker" :active="isMarking" name="Marking" v-on:click="onSectionClick($constants.observer.marking)" />

		<app-actions-item v-if="canChaperone" icon="user" :active="isChaperones" name="Chaperones" v-on:click="onSectionClick($constants.observer.chaperones)" />

		<app-actions-item v-if="canIncidents" icon="exclamation-triangle" :active="isIncidents" name="Incidents" v-on:click="onSectionClick($constants.observer.incidents)" />

	</app-actions-items>

</div>

</template>

<script>

export default {

	computed: {

		hasNext: function() {

			return this.$store.getters['schedule/next']

		},
		
		isMarking: function() {
			
			return this.currentSection === this.$constants.observer.marking

		},

		isRollcall: function() {
			
			return this.currentSection === this.$constants.observer.rollcall

		},

		isSchedule: function() {
			
			return this.currentSection === this.$constants.observer.schedule

		},

		isChaperones: function() {
			
			return this.currentSection === this.$constants.observer.chaperones

		},

		isIncidents: function() {
			
			return this.currentSection === this.$constants.observer.incidents

		},

		currentSection: function() {

			return this.$store.getters['session/observer/section']

		},

		hasSchedule: function() {

			return this.$store.getters['schedule'].length

		},

		canIncidents: function() {

			return this.hasPermission(this.$constants.observer.permission.incidents)

		},

		canMarking: function() {

			return this.hasPermission(this.$constants.observer.permission.marking)

		},

		canChaperone: function() {

			return this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.group && this.hasPermission(this.$constants.observer.permission.chaperone)

		}

	},

	methods: {

		onSectionClick: function(value) {

			this.$pubsub.$emit('observer.section', value)

		}

	}

}

</script>

<style scoped>

</style>
