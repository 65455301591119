import moment from 'moment'
import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		current: moment().utc().tz('Europe/London').unix(),
		offset: 0

	},

	getters: {

		current: function(state, getters, rootState, rootGetters) {

			return (rootGetters['event/paused']) ? rootGetters['event/paused/start'] : state.current + state.offset

		},

		true: function(state) {

			return state.current + state.offset

		},

		offset: function(state) {

			return state.offset

		},

		raw: function(state) {

			return state.current

		}

	},

	mutations: {

		current: function(state, value) {

			state.current = value

		},

		offset: function(state, value) {

			state.offset = value

		}

	},

	actions: {

		init: function(context) {

			// listen to clock syncs on event channel
			pusher.on('server', 'clock', function(data) {

				context.commit('offset', data.time - context.getters['raw'])

			})

		}

	}

}