<template>
	
<div class="audio">

	<div class="audio-item" :class="{'is-disabled': is.recording || is.playing}">

		<div class="audio-item-button-wrapper">

			<div class="audio-item-button is-record" v-tooltip="'Begin recording'" v-on:click="onStartClick">
				<i class="fa fa-microphone"></i>
			</div>

			<div class="audio-item-button is-volume" v-tooltip="'Volume level'" :style="{height: this.volume.level + '%'}" v-if="is.recording">
				<div class="audio-item-button-inner">
					<i class="fa fa-microphone"></i>
				</div>
			</div>

		</div>

		<div class="audio-item-text">Start recording</div>

	</div>

	<div class="audio-item" :class="{'is-disabled': !is.recording}">

		<div class="audio-item-button is-record" v-tooltip="'End recording'" v-on:click="onStopClick"><i class="fa fa-microphone-slash"></i></div>
		<div class="audio-item-text">End recording</div>

	</div>

	<div class="audio-item" :class="{'is-disabled': !is.recorded || is.recording, 'is-playing': is.playing}">

		<div class="audio-item-button is-play" v-tooltip="'Play audio'" v-on:click="onPlayClick"><i class="fa fa-play"></i></div>
		<div class="audio-item-text">Play recording</div>

	</div>

</div>

</template>

<script>	

export default {

	data: function() {

		return {
			maxDuration: 10000,
			recorder: false,
			recording: false,
			timer: false,
			chunks: [],
			volume: {
				microphone: false,
				node: false,
				analyser: false,
				level: 0
			},
			is: {
				loading: true,
				recorded: false,
				recording: false,
				playing: false
			}
		}

	},

	created: function() {

		navigator.mediaDevices.getUserMedia({
			audio: true 
		}).then(function(stream) {

			this.recorder = new MediaRecorder(stream)

			this.recorder.addEventListener('dataavailable', this.onRecording.bind(this))
			this.recorder.addEventListener('stop', this.onStopped.bind(this))

			var audioContext = new AudioContext()
			this.volume.analyser = audioContext.createAnalyser()

			this.volume.microphone = audioContext.createMediaStreamSource(stream)
			this.volume.node = audioContext.createScriptProcessor(2048, 1, 1)

			this.volume.analyser.smoothingTimeConstant = 0.8
			this.volume.analyser.fftSize = 1024

			this.volume.microphone.connect(this.volume.analyser)
			this.volume.analyser.connect(this.volume.node)

			this.volume.node.connect(audioContext.destination)

			this.is.loading = false

		}.bind(this))

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

		if (this.recorder) {

			this.recorder.removeEventListener('dataavailable', this.onRecording.bind(this))
			this.recorder.removeEventListener('stop', this.onStopped.bind(this))

			this.volume.microphone.disconnect()
			this.volume.analyser.disconnect()
			this.volume.node.disconnect()

			if (this.is.recording) {

				this.volume.node.removeEventListener('audioprocess', this.onProcessing.bind(this))

				this.recorder.stop()
				
			}

			if (this.recording) {

				this.recording.removeEventListener('ended', this.onPlayed.bind(this))

			}

		}

	},

	methods: {

		onStartClick: function() {

			this.is.recording = true

			this.chunks = []

			this.volume.node.addEventListener('audioprocess', this.onProcessing.bind(this))

			this.recorder.start()

			this.timer = this.$_.delay(this.onStopClick.bind(this), this.maxDuration)

		},

		onStopClick: function() {

			this.volume.node.removeEventListener('audioprocess', this.onProcessing.bind(this))

			this.recorder.stop()

		},

		onPlayClick: function() {

			this.is.playing = true

			this.recording.play()

		},

		onProcessing: function() {

			var array = new Uint8Array(this.volume.analyser.frequencyBinCount)
			this.volume.analyser.getByteFrequencyData(array)

			var values = 0

			var length = array.length

			for (var i = 0; i < length; i++) {
				values += array[i]
			}

			var average = values / length;

			var rounded = Math.round(average)

			this.volume.level = (rounded > 100) ? 100 : rounded

		},

		onRecording: function(event) {

			this.chunks.push(event.data)

		},

		onStopped: function() {

			clearTimeout(this.timer)

			var blob = new Blob(this.chunks)
			var url = URL.createObjectURL(blob)

			this.recording = new Audio(url)

			this.recording.addEventListener('ended', this.onPlayed.bind(this))

			this.is.recording = false
			this.is.recorded = true

		},

		onPlayed: function() {

			this.is.playing = false

		}

	}

}

</script>

<style scoped>

.audio {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.audio-item {
	width: 33.3%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.audio-item-button-wrapper {
	width: 128px;
	height: 128px;
}

.audio-item-button {
	width: 128px;
	height: 128px;
	font-size: 96px;
	text-align: center;
	line-height: 128px;
	cursor: pointer;
	color: #237DC6;
	z-index: 1;
}

.audio-item-button-inner {
	width: 128px;
	height: 128px;
	position: absolute;
	left: 0px;
	bottom: 0px;
	font-size: 96px;
	text-align: center;
	line-height: 128px;
}

.audio-item-button:hover {
	color: #1b649e;
}

.audio-item.is-disabled .audio-item-button {
	color: #eee;
	pointer-events: none;
}

.audio-item.is-playing .audio-item-button {
	color: #FBB516;
	pointer-events: none;
}

.audio-item.is-disabled .audio-item-button.is-volume {
	color: #FBB516;
	position: absolute;
	left: 0px;
	bottom: 0px;
	height: 0%;
	overflow: hidden;
	z-index: 2;
}

.audio-item-button.is-play {
	font-size: 80px;
}

.audio-item-text {
	text-align: center;
	font-size: 16px;
	color: #333;
	font-weight: 300;
}

.audio-item.is-disabled .audio-item-text {
	color: #ccc;
}

</style>