<template>

<div onboarding="finished" class="finished">

	<div class="finished-text" v-if="!scene.setup.verified">

		<h1>Please wait, verifying exam session.</h1>

		<p>Do not close your browser until this message updates.</p>

		<div class="finished-icon-loading prp-loading"></div>

	</div>

	<div class="finished-text" v-if="scene.setup.verified">

		<h1>Thank you, the exam is now complete.</h1>

		<p>You may now safely close the app.</p>

		<i class="finished-icon-verified fa fa-check-circle"></i>

	</div>

</div>

</template>

<script>

export default {

	props: ['scene', 'initTime']

}

</script>

<style scoped>

.finished {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.finished-text {
	text-align: center;
}

.finished-text h1 {
	font-size: 32px;
	color: #237DC6;
	font-weight: 400;
	margin-bottom: 20px;
}

.finished-text p {
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	margin-bottom: 10px;
}

.finished-icon-loading {
	margin: 0px auto;
}

.finished-icon-verified {
	font-size: 48px;
	color: #10ab6b;
	margin-top: 10px;
}

</style>
