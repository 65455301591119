<template>
    
<div class="marking" onboarding="panel.marksheets" :class="{'is-active': panel.active}">

	<div class="marking-buttons" v-if="panel.active">

		<div onboarding="panel.marksheets.close"  class="marking-buttons-item" v-tooltip.left="'Close marking panel'"><i class="fa fa-remove"></i></div>

	</div>

	<div class="marking-content">

		<div class="candidates">

			<div class="candidates-head">

				<div class="candidates-head-title">{{ $language.role.candidates }}</div>

			</div>

			<div class="candidates-context" onboarding="panel.marksheets.context">

				<div onboarding="panel.marksheets.context.date">{{ panel.date }}</div>
				<div onboarding="panel.marksheets.context.candidates">{{ candidates.length }} <template v-if="candidates.length === 1">{{ $language.role.candidate }}</template><template v-if="candidates.length !== 1">{{ $language.role.candidates }}</template></div>

			</div>

			<app-scroll class="candidates-body" onboarding="panel.marksheets.candidates">

				<div class="candidates-item" :onboarding="'panel.marksheets.candidates.' + item.identity" :class="{'is-inactive': item.status === 'locked' || item.reference === false}" v-for="item in candidates" :key="item.identity" v-on:click="onCandidateClick(item.identity)">

					<div class="candidates-item-text" :onboarding="'panel.marksheets.candidates.' + item.identity + '.text'" >
						<div :onboarding="'panel.marksheets.candidates.' + item.identity + '.name'" class="candidates-item-text-name" v-if="item.reference !== false">{{ $language.role.candidate }} #{{ item.reference }}</div>
						<div :onboarding="'panel.marksheets.candidates.' + item.identity + '.name'" class="candidates-item-text-name" v-if="item.reference === false">No {{ $language.role.candidate }}</div>
						<div :onboarding="'panel.marksheets.candidates.' + item.identity + '.time'" class="candidates-item-text-time">{{ $moment.unix(item.time + initTime).utc().tz('Europe/London').format('h:mma') }}</div>
					</div>

					<div :onboarding="'panel.marksheets.candidates.' + item.identity + '.status'" class="candidates-item-status" v-if="item.reference !== false" :class="{'is-changed': item.status === 'changed', 'is-unsaved': item.status === 'unsaved', 'is-saved': item.status === 'saved', 'is-future': item.status === 'locked', 'is-active': item.status === 'active'}">
						{{ item.label }}
					</div>

				</div>

			</app-scroll>

		</div>

	</div>

</div>

</template>

<script>


export default {

	props: ['panel', 'initTime'],

	computed: {

		candidates: function() {

			return this.panel.candidates

		}

	}

}

</script>

<style scoped>

.marking {
	position: fixed;
	right: -256px;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
}

.marking.is-active {
	right: 0px;
}

.marking-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.marking-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #3b8ed2;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.marking-content {
	width: 100%;
	height: 100%;
}

.candidates {
	width: 256px;
	user-select: none;
	height: 100%;
	display: flex;
    background-color: #237DC6;
	transition: background-color 100ms linear;
    flex-direction: column;
}

.candidates-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.candidates-head-title {
    flex-grow: 1;
}

.candidates-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.candidates-body {
    flex-grow: 1;
    flex-basis: 0;
}

.candidates-item {
	border-bottom: 1px solid rgb(0, 0, 0, 0.1);
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.candidates-item.is-inactive {
	pointer-events: none;
}

.candidates-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: transparent;
}

.candidates-item:last-child {
	border-bottom-width: 0px;
}

.candidates-item-text {
	color: #fff;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
}

.candidates-item-text-name {
	font-size: 14px;
	font-weight: 300;
}

.candidates-item-text-time {
	font-size: 12px;
	font-weight: 300;
	opacity: 0.75;
}

.candidates-item-status {
	color: #fff;
	border-radius: 4px; 
	padding: 2px 4px;
	font-weight: 400;
	font-size: 12px;
}

.candidates-item-status.is-saved {
	background-color: #27a258;
}

.candidates-item-status.is-future {
	background-color: #1c6aa9;
	color: #5ea9e6;
}

.candidates-item-status.is-unsaved {
	background-color: #cc3939;
}

.candidates-item-status.is-changed {
	background-color: #ce9921;
}

.candidates-item-status.is-active {
	background-color: #56aef5;
}

</style>