<template>

<app-actions-icons class="actions" :class="{[clsCount]: true, 'is-compact': compact}" v-if="canDoSomething">

	<app-actions-icon icon="calendar-plus" name="Create ad hoc station" v-if="canAdhoc && !isEarly" v-on:click="onAdhoc" />

	<app-actions-icon icon="pause-circle" name="Pause event" v-on:click="onPause" v-if="canPause && !isPaused && !isEarly" />

	<app-actions-icon icon="play-circle" name="Unpause event" v-on:click="onUnpause" v-if="canPause && isPaused && !isPauseEnding" />

	<app-actions-icon icon="broadcast-tower" name="Give pause update" v-on:click="onAnnounce" v-if="canAnnounce && isPaused && !isPauseEnding" />

	<app-actions-icon icon="broadcast-tower" name="Make announcement to user(s)" v-on:click="onAnnounce" v-if="!isPaused && canAnnounce" />

	<app-actions-icon icon="people-arrows" name="Re-assign user" v-on:click="onReassign" v-if="canReassign" />

	<app-actions-icon icon="sync-alt" name="Enforce user(s) to refresh" v-on:click="onRefresh" v-if="canRefresh" />

	<app-actions-icon icon="sign-out-alt" name="Enforce user(s) to logout" v-on:click="onLogout" v-if="canLogout" />

</app-actions-icons>

</template>

<script>

export default {

	props: ['compact'],

	computed: {

		clsCount: function() {

			var count = 0

			if (this.canAdhoc) count++
			if (this.canPause) count++
			if (this.canAnnounce) count++
			if (this.canLogout) count++
			if (this.canRefresh) count++
			if (this.canReassign) count++

			return 'count-' + count.toString() + ' rows-' + Math.ceil(count / 5).toString()

		},

		canDoSomething: function() {

			return this.canAdhoc || this.canPause || this.canAnnounce || this.canLogout || this.canRefresh || this.canReassign

		},

		canAdhoc: function() {

			return this.hasPermission(this.$constants.observer.permission.adhoc) 

		},

		canPause: function() {

			return this.hasPermission(this.$constants.observer.permission.pause)

		},

		canAnnounce: function() {

			return this.hasPermission(this.$constants.observer.permission.announce)

		},

		canLogout: function() {

			return this.hasPermission(this.$constants.observer.permission.logout)

		},

		canReassign: function() {

			return this.hasPermission(this.$constants.observer.permission.reassign)

		},

		canRefresh: function() {

			return this.hasPermission(this.$constants.observer.permission.refresh)

		},

		isPauseEnding: function() {

			return this.$store.getters['event/paused/end']

		}

	},

	methods: {

		onPause: function() {

			this.$pubsub.$emit('modal.pause')

		},

		onUnpause: function() {

			this.$pubsub.$emit('modal.pause')
			
		},

		onAnnounce: function() {

			this.$pubsub.$emit('modal.announce')
			
		},

		onAdhoc: function() {

			this.$pubsub.$emit('modal.adhoc')

		},

		onReassign: function() {

			this.$pubsub.$emit('modal.reassign')

		},

		onLogout: function() {

			this.$pubsub.$emit('modal.enforce.logout')

		},

		onRefresh: function() {

			this.$pubsub.$emit('modal.enforce.refresh')

		}

	}

}

</script>

<style scoped>

.actions:not(.is-compact) {
	margin-top: 0px;
}

.actions.is-compact {
	flex-wrap: wrap;
}

.actions.is-compact >>> .icon {
	border-radius: 0px;
	margin-right: 0px;
	border-right-width: 0px;
	width: 20%;
	flex-shrink: 0;
	line-height: 44px!important;
}

.actions.is-compact >>> .icon:nth-child(1) {
	border-top-left-radius: 4px;
}

.actions.is-compact.rows-1 >>> .icon:nth-child(1) {
	border-bottom-left-radius: 4px;
}

.actions.is-compact >>> .icon:nth-child(6),
.actions.is-compact >>> .icon:nth-child(7),
.actions.is-compact >>> .icon:nth-child(8),
.actions.is-compact >>> .icon:nth-child(9),
.actions.is-compact >>> .icon:nth-child(10) {
	border-top-width: 0px;
}

.actions.is-compact >>> .icon:nth-child(5) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.is-compact.count-10 >>> .icon:nth-child(5) {
	border-bottom-right-radius: 0px;
}

.actions.is-compact >>> .icon:nth-child(6) {
	border-bottom-left-radius: 4px;
}

.actions.is-compact >>> .icon:last-child {
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.is-compact.rows-2 >>> .icon:last-child {
	width: calc(20% + 1px);
}

.actions.is-compact.rows-1 >>> .icon:last-child {
	border-top-right-radius: 4px;
	width: 20%;
}

.actions.is-compact.count-10 >>> .icon:last-child {
	width: 20%;
}

</style>
