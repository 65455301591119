<template>
	
<div class="head">

	<div class="head-menu" v-if="!marking" v-on:click="$emit('menuToggle')"><i class="fa fa-bars"></i></div>
	<div class="head-menu" v-if="marking" v-on:click="$pubsub.$emit('assistant.back')"><i class="fa fa-chevron-left"></i></div>

	<div class="head-title">

		{{ title }}
		<small v-if="subtitle">{{ subtitle }}</small>

	</div>

</div>

</template>

<script>

export default {

	props: ['section', 'marking', 'locked'],

	data: function() {

		return {
			titles: {
				1: 'Schedule',
				2: 'Marksheets',
				3: 'Briefs',
				4: 'Incidents',
				7: 'Inventory'
			}
		}

	},

	computed: {

		circuitName: function() {

			return (!this.$store.getters['people/self'].circuit) ? false : this.$store.getters['circuits/associated'][this.$store.getters['people/self'].circuit].name

		},

		eventDate: function() {

			return this.$options.filters.formatDate(this.$store.getters['event'].date, 'MM/DD/YYYY')

		},

		title: function() {

			if (this.marking) {

				return this.$language.role.candidate + ' #' + this.$store.getters['people'][this.$store.getters['schedule/associated'][this.marking].candidate].reference 

			} else {
				
				return this.titles[this.section]

			}

		},

		subtitle: function() {

			if (this.circuitName) {
				
				return this.circuitName + ', ' + this.eventDate

			} else {
				
				return this.eventDate

			}

		}

	}
	
}

</script>

<style scoped>

.head {
	height: 54px;
	background-color: #237DC6;
	flex-shrink: 0;
}

.head-menu {
	width: 54px;
	height: 54px;
	cursor: pointer;
	font-size: 32px;
	line-height: 54px;
	text-align: center;
	color: #fff;
}

.head-title {
	position: absolute;
	display: flex;
	flex-direction: column;
	height: 54px;
	justify-content: center;
	align-items: center;
	left: 50%;
	top: 0px;
	font-size: 20px;
	color: #fff;
	font-weight: 300;
	transform: translateX(-50%);
}

.head-title small {
	font-size: 14px;
	margin-top: 2px;
	display: block;
}

</style>