<template>

<app-page>

	<div class="login-prompt is-browser" v-if="!is.compatible">

		<h1>Incompatible browser</h1>

		<p>To run Osler Online you must be using Google Chrome and a desktop computer.</p>
		
		<p>Please click the button below to go to the official Google Chrome website and download the latest version.</p>

		<a href="https://www.google.com/chrome">Get Chrome</a>

	</div>

	<div class="login-prompt is-auth" v-if="is.compatible" :class="{'is-session': is.reload}">

		<div class="auth-logo prp-loading"></div>

		<template v-if="!is.reload">

			<p v-if="is.errored" class="auth-error">Sorry, your details were not recognised.</p>

			<input type="text" v-if="!is.direct" class="auth-input" v-model="inputs.event" :disabled="is.loading" v-on:keydown.enter="onSubmitClick" placeholder="Enter your event code" />

			<input type="text" class="auth-input" v-model="inputs.name" :disabled="is.loading" v-on:keydown.enter="onSubmitClick" placeholder="Enter your username" />

			<input type="password" class="auth-input" v-model="inputs.code" :disabled="is.loading" v-on:keypress.enter="onSubmitClick" placeholder="Enter your access pin" />

			<app-button :loading="is.loading" v-on:click="onSubmitClick">Login</app-button>

		</template>

	</div>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				compatible: false,
				loading: false,
				errored: false,
				direct: false,
				reload: false,
				quick: false
			},
			inputs: {
				name: '',
				code: '',
				events: ''
			},
			modules: [
				'language', 
				'onboarding', 
				'session', 
				'event', 
				'schedule', 
				'structure',
				'people', 
				'overview', 
				'chaperone', 
				'circuits', 
				'incidents', 
				'marking', 
				'messages', 
				'chats', 
				'stations', 
				'announcements', 
				'playback', 
				'time', 
				'online'
			]
		}

	},

	created: async function() {

		this.is.compatible = true //navigator.userAgent.indexOf("Chrome") > -1

		if (this.is.compatible) {

			var url = window.location.href.split('/')

			if (url.length > 3 && url[3] !== '') {

				this.is.direct = true

				url = url[3].split('?')

				this.inputs.event = url[0]
				this.is.quick = url.length > 1

			} 

			await this.$store.dispatch('init', this.inputs.event)

			if (this.$store.getters['key']) {

				this.is.reload = true

				this.$store.commit('session/reload', true)

				this.$api.request('login', {
					mode: this.loginMode,
					event: this.inputs.event
				}).then(this.onLoginSuccess.bind(this), this.onLoginFail.bind(this))

			}
			
		}

	},

	computed: {

		loginMode: function() {

			return (this.window.is.desktop) ? this.$constants.mode.event : this.$constants.mode.assistant

		}

	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('login', {
				mode: this.loginMode,
				event: this.inputs.event,
				name: this.inputs.name,
				code: this.inputs.code
			}).then(this.onLoginSuccess.bind(this), this.onLoginFail.bind(this))

		},

		onLoginFail: function() {

			this.$store.commit('session/reload', false)

			this.is.loading = false
			this.is.errored = true
			this.is.reload = false

		},

		onLoginSuccess: function(json) {

			this.$store.dispatch('cookie', {
				event: this.inputs.event,
				key: json.session.key
			})

			// update store with data and subscribe to any pusher events
			this.$_.each(this.modules, function(name) {

				this.$store.dispatch(name + '/init', json[name])

			}.bind(this))

			// load any saved notes
			this.$store.dispatch('notes/init')

			this.$store.dispatch('online/presence').then(function() {

				if (this.is.quick) {
					
					this.$store.dispatch('device/video').then(function() {

						return this.$store.dispatch('device/audio')

					}.bind(this)).then(function() {

						this.$store.commit('session/accepted', true)
						this.$store.commit('device/initiated', true)

						this.$api.request('login/ready')

						window.history.pushState({}, '', window.location.href.replace('?quick', ''))
						
						this.$store.commit('session/presence', true)

					}.bind(this))


				} else {

					this.$store.commit('session/presence', true)

				}

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login-prompt {
	width: 320px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.login-prompt.is-session {
	opacity: 0;
}

.auth-logo {
    animation-name: none;
    margin-bottom: 10px;
}

.auth-input {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 38px;
	padding: 0px 10px;
	text-align: center;
	background-color: #fff;
	margin-bottom: 10px;
	width: 100%;
}

.auth-error {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 10px;
	font-weight: 400;
	color: red;
}

.login-prompt.is-browser h1 {
	font-size: 24px;
	margin-bottom: 20px;
}

.login-prompt.is-browser p {
	font-size: 16px;
	line-height: 20px;
	color: #333;
	margin-bottom: 20px;
}

.login-prompt.is-browser a {
	display: block;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	font-weight: 400;
	cursor: pointer;
	padding: 0px 20px;
	text-transform: uppercase;
	background-color: #237DC6;
	color: #fff;
}

.login-prompt.is-browser a:hover {
	background-color: #1b649e;
}

</style>
