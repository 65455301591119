<template>
	
<div class="briefs" :class="{'is-sidebyside': isSideBySide}">

	<div class="brief-tabs" v-if="briefs.length > 1">
		
		<div class="brief-tabs-item" v-for="brief in briefs" :key="brief" v-on:click="currentBrief = brief" :class="{'is-active': currentBrief === brief}">{{ brief }}</div>

	</div>

	<template v-for="brief in briefs">

		<app-shredded v-if="currentBrief === brief || isSideBySide" :showNote="true" :initialZoom="0.6" class="brief-shredded" :key="brief" :data="{type: 'brief', brief: brief, identity: station.identity}" :schedule="station.identity" />

	</template>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			currentBrief: false,
			station: false,
			briefs: []
		}

	},

	created: function() {

		this.station = this.$_.findWhere(this.$store.getters['schedule'], {
			type: this.$constants.schedule.type.station
		})

		this.$_.each(this.station.brief, function(value, key) {

			if (value) this.briefs.push(key)
			if(!this.currentBrief) this.currentBrief = key

		}.bind(this))

	},

	computed: {

		isSideBySide: function() {

			return this.window.width >= 1110 && this.briefs.length === 2

		}

	}
	
}

</script>

<style scoped>

.briefs {
	height: 100%;
}

.brief-tabs {
	display: flex;
}

.brief-tabs-item {
	flex-grow: 1;
	flex-shrink: 0;
	height: 54px;
	line-height: 54px;
	color: #333;
	text-align: center;
	text-transform: capitalize;
	font-size: 18px;
	border-bottom: 4px solid #fff;
}

.briefs.is-sidebyside .brief-tabs-item,
.brief-tabs-item.is-active {
	border-bottom: 4px solid #237dc6;
}

.briefs.is-sidebyside .brief-tabs-item {
	pointer-events: none;
}

.briefs.is-sidebyside .brief-tabs-item:first-child {
	border-right: 1px solid #666;
}

.brief-shredded {
	height: calc(100% - 54px)!important;
}

.briefs.is-sidebyside .brief-shredded {
	width: 50%;
	position: absolute;
	top: 54px;
}

.briefs.is-sidebyside .brief-shredded:nth-child(2) {
	left: 0px;
	border-right: 1px solid #666;
}

.briefs.is-sidebyside .brief-shredded:nth-child(3) {
	left: 50%;
}

</style>