import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				active: false,
				loading: false,
				valid: false
			},
			input: {},
			params: {}
		}

	},

	watch: {

		input: {
			deep: true,
			handler: function(n) {

				this.onInputChange(n)

			}
		}

	},

	created: function() {

		this.$pubsub.$on('modal.' + this.name, this.onOpen)

	},

	beforeDestroy: function() {

		this.$pubsub.$off('modal.' + this.name, this.onOpen)

	},

	methods: {

		onOpen: function(params) {

			Vue.set(this, 'params', params || {})
			this.is.loading = false
			this.reset()
			this.is.active = true

		},

		onClose: function() {

			this.is.active = false

		}

	}

}