<template>

<div class="welcome">

	<h1>Hello, {{ name }}!</h1>

	<div class="welcome-intro" v-if="intro" v-html="intro"></div>
	
	<div class="welcome-about">

		<div class="welcome-about-item">
			<b>Event</b>{{ event }}
		</div>

		<div class="welcome-about-item">
			<b>Role</b>{{ role }}
		</div>

		<div class="welcome-about-item">
			<b>Date</b>{{ time | formatDate('DD/MM/YYYY') }}
		</div>

		<div class="welcome-about-item">
			<b>Start time</b>{{ time | formatDate('h:mma') }}
		</div>

	</div>

	<div class="welcome-preload" v-if="is.preloading">

		<div class="welcome-preload-text">Please wait, pre-loading documents...</div>

		<div class="welcome-preload-progress" :style="{width: progress}"></div>

	</div>

	<div class="welcome-buttons" v-if="!is.preloading">
		
		<app-button v-on:click="onProceedClick">Proceed</app-button>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				preloading: false
			},
			preloadTotal: 0,
			preload: []
		}

	},

	created: function() {

		//if (!this.isCandidate && !this.isRoamingChaperone) {
		if (this.isRoamingObserver) {

			var stations = this.$_.where(this.$store.getters[(this.isRoamingObserver) ? 'overview' : 'schedule'], function(item) {

				return item.type === this.$constants.schedule.type.station

			}.bind(this))

			this.$_.each(stations, function(schedule) {

				if (!this.$_.findWhere(this.preload, {
					identity: schedule.station
				}) && schedule.brief) {

					if (!this.isRoleplayer && schedule.brief.candidate) this.preload.push({
						type: 'station',
						brief: 'candidate',
						identity: schedule.station
					})

					if (schedule.brief.roleplayer) this.preload.push({
						type: 'station',
						brief: 'roleplayer',
						identity: schedule.station
					})

					if (!this.isRoleplayer && schedule.brief.examiner) this.preload.push({
						type: 'station',
						brief: 'examiner',
						identity: schedule.station
					})

					if (!this.isRoleplayer && schedule.brief.layexaminer) this.preload.push({
						type: 'station',
						brief: 'layexaminer',
						identity: schedule.station
					})

				}

			}.bind(this))
			
			if (this.preload.length) {

				this.is.preloading = true

				this.preloadTotal = this.preload.length

				this.preloadDocument()

			}

		}

	},

	computed: {

		progress: function() {

			return ((100 / this.preloadTotal) * (this.preloadTotal - this.preload.length)).toString() + '%'

		},

		name: function() {

			var parts = this.$store.getters['people/self'].name.split(' ')

			return parts[0]

		},

		event: function() {

			return this.$store.getters['event'].name

		},

		date: function() {

			return this.$store.getters['event'].date

		},

		time: function() {

			if (this.isRoamingObserver) {

				return this.$store.getters['overview/start']

			} else if (this.isRoamingChaperone) {

				return this.$store.getters['structure'][0].start

			} else {

				return this.$store.getters['schedule/start']
				
			}

		},

		role: function() {

			return this.$store.getters['language/role'](this.$store.getters['people/self'].role)

		},

		intro: function() {

			return this.$store.getters['event'].intro

		}

	},

	methods: {

		preloadDocument: async function() {

			var preloadData = this.preload.shift()

			await this.$store.dispatch('shredded/cache', preloadData)

			if (this.preload.length) {

				this.preloadDocument()

			} else {

				this.is.preloading = false

			}

		},

		onProceedClick: function() {

			this.$store.commit('session/accepted', true)

			this.$emit('proceed')
			
		}

	}

}

</script>

<style scoped>

.welcome {
	width: 640px;
	user-select: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.welcome h1 {
	margin-bottom: 20px;
	font-size: 24px;
	text-align: center;
	font-weight: 300;
}

.welcome-about {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 20px;
	color: #333;
}

.welcome-about-item {
	width: 33.3%;
	font-weight: 300;
	font-size: 16px;
}

.welcome-about-item b {
	font-size: 12px;
	margin-bottom: 4px;
	display: block;
	font-weight: 400;
	color: #333;
}

.welcome-intro {
	margin-bottom: 20px;
}

.welcome-intro >>> p {
	font-size: 16px;
	line-height: 20px;
}

.welcome-intro >>> p b {
	font-weight: 500;
}

.welcome-intro >>> p i {
	font-style: italic;
}

.welcome-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.welcome-preload {
	height: 32px;
	border-radius: 4px;
	background-color: #eee;
	margin-bottom: 32px;
}

.welcome-preload-progress {
	width: 0%;
	border-radius: 4px;
	transition: 100ms linear;
	height: 32px;
	background-image: linear-gradient(45deg, #237dc6 25%, #4b9adb 25%, #4b9adb 50%, #237dc6 50%, #237dc6 75%, #4b9adb 75%, #4b9adb 100%);
	background-size: 56.57px 56.57px;
}

.welcome-preload-text {
	font-size: 14px;
	position: absolute;
	top: 40px;
	text-align: center;
}

</style>
