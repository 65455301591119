export default {

	marksheet: {
		status: {
			locked: 1,
			saved: 2,
			unsaved: 3,
			active: 4,
			changed: 5
		},
		statusLabel: {
			1: 'Not started',
			2: 'Completed',
			3: 'Not saved',
			4: 'In progress',
			5: 'Changed'
		}
	},

	chat: {
		status: {
			active: 1,
			finished: 2
		}
	},

	inventory: {
		interaction: {
			free: 0,
			observe: 1
		},
		availability: {
			always: 0,
			time: 1,
			trigger: 2,
			permission: {
				role: 1,
				user: 2,
				lead: 3
			}
		}
	},

	mirror: {
		perRoom: 45
	},

	meeting: {
		format: {
			standard: 1,
			webinar: 2
		}	
	},

	interval: {
		format: {
			standard: 1,
			placeholder: 2,
			activity: 2
		}
	},
	
	chaperone: {
		off: 0,
		individual: 1,
		group: 2
	},

	mode: {
		event: 1,
		assistant: 2
	},

	delivery: {
		online: 1,
		offline: 2
	},

	assistant: {
		content: {
			schedule: 1,
			marksheets: 2,
			briefs: 3,
			incidents: 4,
			messaging: 5,
			announcements: 6,
			inventories: 7,
			marking: 8
		}
	},
	
	face: {
		detected: 1,
		undetected: 2,
		big: 3,
		small: 4,
		left: 5,
		right: 6,
		high: 7,
		low: 8
	},

	setup: {
		status: {
			requesting: 1,
			notexist: 2,
			permission: 3,
			blocked: 4,
			checking: 5,
			unknown: 6,
			ok: 7,
			nocamera: 8,
			nomicrophone: 9,
			nodevices: 10,
			failed: 11,
			strong: 12,
			good: 13,
			weak: 14,
			bad: 15,
			connection: 16,
			notdone: 17
		}
	},

	status: {

		loading: 1,
		errored: 2

	},

	observer: {

		incidents: 1,
		rollcall: 2,
		schedule: 3,
		overview: 4,
		chaperones: 5,
		marking: 6,

		announcement: {
			all: 2,
			limited: 3,
			role: 4
		},

		sections: {
			incidents: 1,
			rollcall: 2,
			schedule: 3,
			overview: 4,
			chaperones: 5,
			marking: 6
		},

		scope: {
			event: 1,
			circuit: 2,
			station: 3,
			candidate: 4,
			circuits: 5
		},

		permission: {
			pause: 1,
			adhoc: 2,
			incidents: 3,
			announce: 4,
			logout: 5,
			refresh: 6,
			reassign: 7,
			progression: 8,
			chaperone: 9,
			groupchat: 10,
			marking: 11,
			extend: 12
		}
		
	},
	
	event: {
		type: {
			exam: 1,
			open: 2
		}
	},
    
    marking: {
        question: {
            type: {
                scale: 1,
                text: 2,
                check: 3,
				toggle: 4,
				pbf: 5,
				epbf: 6,
				cpfc: 7,
				cpbbfc: 8,
				dropdown: 9,
				info: 10,
				ucbpc: 11
            }
        }
    },

	card: {

		type: {
			file: 1,
            participant: 2,
			notepad: 3,
			screenshare: 4
		}

	},

	schedule: {

		early: 3600,

		type: {

			station: 1,
			meeting: 2,
			break: 3,
			interval: 3,
			chaperone: 4

		},

		typeName: {
			1: 'Station',
			2: 'Meeting',
			3: 'Break',
			4: 'Chaperone'
		}

	},

	station: {
		type: {
			scheduled: 1,
			backup: 2
		}
	},

	role: {

		candidate: 1,
		roleplayer: 2,
		examiner: 3,
		observer: 4,
		layexaminer: 5

	},

	pusher: {

		cluster: 'eu'

	},

	playback: {
		marking: {
			live: 0,
			recorded: 1,
			both: 2
		}
	}

}