<template>

<app-actions-icons onboarding="sidebar.actions" class="actions" :class="{'is-disabled': isPaused, [clsCount]: true}" v-if="canDoSomething">

	<app-actions-icon onboarding="sidebar.actions.raisehand" icon="hand-paper" name="Raise hand" v-if="canRaiseHand && !isHighlighted" :loading="is.raising" />

	<app-actions-icon onboarding="sidebar.actions.lowerhand" icon="hand-rock" name="Lower hand" v-if="canRaiseHand && isHighlighted" :loading="is.raising" />

	<app-actions-icon onboarding="sidebar.actions.startvideo" icon="user-friends" :name="'Start video chat with ' + $language.role.candidate" v-if="!isTalking && canTalk" />

	<app-actions-icon onboarding="sidebar.actions.endvideo" icon="user-slash" :name="'End video chat with ' + $language.role.candidate" v-if="isTalking && canTalk" />

	<app-actions-icon onboarding="sidebar.actions.mute" icon="microphone" name="Mute microphone" v-if="!isMuted && canToggleMicrophone" />

	<app-actions-icon onboarding="sidebar.actions.unmute" icon="microphone-slash" name="Unmute microphone" v-if="isMuted && canToggleMicrophone"/>

	<app-actions-icon onboarding="sidebar.actions.hidevideo" icon="video" name="Hide camera" v-if="!isHidden && canToggleVideo" />

	<app-actions-icon onboarding="sidebar.actions.showvideo" icon="video-slash" name="Show camera" v-if="isHidden && canToggleVideo" />

	<app-actions-icon onboarding="sidebar.actions.startscreen" icon="desktop" name="Start screenshare" v-if="canScreenShare && !isScreenSharing" />

	<app-actions-icon onboarding="sidebar.actions.endscreen" icon="desktop" :slash="true" name="Stop screenshare" v-if="canScreenShare && isScreenSharing" />

	<app-actions-icon onboarding="sidebar.actions.candidatebrief" :icon="candidateIcon" :name="candidateName" v-if="canReadCandidateBrief" />

	<app-actions-icon onboarding="sidebar.actions.roleplayerbrief" :icon="roleplayerIcon" :name="roleplayerName" v-if="canReadRoleplayerBrief" />

	<app-actions-icon onboarding="sidebar.actions.examinerbrief" :icon="examinerIcon" :name="examinerName" v-if="canReadExaminerBrief" />

	<app-actions-icon onboarding="sidebar.actions.notes" icon="pencil-alt" name="Notepad" v-if="canMakeNotes" />

	<app-actions-icon onboarding="sidebar.actions.marks" icon="marker" name="Toggle marking panel" v-if="canMark" />

	<app-actions-icon onboarding="sidebar.actions.users" icon="users" name="Toggle attendee panel" v-if="canUsers" />

	<app-actions-icon onboarding="sidebar.actions.participate" icon="user-plus" :name="participateName" v-if="canParticipate" :loading="is.participate" />

	<app-actions-icon onboarding="sidebar.actions.observe" icon="user-secret" name="Back to observing only" v-if="canObserve" :loading="is.observe" />

	<app-actions-icon onboarding="sidebar.actions.leave" icon="door-open" :loading="is.leave" name="Leave room" v-if="canLeave" />

</app-actions-icons>

</template>

<script>

export default {

	props: ['actions', 'role'],

	computed: {

		clsCount: function() {

			var count = 0

			if (this.canMark) count++
			if (this.canParticipate) count++
			if (this.canObserve) count++
			if (this.canToggleVideo) count++
			if (this.canToggleMicrophone) count++
			if (this.canScreenShare) count++
			if (this.canReadCandidateBrief) count++
			if (this.canReadRoleplayerBrief) count++
			if (this.canReadExaminerBrief) count++
			if (this.canMakeNotes) count++
			if (this.canTalk) count++
			if (this.canLeave) count++
			if (this.canRaiseHand) count++
			if (this.canUsers) count++

			return 'count-' + count.toString() + ' rows-' + Math.ceil(count / 5).toString()

		},

		canDoSomething: function() {

			return this.canRaiseHand || this.canUsers || this.canScreenShare || this.canToggleVideo || this.canTalk || this.canMark || this.canParticipate || this.canObserve || this.canToggleMicrophone || this.canReadExaminerBrief || this.canReadCandidateBrief || this.canReadRoleplayerBrief || this.canMakeNotes || this.canLeave

		},

		canScreenShare: function() {

			return this.actions.screenshare

		},

		isScreenSharing: function() {

			return this.actions.screensharing

		},

		canTalk: function() {

			return this.actions.screenshare

		},

		isTalking: function() {

			return this.actions.talking

		},

		isHighlighted: function() {

			return this.actions.highlighted

		},
		
		canMark: function() {

			return this.actions.mark

		},

		canToggleVideo: function() {

			return this.actions.togglevideo

		},

		canToggleMicrophone: function() {

			return this.actions.toggleaudio

		},

		canRaiseHand: function() {

			return this.actions.raisehand

		},

		canUsers: function() {

			return this.actions.users

		},

		candidateIcon: function() {

			return (this.role === 'candidate') ? 'file-alt' : 'user-tie'

		},

		roleplayerIcon: function() {

			return (this.role === 'candidate') ? 'file-alt' : 'user'

		},

		examinerIcon: function() {

			return 'user'

		},

		participateName: function() {

			return 'Participate in video chat'

		},

		candidateName: function() {

			return 'Brief'

		},

		roleplayerName: function() {

			return 'Brief'

		},

		examinerName: function() {

			return this.$language.role.examiner + ' brief'

		},
        
        canMakeNotes: function() {

			return this.actions.notes

        },

		canObserve: function() {

			return this.actions.observe

		},

		canParticipate: function() {

			return this.actions.participate

		},

		canLeave: function() {

			return this.actions.leave

		},

		canReadCandidateBrief: function() {

			return this.actions.candidatebrief

		},

		canReadExaminerBrief: function() {

			return this.actions.examinerbrief

		},

		canReadRoleplayerBrief: function() {

			return this.actions.roleplayerbrief

		},

		isActiveStation: function() {

			return false

		},

		isActiveMeeting: function() {

			return true

		},

		isMuted: function() {

			return this.actions.muted

		},

		isHidden: function() {

			return this.actions.hidden

		}	

	}

}

</script>

<style scoped>

.actions.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.actions {
	flex-wrap: wrap;
}

.actions >>> .icon {
	border-radius: 0px;
	margin-right: 0px;
	border-right-width: 0px;
	width: 20%;
	flex-shrink: 0;
	line-height: 44px!important;
}

.actions >>> .icon:nth-child(1) {
	border-top-left-radius: 4px;
}

.actions.rows-1 >>> .icon:nth-child(1) {
	border-bottom-left-radius: 4px;
}

.actions >>> .icon:nth-child(6),
.actions >>> .icon:nth-child(7),
.actions >>> .icon:nth-child(8),
.actions >>> .icon:nth-child(9),
.actions >>> .icon:nth-child(10) {
	border-top-width: 0px;
}

.actions >>> .icon:nth-child(5) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.count-10 >>> .icon:nth-child(5) {
	border-bottom-right-radius: 0px;
}

.actions >>> .icon:nth-child(6) {
	border-bottom-left-radius: 4px;
}

.actions >>> .icon:last-child {
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

.actions.rows-2 >>> .icon:last-child {
	width: calc(20% + 1px);
}

.actions.rows-1 >>> .icon:last-child {
	border-top-right-radius: 4px;
	width: 20%;
}

.actions.count-10 >>> .icon:last-child {
	width: 20%;
}

</style>
