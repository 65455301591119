<template>

<div class="schedule">

	<div class="schedule-day" v-for="(day, index) in days" :key="index">

		<div class="schedule-day-title" v-if="days.length > 1">{{ day.date | formatDate('Do MMMM') }}</div>

		<div class="schedule-round" v-for="(round, time) in rounds(day.value)" :key="time" v-on:click="toggleExpand(time)" :class="{'has-colour': $colour, 'is-expanded': $_.contains(expanded, time), 'is-done': allCompleted(round)}" :style="{borderColor: $colour}">

			<div class="schedule-round-head">

				{{ time | formatDate('HH:mm:ss') }}

			</div>

			<div class="schedule-round-body">

				<div class="schedule-round-item" v-tooltip.top-end="tooltip(item)" v-for="(item, index) in round" :key="index" v-on:click="onScheduleClick(item)" :class="{'is-completed': isCompleted(item), 'is-active': isCurrent(item), 'is-break': item.type === $constants.schedule.type.break}">

					<div class="schedule-round-item-icon fa fa-users" v-if="item.type === $constants.schedule.type.meeting && item.format === $constants.meeting.format.standard"></div>
					<div class="schedule-round-item-icon fas fa-chalkboard-teacher" v-if="item.type === $constants.schedule.type.meeting && item.format === $constants.meeting.format.webinar"></div>
					<div class="schedule-round-item-icon fa fa-coffee" v-if="item.type === $constants.schedule.type.break && item.format == $constants.interval.format.standard"></div>
					<div class="schedule-round-item-icon fa fa-hourglass-half" v-if="item.type === $constants.schedule.type.break && item.format == $constants.interval.format.activity"></div>
					<div class="schedule-round-item-icon fa fa-graduation-cap" v-if="item.type === $constants.schedule.type.station && !isEmpty(item.candidate)"></div>
					<div class="schedule-round-item-icon fa fa-user-slash" v-if="item.type === $constants.schedule.type.station && isEmpty(item.candidate)"></div>

					<div class="schedule-round-item-name">{{ item.name }}</div>
					<div class="schedule-round-item-status">{{ itemStatus(item) }}</div>

					<div class="schedule-round-item-people" :class="{'is-current': isCurrent(item)}" v-if="item.type === $constants.schedule.type.station && !isCompleted(item)">
						<div class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.candidate), 'is-ready': isReady(item.candidate), 'is-offline': isOffline(item.candidate)}">{{ $language.role.c }}</div>
						<div v-if="item.roleplayer" class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.roleplayer), 'is-ready': isReady(item.roleplayer), 'is-offline': isOffline(item.roleplayer)}">{{ $language.role.r }}</div>
						<div class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.examiner), 'is-ready': isReady(item.examiner), 'is-offline': isOffline(item.examiner)}">{{ $language.role.e }}</div>
						<div v-if="item.layexaminer" class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.layexaminer), 'is-ready': isReady(item.layexaminer), 'is-offline': isOffline(item.layexaminer)}">{{ $language.role.l }}</div>
					</div>

					<div class="schedule-round-item-check fa fa-check-circle" v-if="isCompleted(item)"></div>
					<div class="schedule-round-item-check fa fa-broadcast-tower" v-if="isCurrent(item)"></div>

				</div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				joining: false
			},
			expanded: []
		}

	},

	computed: {

		days: function() {

			return this.$store.getters['event'].days

		},

		schedule: function() {

			return this.$_.filter(this.$store.getters['overview'], function(item) {
				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false
			}.bind(this))

		}

	},

	methods: {

		rounds: function(day) {

			var rounds = {}

			this.$_.each(this.schedule, function(item) {

				if (item.day === day) {

					rounds[item.start] = rounds[item.start] || []

					rounds[item.start].push(item)

				}

			})

			return rounds

		},

		toggleExpand: function(round) {

			if (this.$_.contains(this.expanded, round)) {

				this.expanded.splice(this.expanded.indexOf(round), 1)

			} else {

				this.expanded.push(round)

			}

		},

		allCompleted: function(round) {

			var total = 0

			this.$_.each(round, function(item) {

				if (this.isCompleted(item)) total++

			}.bind(this))

			return total === round.length

		},

		tooltip: function(schedule) {

			if (schedule.type !== this.$constants.schedule.type.station) return false

			var tooltip = []
			var presence = ''

			this.$_.each(schedule.participants, function(participant) {

				presence = 'is-offline'

				if (this.isOnline(participant)) presence = 'is-online'
				if (this.isReady(participant)) presence = 'is-ready'

				tooltip.push('<div class="schedule-tooltip-participants-item ' + presence + '">' + this.$store.getters['people'][participant].name + '<small>' + this.$store.getters['language/role'](this.$store.getters['people'][participant].role) + '</small></div>')

			}.bind(this))
			
			return {
				content: tooltip.join(''),
				classes: ['schedule-tooltip']
			}

		},

		isOnline: function(person) {

			return this.$_.contains(this.$store.getters['online'], person)

		},

		isReady: function(person) {

			return this.$_.contains(this.$store.getters['online/ready'], person)

		},

		isOffline: function(person) {

			return !this.$_.contains(this.$store.getters['online'], person)

		},

		isEmpty: function(person) {

			return (person) ? this.$store.getters['people'][person].empty : false

		},

		onScheduleClick: function(item) {

			if (item.type !== this.$constants.schedule.type.break && !this.isCompleted(item)) {

				this.$emit('join', item.identity)

			}

		},

		itemStatus: function(item) {

			if (this.isNext(item)) {

				var difference = item.start - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				}

				return 'Begins in ' + remaining

			} else if (this.isCompleted(item)) {

				return 'Completed'

			} else if (this.isCurrent(item)) {

				return 'In progress'

			} else {

				var minutes = Math.floor((item.end - item.start) / 60)

				return 'Duration: ' + minutes + ' minute' + ((minutes === 1) ? '' : 's')

			}

		},

		isNext: function() {

			return false

		},

		isCurrent: function(item) {

			return item.start < this.time && item.end > this.time

		},

		isCompleted: function(item) {
		
			return item.end < this.time

		}

	}

}

</script>

<style scoped>

.schedule {
	display: flex;
	flex-direction: column;
}

.schedule-day {
	display: flex;
	flex-direction: column;
}

.schedule-day-title {
	border-radius: 4px;
	margin-bottom: 10px;
	background-color: #237DC6;
	color: #fff;
	padding: 10px;
	font-size: 16px;
	font-weight: 400;
}

.schedule-round {
	margin: 0px 10px 10px 0px;
	width: 100%;
}

.schedule-round.is-done {
	cursor: pointer;
}

.schedule-round.is-done:not(.is-expanded) {
	height: 32px;
	overflow: hidden;
	opacity: 0.5;
}

.schedule-round.is-done:hover {
	opacity: 1;
}

.schedule-round.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.schedule > *:last-child {
	margin-bottom: 0px;
}

.schedule-round-head {
	background-color: #237DC6;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px;
	margin-left: 1px;
}

.schedule-round-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
	background-color: #fff;
}

.schedule-round.has-colour .schedule-round-body {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

.schedule-round-item {
	background-color: #fff;
	font-size: 16px;
	line-height: 20px;
	padding: 6px 10px;
	cursor: pointer;
	padding-left: 46px;
	width: calc(33% - 1px);
	border-radius: 4px;
	margin: 1px 0px 0px 1px;
}

.schedule-round-item:nth-child(3n) {
	width: calc(34% - 1px);
}

.schedule-round-item:nth-child(even) {
	border: 1px solid #efefef;
}

.schedule-round-item:nth-child(odd) {
	background-color: #efefef;
}

.schedule-round-item.is-break {
	cursor: initial;
	pointer-events: none;
}

.schedule-round-item.is-complete {
	cursor: initial;
}

.schedule-round-item.is-active {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-round-item:nth-child(3n) {
	border-right: 0px;
}

.schedule-round-item:hover {
	background-color: #eee;
}

.schedule-round-item.is-active:hover {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-round-item-icon {
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
}

.schedule-round-item-name {
	font-size: 16px;
	font-weight: 300;
}

.schedule-round-item.is-active .schedule-round-item-name { 
	font-weight: 400;
}

.schedule-round-head {
	font-weight: 400;
}

.schedule-round-item-status {
	font-size: 12px;
	font-weight: 300;
}

.schedule-round-item.is-completed {
	color: #999;
}

.schedule-round-item-people {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -6px;
	font-size: 16px;
	color: #23c64f;
	display: flex;
	flex-direction: row;
}

.schedule-round-item-people.is-current {
	right: 54px;
}

.schedule-round-item-people-item {
	width: 16px;
	height: 16px;
	border-radius: 8px;
	margin-left: 2px;
	background-color: red;
	line-height: 16px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
}

.schedule-round-item-people-item.is-online {
	background-color: #f5c638;
}

.schedule-round-item-people-item.is-ready {
	background-color: #1ed43f;
}

.schedule-round-item-people-item.is-offline {
	background-color: #c62323;
}

.schedule-round-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #237DC6;
}

.schedule-round-item.is-active .schedule-round-item-check {
	color: #fff;
}

</style>