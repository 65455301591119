import Cookies from 'js-cookie'

export default {

	namespaced: true,

	state: {

		notes: ''

	},

	getters: {

		get: function(state) {

			return state.notes

		}

	},

	mutations: {

		set: function(state, value) {

			state.notes = value

			Cookies.set('notes', state.notes)

		},

		reset: function(state) {

			state.notes = ''

			Cookies.set('notes', '')

		}

	},

	actions: {

		init: function(context) {

			context.commit('set', Cookies.get('notes'))

		}

	}

}