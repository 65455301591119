<template>

<div class="content">

	<app-waiting v-if="!isActive && next && !isPaused && (!isEarly || isRoamingObserver)" :text="nextMessage" :deadline="next.start" />

	<com-test v-if="isTesting && (!isStation || isPreparation) && !isMeeting" />
	<com-early v-if="isEarly && !isTesting && !isRoamingObserver" />

	<com-paused v-if="isPaused && (!isRoamingObserver || hasSchedule)" />

	<template v-if="!$isEventFinished && !isPaused">

		<com-station v-if="isStation" />
		<com-break v-if="isBreak" />
		<com-meeting v-if="isMeeting" />

	</template>

	<com-chaperone v-if="isChaperoneGroup && !$isEventFinished" />

	<com-finished v-if="$isEventFinished && !$isPlaybackEnabled && !isPaused && !isRoamingObserver" />

	<com-playback v-if="($isEventFinished || $isInterval) && $isPlaybackEnabled && !isPaused && !isRoamingObserver && $isMarkingEnabled" />
	<app-waiting v-if="($isEventFinished || $isInterval) && $isPlaybackEnabled && !isPaused && !isRoamingObserver && !$isMarkingEnabled" text="Playback begins in" :deadline="$store.getters['event/playback/marking'].from" />
	
	<com-observer v-if="isRoamingObserver && !isActive && !next" />

</div>

</template>

<script>

import comPlayback from './content/Playback'
import comFinished from './content/Finished'
import comObserver from './content/Observer'
import comStation from './content/Station'
import comChaperone from './content/Chaperone'
import comTest from './content/Test'
import comMeeting from './content/Meeting'
import comEarly from './content/Early'
import comPaused from './content/Paused'
import comBreak from './content/Break'

export default {

	components: {
		'com-playback': comPlayback,
		'com-finished': comFinished,
		'com-observer': comObserver,
		'com-test': comTest,
		'com-early': comEarly,
		'com-station': comStation,
		'com-chaperone': comChaperone,
		'com-meeting': comMeeting,
		'com-break': comBreak,
		'com-paused': comPaused
	},

	data: function() {

		return {
			toggle: {
				setup: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('content.setup', this.onSetupToggle.bind(this))
		if (this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.individual || this.isCandidate) this.$chaperone.connect()

	},

	beforeDestroy: function() {

		this.$pubsub.$off('content.setup', this.onSetupToggle.bind(this))
		if (this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.individual || this.isCandidate) this.$chaperone.disconnect(false, true)

	},

	computed: {

		isTesting: function() {

			return this.toggle.setup

		},

		hasSchedule: function() {

			return this.$store.getters['schedule'].length

        },

		nextMessage: function() {

			return (this.next.type === this.$constants.schedule.type.station && this.next.duration.preparation) ? 'Please wait for reading time to begin.' : 'Please wait, ' + this.next.name + ' will begin shortly.'

		},

		next: function() {

			return this.$store.getters['schedule/next']

		}

	},

	methods: {

		onSetupToggle: function() {

			this.toggle.setup = !this.toggle.setup

		}

	}

}

</script>

<style scoped>

.content {
	position: absolute;
	left: 256px;
	top: 10px;
	right: 10px;
	bottom: 64px;
	user-select: none;
}

</style>
