<template>

<div class="meeting">

	<div class="meeting-warning" v-if="isMeetingEnding" :class="{'is-active': isMeetingEnding}">{{ countdown(meetingEnd) }}</div>

	<app-room :item="schedule" :cards="cards" :pinsEditable="isLead" :forceLocal="onlyLocal" :showLocalPinned="true" />
	
</div>

</template>

<script>

export default {

	computed: {

		onlyLocal: function() {

			return this.schedule.participants.length === 1

		},

		schedule: function() {

			return this.$store.getters['schedule/active']

		},

		meetingEnd: function() {

			return this.schedule.end

		},

		isMeetingEnding: function() {

			return this.countdown(this.schedule.end, true) <= 10

		},

		cards: function() {

			var cards = []

			this.$_.each(this.schedule.inventory, function(inventory) {
				
				var show = false

				if (inventory.availability.type === this.$constants.inventory.availability.always) {

					show = true

				} else if (inventory.availability.type === this.$constants.inventory.availability.time) {

					show = inventory.availability.timer <= this.roleplayElapsed

				} else if (inventory.availability.type === this.$constants.inventory.availability.trigger) {

					var isPermitted = false, revealed = false

					if (inventory.availability.permission === this.$constants.inventory.availability.permission.role) {

						isPermitted = this.$_.contains(inventory.availability.roles, this.$store.getters['people/self'].role)

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.user) {

						isPermitted = this.$_.contains(inventory.availability.logins, this.$store.getters['session/identity'])

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.lead) {

						isPermitted = this.isLead

					}

					revealed = this.$_.contains(this.$_.keys(this.schedule.triggered), inventory.id)

					if (isPermitted) {

						show = true

					} else {

						show = revealed

					}

					if (revealed) {

						if (this.schedule.triggered[inventory.id] !== this.$store.getters['session/identity']) {

							isPermitted = false

						}

					}

				}
				
				if (show) {

					cards.push({
						type: this.$constants.card.type.file,
						identity: inventory.id,
						observeIdentity: this.schedule.id + '-' + inventory.id,
						title: inventory.name,
						subtitle: 'Inventory',
						availability: inventory.availability,
						interaction: inventory.interaction,
						triggerer: this.schedule.triggered[inventory.id],
						revealed: revealed,
						image: inventory.image,
						video: inventory.video,
						shredded: (inventory.pdf) ? {
							type: 'inventory',
							identity: inventory.id
						} : false
					})
					
				}

			}.bind(this))

			return cards

		},

	}

}

</script>

<style scoped>

.meeting {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}


.meeting-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 1;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.meeting-warning.is-active {
	opacity: 1;
}


</style>