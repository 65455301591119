<template>

<com-card class="participant" :hidePin="is.video || is.expanded" :miniview="miniview" :allowFullsceen="allowFullsceen" :expandable="expandable" :expanded="expanded" v-on:expand="$emit('expand')" v-on:collapse="$emit('collapse')" :class="{'is-online': online}" :type="$constants.card.type.participant" :lead="leading" :circuit="circuit" :title="title" :subtitle="subtitle" :role="role" :plain="plain" :hideMore="isCandidate && isStation && !local" :focus="!is.localMute && speaking" :pinSettings="pin" v-on:pin="$emit('pin')">

	<com-recording v-on:canplay="$emit('canplay')" :recording="showRecording" :avatar="hasAvatar" v-if="showRecording" :audioOnly="audioOnly" />
	<com-stream :forced="forced" v-if="showStream" :miniview="miniview" :avatar="hasAvatar" :localMute="is.localMute" :audioOnly="audioOnly" v-on:screenStart="$emit('screenStart')" :showScreenshare="showScreenshare" v-on:screenEnd="$emit('screenEnd')" :stream="stream" :speaking="speaking" v-on:attached="$emit('attached', arguments[0])" />

	<div class="participant-status" v-if="!showStream && !plain && !miniview">

		<template v-if="!showAsPinned && !showAsAlone && !showAsObserving && !showAsHighlighted">

			<div class="participant-status-label" v-if="!online && !local && !$isActivePlayback">Offline</div>
			<i class="participant-status-icon fa fa-user-slash" v-if="!online && !local"></i>

			<div class="participant-status-label" v-if="online && !local && !$isActivePlayback">Connecting</div>
			<i class="participant-status-icon fa fa-user" v-if="online"></i>

		</template> 

		<i class="participant-status-icon fa fa-thumbtack" v-if="showAsPinned || showAsAlone"></i>
		<i class="participant-status-icon fa fa-video-slash" v-if="showAsObserving && !showAsHighlighted"></i>
		<i class="participant-status-icon is-blue fa fa-hand-paper" v-if="showAsHighlighted && local"></i>

	</div>

	<template v-slot:actions v-if="!local">

		<template v-if="$isWebinar && isLead && person.identity !== $store.getters['people/self'].identity">

			<button class="card-foot-actions-item" v-tooltip="'Remove from video chat'" v-on:click="onRemoveClick" :class="{'is-loading': is.inviting}"><i class="fa fa-user-minus"></i></button>

		</template>
		
		<template v-if="isChaperoneGroup">

			<button class="card-foot-actions-item is-active" v-tooltip="'Mute microphone'" v-if="!is.localMute" v-on:click="onMuteClick"><i class="fa fa-microphone"></i></button>
			<button class="card-foot-actions-item" v-tooltip="'Unmute microphone'" :class="{'is-disabled': !showStream}" v-if="is.localMute" v-on:click="onMuteClick"><i class="fa fa-microphone-slash"></i></button>
			<button class="card-foot-actions-item" :class="{'is-active': activeChat}" v-tooltip="'Direct messages'" v-on:click="onMessageClick"><i class="fa fa-comments"></i><div class="card-foot-actions-item-counter" v-if="unreadChat">{{ unreadChat }}</div></button>
			<button class="card-foot-actions-item" v-if="!is.video" v-tooltip="'Start video chat'" v-on:click="onVideoStartClick" :class="{'is-disabled': (isParticipantActive && !isParticipantReading) || !showStream || isTalking || isAlreadyVideoing}"><i class="fa fa-user-friends"></i></button>
			<button class="card-foot-actions-item is-active" v-if="is.video" v-tooltip="'End video chat'" v-on:click="onVideoEndClick"><i class="fa fa-user-slash"></i></button>
			<button class="card-foot-actions-item" v-tooltip="tipObserve" v-on:click="onObserveClick" :class="{'is-disabled': !isParticipantActive || is.video || isTalking, 'is-loading': is.joining}"><i class="fa fa-user-secret"></i></button>
			<button class="card-foot-actions-item" v-if="!is.expanded" v-on:click="onExpandClick" v-tooltip="'Click to enlarge'" :class="{'is-disabled': !showStream && !is.video}"><i class="fa fa-expand-alt"></i></button>
			<button class="card-foot-actions-item" v-if="is.expanded" v-on:click="onExpandClick" v-tooltip="'Click to shrink'" :class="{'is-disabled': !showStream && !is.video}"><i class="fa fa-compress-alt"></i></button>

		</template>

	</template>

</com-card>

</template>

<script>

import comCard from './Card'
import comRecording from './participant/Recording'
import comStream from './participant/Stream'

export default {

	props: ['person', 'stream', 'speaking', 'forced', 'miniview', 'allowFullsceen', 'showScreenshare', 'pin', 'local', 'plain', 'expandable', 'expanded'],

	components: {

		'com-card': comCard,
		'com-stream': comStream,
		'com-recording': comRecording

	},

	data: function() {

		return {
			is: {
				localMute: false,
				expanded: false,
				joining: false,
				video: false,
				inviting: false,
				roleplaying: false
			}
		}

	},

	created: function() {

		if (this.isChaperoneGroup) {

			this.is.localMute = true

			this.$pubsub.$on('participant.local.unmute', this.onLocalMute.bind(this))
			this.$pubsub.$on('participant.local.expand', this.onLocalExpand.bind(this))

		} 

	},

	beforeDestroy: function() {

		this.$pubsub.$off('participant.local.unmute', this.onLocalMute.bind(this))
		this.$pubsub.$off('participant.local.expand', this.onLocalExpand.bind(this))

		if (this.is.video) this.onVideoEndClick()

	},

	watch: {

		isRoleplaying: function(n) {

			if (n && this.isChaperoneGroup) {

				this.is.localMute = true
				this.is.expanded = false
				this.onVideoEndClick()

			}

		},

		showStream: function(n) {

			if (!n && this.isChaperoneGroup) {
				
				this.is.localMute = true
				this.is.expanded = false
				this.onVideoEndClick()

			}

		}

	},

	computed: {

		showRecording: function() {

			return (this.$isActivePlayback) ? this.$isActivePlayback.videos[this.person.identity] : false

		},

		isRoleplaying: function() {

			return this.isParticipantActive && !this.isParticipantReading

		},

		showStream: function() {

			return this.stream && ((!this.showAsObserving && !this.showAsPinned && !this.showAsAlone) || this.isSharing || this.forced)

		},

		isSharing: function() {

			return this.$store.getters['device/screen']

		},

		showAsObserving: function() {

			return this.isObserving && this.local && !this.isTalking

		},

		showAsHighlighted: function() {
		
			return this.$_.contains(this.isActive.highlighted, this.person.identity)

		},

		showAsPinned: function() {

			return (this.pin && this.isMeeting && this.local) ? this.pin.active : false

		},

		showAsAlone: function() {
			
			return (this.isActive) ? this.isActive.participants.length === 1 && this.local && this.isMeeting && this.isActive.participants[0] === this.person.identity : false

		},

		online: function() {

			return this.$_.contains(this.$store.getters['online'], this.person.identity) 

		},

		leading: function() {

			return this.$_.contains(this.isActive.leads, this.person.identity) && this.isMeeting

		},

		audioOnly: function() {

			return (this.isStation) ? !this.isActive.permissions.video : false

		},

		hasAvatar: function() {

			if (this.isStation) {

				if (this.person.role === this.$constants.role.roleplayer && this.isActive.avatar.roleplayer) return this.isActive.avatar.roleplayer
				if (this.person.role === this.$constants.role.layexaminer && this.isActive.avatar.layexaminer) return this.isActive.avatar.layexaminer
				if (this.person.role === this.$constants.role.candidate && this.isActive.avatar.candidate) return this.isActive.avatar.candidate
				if (this.person.role === this.$constants.role.examiner && this.isActive.avatar.examiner) return this.isActive.avatar.examiner

			}	

			return false

		},

		circuit: function() {

			return (this.person.circuit && (!this.isChaperoneGroup || this.person.chaperone)) ? this.$store.getters['circuits/associated'][this.person.circuit].name : false

		},

		title: function() {

			return this.person.name || 'Unnamed'

		},

		subtitle: function() {

			if (this.isChaperoneGroup && !this.local) {

				var schedule = this.$_.filter(this.$store.getters['overview'], function(item) {

					return this.$_.contains(item.participants, this.person.identity)

				}.bind(this))

				var item = this.$_.find(schedule, function(item) {

					return item.start < this.time && item.end > this.time

				}.bind(this))

				if (!item) item = this.$_.find(schedule, function(item) {

					return item.start > this.time

				}.bind(this))

				if (!item) {

					return 'Exam finished'

				} else {

					if (item.start > this.time) {

						return item.name + ' begins in ' + this.countdown(item.start)

					} else {

						if (item.type === this.$constants.schedule.type.station) {

							if (item.roleplay > this.time) {

								return item.name + ' reading time ends in ' + this.countdown(item.roleplay)

							} else {

								return item.name + ' roleplay ends in ' + this.countdown(item.end)

							}

						} else {

							return item.name + ' ends in ' + this.countdown(item.end)

						}

					}

				}

			} else {

				return this.person.label

			}

		},

		role: function() {

			if (this.isChaperoneGroup) return false

			if (this.person.role === this.$constants.role.roleplayer) return this.$language.role.roleplayer
			if (this.person.role === this.$constants.role.examiner) return this.$language.role.examiner
			if (this.person.role === this.$constants.role.layexaminer) return this.$language.role.layexaminer
			if (this.person.role === this.$constants.role.candidate) return this.$language.role.candidate
			if (this.person.role === this.$constants.role.observer) return this.$language.role.observer

			return ''

		},

		activeChat: function() {

			return this.$_.find(this.$store.getters['chats'], function(item) {

				return this.$_.contains(item.participants, this.person.identity)

			}.bind(this))

		},

		unreadChat: function() {

			if (!this.activeChat) return false

			return this.$_.filter(this.$store.getters['messages'], function(message) {

				return message.time > this.activeChat.read && message.user !== this.$store.getters['session/identity'] && message.chat === this.activeChat.identity

			}.bind(this)).length

		},

		isParticipantActive: function() {

			return this.$_.find(this.$store.getters['overview'], function(item) {

				return this.$_.contains(item.participants, this.person.identity) && item.start < this.time && item.end > this.time && item.type !== this.$constants.schedule.type.break

			}.bind(this))

		},

		isParticipantReading: function() {

			return (this.isParticipantActive) ? this.isParticipantActive.type === this.$constants.schedule.type.station && this.isParticipantActive.roleplay > this.time : false

		},

		tipObserve: function() {

			return (this.isParticipantActive) ? 'Observe ' + this.$constants.schedule.typeName[this.isParticipantActive.type] : ''

		},

		isAlreadyVideoing: function() {

			return this.person.video

		}

	},

	methods: {

		onLocalMute: function(identity) {

			if (this.person.identity !== identity) this.is.localMute = true

		},

		onLocalExpand: function(identity) {

			if (this.person.identity !== identity) {
				
				this.is.expanded = false
				this.$emit('collapse')
			
			}

		},

		onRemoveClick: function() {

			this.is.inviting = true

			this.$api.request('room/invite', {
				participant: this.person.identity,
				room: this.isActive.identity
			}).then(function() {

				this.is.inviting = false

			}.bind(this), function() {

				this.is.inviting = false

			}.bind(this))

		},

		onVideoStartClick: function() {

			this.is.video = true

			this.$emit('expand')

			this.$api.request('observer/video', {
				identity: this.person.identity,
				start: true
			}).then(function() {

				this.$store.commit('chaperone/talking', true)

				this.$store.commit('settings/video', true)
				this.$store.commit('settings/audio', true)

				if (this.is.localMute) {

					this.is.localMute = false
					this.$pubsub.$emit('participant.local.unmute', this.person.identity)

				}

			}.bind(this))


		},

		onVideoEndClick: function() {

			if (this.is.video) {

				this.is.video = false
				this.is.expanded = false

				this.$store.commit('chaperone/talking', false)

				this.$store.commit('settings/video', false)
				this.$store.commit('settings/audio', false)

				this.$api.request('observer/video', {
					identity: this.person.identity,
					end: true
				})

				this.$emit('collapse')
				
			}

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

			if (this.is.expanded) this.$pubsub.$emit('participant.local.expand', this.person.identity)

			this.$emit((this.is.expanded) ? 'expand' : 'collapse')

		},

		onMuteClick: function() {

			this.is.localMute = !this.is.localMute

			if (!this.is.localMute) this.$pubsub.$emit('participant.local.unmute', this.person.identity)

		},

		onObserveClick: function() {

			if (this.isParticipantActive) {

				this.is.joining = true

				this.$store.commit('settings/video', false)
				this.$store.commit('settings/audio', false)

				this.$api.request('room/join', {
					room: this.isParticipantActive.identity
				}).then(function() {

					this.is.joining = false

				}.bind(this))
				
			}

		},

		onMessageClick: function() {

			if (this.activeChat) {

				this.$pubsub.$emit('panel.chats.direct', this.activeChat)

			} else {

				this.$pubsub.$emit('modal.chat', {
					identity: this.person.identity
				})

			}

		}

	}

}

</script>

<style scoped>

.participant-status {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #eee;
}

.participant-status-label {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 12px;
	color: #fff;
	letter-spacing: 1px;
	padding: 2px 4px;
	font-weight: 500;
	border-radius: 4px;
	margin-left: 4px;
	background-color: #c62323;
}

.participant.is-online .participant-status-label {
	background-color: green;
}

.participant-status-icon {
	font-size: 128px;
	color: #ddd;
}

.participant-status-icon.is-blue {
	color: #237DC6;
}

</style>