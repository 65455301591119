<template>

<app-modal :active="is.active">

	<template v-slot:head>
		<h1>Report incident</h1>
	</template>

	<select class="select" v-model="input.type">
		<option value="0">General report</option>
		<option value="1" v-if="canCircuit">Circuit report</option>
		<option value="2">Schedule report</option>
	</select>

	<select class="select" v-model="input.circuit" v-if="input.type === '1'">
		<option value="0">Select circuit</option>
		<option v-for="item in circuits" :key="item.identity" :value="item.identity">{{ item.name }}</option>
	</select>

	<select class="select" v-model="input.schedule" v-if="input.type === '2'">
		<option value="0">Select schedule</option>
		<option v-for="item in schedule" :key="item.identity" :value="item.identity">{{ item.name }}</option>
	</select>

	<textarea class="textarea" v-model="input.text" :disabled="is.loading" placeholder="Describe your incident in as much detail as possible..." />

	<template v-slot:buttons>
		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Submit</app-button>
		<app-button :disabled="is.loading" v-on:click="onClose">Cancel</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'incident',

			input: {
				schedule: 0,
				type: 0,
				circuit: 0,
				text: ''
			}

		}

	},

	computed: {

		canCircuit: function() {

			return this.isRoamingObserver

		},

		schedule: function() {

			var schedule = []

			this.$_.each(this.$store.getters[(this.isRoamingObserver) ? 'overview' : 'schedule'], function(item) {

				if (item.start < this.$store.getters['time'] && item.start > 0) schedule.push(item)

			}.bind(this))

			return schedule

		},

		circuits: function() {

			return this.$store.getters['circuits']

		}

	},

	methods: {

		reset: function() {

			this.input.schedule = 0
			this.input.circuit = 0
			this.input.type = 0
			this.input.text = ''

		},

		onInputChange: function(n) {

			this.is.valid = n.text.trim()

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('incident/report', {
				schedule: this.input.schedule,
				circuit: this.input.circuit,
				text: this.input.text
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
}

.textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 106px;
}

</style>
