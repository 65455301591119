<template>

<app-modal :active="is.active">

	<template v-slot:head>
		<h1 v-if="!isAdding">Send message</h1>
		<h1 v-if="isAdding">Add to chat</h1>
	</template>

	<app-directory :multiple="canMultiple" :limitCandidates="true" v-if="!is.composing" :people="people" :hide="hiddenPeople" v-on:change="onDirectoryChange" />

	<div class="message" v-if="is.composing">

		<div class="message-recipient"><b>To: </b>{{ recipientName }}</div>

		<textarea v-model="input.message" :disabled="is.loading" v-if="people.length" placeholder="Enter your message..." class="message-textarea"></textarea>

	</div>

	<template v-slot:buttons>
		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick" v-if="is.composing">Send</app-button>
		<app-button :loading="is.loading" :disabled="!input.user.length" v-on:click="onNextClick" v-if="!is.composing && !isAdding">Next</app-button>
		<app-button :loading="is.loading" :disabled="!input.user.length" v-on:click="onSubmitClick" v-if="!is.composing && isAdding">Add</app-button>
		<app-button v-if="!is.composing || is.direct" :disabled="is.loading" v-on:click="onClose">Cancel</app-button>
		<app-button v-if="is.composing && !is.direct" :disabled="is.loading" v-on:click="onBackClick">Back</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'chat',

			is: {
				direct: false,
				composing: false
			},

			input: {
				user: [],
				message: ''
			}

		}

	},

	computed: {

		isAdding: function() {

			return this.params.chat 

		},

		hiddenPeople: function() {

			return (this.isAdding) ? this.$_.clone(this.$_.findWhere(this.$store.getters['chats'], {
				identity: this.params.chat
			}).participants) : []

		},

		canMultiple: function() {

			return this.hasPermission(this.$constants.observer.permission.groupchat)

		},

		recipientName: function() {

			var name = []

			this.$_.each(this.input.user, function(id) {

				name.push(this.$store.getters['people'][id].name)

			}.bind(this))

			return name.join(', ')

		},

		people: function() {

			var people = []

			this.$_.each(this.$store.getters['people'], function(person) {

				if (person.identity !== this.$store.getters['session/identity']) {

					if (person.message && (!this.isAdding || person.role !== this.$constants.role.candidate)) {

						people.push(person)

					}

				}

			}.bind(this))

			return people

		}

	},

	methods: {

		reset: function() {

			this.input.user = []
			this.input.message = ''
			this.is.direct = false
			
			if (this.params.identity) {

				this.is.direct = true
				this.input.user.push(this.params.identity)
				this.is.composing = true

			} 

		},

		onNextClick: function() {

			this.is.composing = true

		},

		onInputChange: function(n) {

			this.is.valid = n.user.length && n.message.trim()

		},

		onDirectoryChange: function(users) {

			this.input.user = (this.canMultiple) ? users : [users]

		},

		onBackClick: function() {

			this.is.composing = false

		},

		onSubmitClick: function() {

			this.is.loading = true

			if (this.isAdding) {

				this.$api.request('chat/add', {
					user: this.input.user,
					chat: this.isAdding
				}).then(function() {

					this.onClose()

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			} else {

				this.$api.request('chat/start', {
					user: this.input.user,
					message: this.input.message
				}).then(function(data) {

					this.onClose()
					this.$pubsub.$emit('panel.chats.chat', data.chat)
					this.is.composing = false

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 10px;
	width: 100%;
}

.message-recipient {
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 20px;
}

.message-textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 10px;
	text-align: left;
	background-color: #fff;
	width: 100%;
	height: 216px;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
