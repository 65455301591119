<template>
    
<div class="users" :class="{'is-active': is.expanded}">

	<div class="users-buttons">

		<div class="users-buttons-item" v-if="is.expanded" v-on:click="onExpandClick" v-tooltip.left="'Close attendee panel'"><i class="fa fa-remove"></i></div>

	</div>

	<div class="users-content">

		<div class="users-head">

			<div class="users-head-title">Attendees</div>

		</div>

		<app-scroll class="users-body">

			<div class="users-section">

				Hand raised <small v-if="highlighted.length">{{ highlighted.length }}</small>
			
			</div>

			<div class="users-item" v-for="login in highlighted" :key="login.identity + '-rh'" :class="{'is-offline': !isOnline(login.identity)}">

				<div class="users-item-text">
					<div class="users-item-text-name">{{ login.name }}</div>
					<div class="users-item-text-role">{{ $store.getters['language/role'](login.role) }}</div>
				</div>

				<div class="users-item-action" v-if="isOnline(login.identity) && (!is.inviting || is.inviting === login.identity)" :class="{'is-loading': is.inviting}" v-on:click="onInviteClick(login.identity)">

					<i class="fa fa-user-plus" v-tooltip="'Add to video chat'" v-if="!isParticipant(login.identity) && canAdd"></i>
					<i class="fa fa-user-minus" v-tooltip="'Remove from video chat'" v-if="isParticipant(login.identity)"></i>

				</div>

			</div>

			<div class="users-section">

				Online <small v-if="online.length">{{ online.length }}</small>
			
			</div>

			<div class="users-item" v-for="login in online" :key="login.identity" :class="{'is-offline': !isOnline(login.identity)}">

				<div class="users-item-text">
					<div class="users-item-text-name">{{ login.name }}</div>
					<div class="users-item-text-role">{{ $store.getters['language/role'](login.role) }}</div>
				</div>

				<div class="users-item-action" v-if="isOnline(login.identity) && (!is.inviting || is.inviting === login.identity)" :class="{'is-loading': is.inviting}" v-on:click="onInviteClick(login.identity)">

					<i class="fa fa-user-plus" v-tooltip="'Add to video chat'" v-if="!isParticipant(login.identity) && canAdd"></i>
					<i class="fa fa-user-minus" v-tooltip="'Remove from video chat'" v-if="isParticipant(login.identity)"></i>

				</div>

			</div>

			<div class="users-section">

				Offline <small v-if="offline.length">{{ offline.length }}</small>
			
			</div>

			<div class="users-item" v-for="login in offline" :key="login.identity" :class="{'is-offline': !isOnline(login.identity)}">

				<div class="users-item-text">
					<div class="users-item-text-name">{{ login.name }}</div>
					<div class="users-item-text-role">{{ $store.getters['language/role'](login.role) }}</div>
				</div>

			</div>

		</app-scroll>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				expanded: false,
				inviting: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('users.toggle', this.onExpandClick.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('users.toggle', this.onExpandClick.bind(this))

	},

	computed: {

		canAdd: function() {

			return this.isActive.participants.length < 4

		},

		attendees: function() {

			var attendees = []

			this.$_.each(this.isActive.participants, function(identity) {

				if (!this.$_.contains(this.isActive.leads, identity)) attendees.push(this.$store.getters['people'][identity])

			}.bind(this))

			this.$_.each(this.isActive.observers, function(identity) {

				if (!this.$_.contains(this.isActive.leads, identity)) attendees.push(this.$store.getters['people'][identity])

			}.bind(this))

			return attendees

		},

		highlighted: function() {

			var highlighted = []

			this.$_.each(this.attendees, function(person) {

				if (this.$_.contains(this.isActive.highlighted, person.identity)) highlighted.push(person)

			}.bind(this))

			return highlighted

		},

		online: function() {

			var online = []

			this.$_.each(this.attendees, function(person) {

				if (this.$_.contains(this.$store.getters['online'], person.identity)) online.push(person)

			}.bind(this))

			return online

		},

		offline: function() {

			var offline = []

			this.$_.each(this.attendees, function(person) {

				if(!this.$_.contains(this.$store.getters['online'], person.identity)) offline.push(person)

			}.bind(this))

			return offline

		}

	},

	methods: {

		isOnline: function(identity) {

			return this.$_.contains(this.$store.getters['online'], identity)

		},

		isHighlighted: function(identity) {

			return this.$_.contains(this.isActive.highlighted, identity)

		},

		isParticipant: function(identity) {

			return this.$_.contains(this.isActive.participants, identity)

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onInviteClick: function(identity) {

			this.is.inviting = identity

			this.$api.request('room/invite', {
				participant: identity,
				room: this.isActive.identity
			}).then(function() {

				this.is.inviting = false

			}.bind(this), function() {

				this.is.inviting = false

			}.bind(this))

		}

	}
	
}

</script>

<style scoped>

.users {
	position: fixed;
	right: -256px;
	user-select: none;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
    background-color: #3b8ed2;
}

.users.is-active {
    right: 0px;
}

.users-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.users-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #3b8ed2;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.users:not(.is-active) .users-buttons-item {
    background-color: #237DC6;
}

.users-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.users:not(.is-active) .users-buttons-item:not(.is-active):hover {
    background-color: #3b8ed2;
}

.users-buttons-item.is-saved {
    background-color: #38b56a;
}

.users-content {
	width: 100%;
	height: 100%;
}

.users-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.users-head-title {
    flex-grow: 1;
}

.users-body {
    flex-grow: 1;
    flex-basis: 0;
	height: calc(100% - 44px);
}

.users-section {
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 14px;
	font-weight: 500;
	color: #fff;
}

.users-section small {
	position: absolute;
	right: 10px;
	top: 7px;
	line-height: 20px;
	display: block;
	color: #fff;
	min-width: 20px;
	text-align: center;
	font-weight: 400;
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.3);
}

.users-item {
	border-bottom: 1px solid rgb(0, 0, 0, 0.1);
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none;
}

.users-item.is-offline {
	pointer-events: none;
	opacity: 0.4;
}

.users-item.is-inactive {
	pointer-events: none;
}

.users-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: transparent;
}

.users-item:last-child {
	border-bottom-width: 0px;
}

.users-item-text {
	color: #fff;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
}

.users-item-text-name {
	font-size: 14px;
	font-weight: 400;
}

.users-item-text-role {
	font-size: 12px;
	font-weight: 300;
	opacity: 0.75;
}

.users-item-status {
	color: #fff;
	border-radius: 4px; 
	padding: 2px 4px;
	font-weight: 400;
	font-size: 12px;
}

.users-item-status.is-online {
	background-color: #27a258;
}

.users-item-status.is-raised {
	background-color: #56aef5;
}

.users-item-status.is-offline {
	background-color: #cc3939;
}

.users-item-action {
	color: #fff;
	font-size: 20px;
	width: 32px;
	height: 25px;
	margin-left: 16px;
	cursor: pointer;
	line-height: 25px;
	text-align: center;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.users-item-action.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAABtknv///zt5q0R/ryxwpfj6+////9Ti7ImvzKjD2VeNt9/p8bvR4oKqyaTB2FSKttzm7/r7/LjO4DBypzx7rCNqocvc6Dl4qiVroluPuW+cwUmDsSltpAAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.users-item-action.is-loading > * {
	opacity: 0;
}

</style>