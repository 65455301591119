<template>

<app-modal :active="is.active">

	<template v-slot:head>

		<h1 v-if="!isPaused">Pause event</h1>
		<h1 v-if="isPaused">Unpause event</h1>

	</template>

	<template v-if="!isPaused">

		<p>You can pause the entire event at its current position with any active countdowns and video chats frozen. You can then later unpause the event to resume exactly where it left off.</p>

		<p><b>Optional message</b></p>

		<p>You can provide a custom message that is shown to users to explain the reason for the pause. You can also add further messages later to keep them updated.</p>

		<textarea v-model="input.message" :disabled="is.loading" placeholder="Enter a message..." class="textarea"></textarea>

	</template>

	<template v-if="isPaused">

		<p>By unpausing the event a 30 second countdown will begin to resume the schedule.</p>

		<p><b>Optional message</b></p>

		<p>You can customise the message shown to users now the resumption countdown has begun.</p>

		<textarea v-model="input.message" :disabled="is.loading" placeholder="Enter a message..." class="textarea"></textarea>

	</template>
	
	<template v-slot:buttons>

		<app-button :loading="is.loading" v-on:click="onPauseClick" v-if="!isPaused">Pause</app-button>

		<app-button :loading="is.loading" v-on:click="onUnpauseClick" v-if="isPaused">Unpause</app-button>

		<app-button :disabled="is.loading" theme="white" v-on:click="onClose">Cancel</app-button>

	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'pause',

			input: {
				message: ''
			}

		}

	},

	computed: {

	},

	methods: {

		reset: function() {

			this.is.loading = false 
			this.input.message = ''

		},

		onInputChange: function() {},

		onPauseClick: function() {

			this.is.loading = true

			this.$api.request('pause/start', {
				message: this.input.message
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		},

		onUnpauseClick: function() {

			this.is.loading = true

			this.$api.request('pause/end').then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 66px;
	resize: none;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

p b {
	font-weight: 500;
}

</style>
