<template>

<div id="app" v-on:contextmenu.prevent="$ignoreContext">

	<com-login v-show="!isOnboarding" v-if="!isLoggedin" />
	<com-event v-show="!isOnboarding" v-if="isLoggedin && $isEventMode" />
	<com-assistant v-show="!isOnboarding" v-if="isLoggedin && $isAssistantMode" />
	<com-onboarding v-if="isOnboarding && !isActive" :script="script" />

</div>

</template>

<script>

import comLogin from './components/Login'
import comEvent from './components/Event'
import comAssistant from './components/Assistant'
import comOnboarding from './components/Onboarding'

export default {

	components: {
		'com-login': comLogin,
		'com-event': comEvent,
		'com-assistant': comAssistant,
		'com-onboarding': comOnboarding
	},

	created: function() {

		this.$pubsub.$emit('window.initiated')

	},

	computed: {

		script: function() {

			var role = 'candidate'
			
			if (this.isExaminer) role = 'examiner'

			return this.$store.getters['onboarding/script'][role]

		},

		isOnboarding: function() {

			return this.$store.getters['onboarding/active']

		}

	}

}

</script>

<style>

body {
	overflow: hidden;
	background-color: #fff;
	min-height: 100%;
}

#app {
	position: fixed;
	left: 0px;
	top: 0px;
	background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
	width: 100%;
	height: 100%;
}

</style>
