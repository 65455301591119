<template>

<app-modal :active="is.active">

	<template v-slot:head>
		<h1>Logout</h1>
	</template>

	<p v-if="!params.message">Are you sure you wish to log out?</p>
	<p v-if="params.message">{{ params.message }}</p>

	<template v-slot:buttons>
		<app-button :loading="is.loading" v-on:click="onSubmitClick" v-if="params.message">Ok</app-button>
		<app-button :loading="is.loading" v-on:click="onSubmitClick" v-if="!params.message">Yes</app-button>
		<app-button :disabled="is.loading" v-on:click="onClose" v-if="!params.message">No</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			params: {
				message: false
			},

			name: 'logout'

		}

	},

	methods: {

		reset: function() {},

		onSubmitClick: function() {

			this.is.loading = true

			this.$store.dispatch('cookie', {
				event: this.$store.getters['event/identity'],
				key: undefined
			})

			window.location.reload()

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
}

.textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 106px;
}

</style>
