export default {

	computed: {

		$isEventFinished: function() {

			return (this.$store.getters['schedule/finished'] && !this.isRoamingChaperone && !this.isRoamingObserver) || (this.isChaperone && this.$store.getters['people/self'].verified)

		},

		$isMarkingEnabled: function() {

			if (this.$store.getters['event/marking/enabled']) {

				if (this.$store.getters['event/playback/marking'].enabled === this.$constants.playback.marking.both) {

					return this.time <= this.$store.getters['event/playback/marking'].to

				} else if (this.$store.getters['event/playback/marking'].enabled === this.$constants.playback.marking.recorded) {

					return this.time >= this.$store.getters['event/playback/marking'].from && this.time <= this.$store.getters['event/playback/marking'].to

				} else {

					return true

				}

			} else {

				return false

			}

		},

		$isPlaybackEnabled: function() {

			return (this.isExaminer || this.isLayExaminer) && this.$store.getters['event/playback/marking'].enabled

		},

		$isEventMode: function() {

			return this.$store.getters['session/mode'] === this.$constants.mode.event

		},

		$isAssistantMode: function() {

			return this.$store.getters['session/mode'] === this.$constants.mode.assistant

		},

		$colour: function() {

			return this.$store.getters['circuits/associated'][this.$store.getters['circuits/current']].colour

		},
		
		$language: function() {

			return this.$store.getters['language']

		},

		isLiveEnding: function() {

			if (this.isStation) {

				if ( this.time <= this.isActive.roleplay) {

					return this.countdown(this.isActive.roleplay) < 60

				} else {

					return this.countdown(this.isActive.end) < 60

				}

			} else if (this.isMeeting) {

				return this.countdown(this.isActive.end) < 60

			}

			return false

		},

		liveStatus: function() {

			var name

			if (this.isActive) {

				if (this.$isAssistantMode && this.isStation) {

					name = this.$store.getters['people'][this.isActive.candidate].name

				} else {

					name = this.isActive.name

				}

			}

			if (this.isStation) {

				if (this.time <= this.isActive.roleplay) {

					return '<b>' + name + ' reading time</b> ends in ' + this.countdown(this.isActive.roleplay)

				} else {

					return '<b>' + name + ' roleplay</b> ends in ' + this.countdown(this.isActive.end)

				}

			} else if (this.isMeeting) {

				return '<b>' + name + '</b> ends in ' + this.countdown(this.isActive.end)

			} else if (this.isBreak) {

				return '<b>' + name + '</b> ends in ' + this.countdown(this.isActive.end)

			} else if (this.$store.getters['schedule/finished']) {

				return (this.isRoamingObserver) ? '' : '<b>Exam has finished</b>'

			} else {

				var left = this.countdown(this.$store.getters['schedule/next'].start)

				if (left === 'a moment') {

					return '<b>' + this.$store.getters['schedule/next'].name + '</b> will begin once unlocked by an observer</b>'

				} else {

					return '<b>' + this.$store.getters['schedule/next'].name + '</b> begins in ' + left

				}

			}

		},

		isAccepted: function() {

			return this.$store.getters['session/accepted']

		},

		isLoggedin: function() {

			return this.$store.getters['session/loggedin']

		},

		isInitiated: function() {

			return this.$store.getters['device/initiated']

		},

		isExam: function() {

			return this.$store.getters['event/type'] === this.$constants.event.type.exam

		},

		isEarly: function() {

			return this.time < this.$store.getters['schedule/start'] - this.$constants.schedule.early

		},

		isDeliveryOnline: function() {

			return this.$store.getters['event/delivery'] === this.$constants.delivery.online

		},

		isPaused: function() {

			return this.$store.getters['event/paused']

		},

		isLead: function() {

			return (this.isActive) ? this.$_.contains(this.isActive.leads, this.$store.getters['session/identity']) || this.isRoamingObserver : false

		},

		isActive: function() {

			return this.$store.getters['schedule/active']

		},

		$isActivePlayback: function() {

			return (this.isActive) ? this.isActive.playback : false

		},

		$isActiveReady: function() {

			return (this.$isActivePlayback) ? this.isActive.playback.ready : true

		},

		$isActivePlaying: function() {

			return (this.$isActivePlayback) ? this.isActive.playback.playing : true

		},

		isStation: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.station : false

		},
		
		isChaperoneGroup: function() { 

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.chaperone : false

		},

		isAudioStation: function() {

			return (this.isStation) ? !this.isActive.permissions.video : false

		},

		isStationExaminerOnly: function() {

			return (this.isStation) ? this.isActive.roleplayer === false : false

		},

		$isWebinar: function() {

			return (this.isMeeting) ? this.isActive.format === this.$constants.meeting.format.webinar : false

		},

		isMeeting: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.meeting : false

		},

		$isInterval: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.interval : false

		},

		isBreak: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.interval && this.isActive.format === this.$constants.interval.format.standard : false

		},

		isObserving: function() {

			return (this.$store.getters['schedule/active']) ? this.$_.contains(this.$store.getters['schedule/active'].observers, this.$store.getters['session/identity']) : false

		},

		isParticipating: function() {

			return (this.$store.getters['schedule/active']) ? this.$_.contains(this.$store.getters['schedule/active'].participants, this.$store.getters['session/identity']) : false

		},
		
		isCandidate: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.candidate

		},

		isObserver: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.observer

		},

		isChaperone: function() {

			return this.$store.getters['people/self'].chaperone

		},

		isRoamingChaperone: function() {
			
			return this.isChaperone && this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.group

		},

		isTalking: function() {

			return this.$store.getters['chaperone/talking']

		},

		isPreparation: function() {

			return (this.isStation) ? this.time < this.isActive.roleplay : false

		},

		$isObservingMultipleCircuits: function() {

			return this.isObserver && this.$_.contains([this.$constants.observer.scope.circuits], this.$store.getters['people/self'].scope)

		},

		isRoamingObserver: function() {

			return this.isObserver && !this.isChaperone && this.$_.contains([this.$constants.observer.scope.event, this.$constants.observer.scope.circuit, this.$constants.observer.scope.circuits], this.$store.getters['people/self'].scope)

		},

		isRoleplayer: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.roleplayer

		},

		isExaminer: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.examiner

		},

		isLayExaminer: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.layexaminer

		}

	},

	methods: {

		$ignoreContext: function() {

		},

		$allowContext: function(e) {

			e.stopPropagation()

		},

		hasPermission: function(type) {

			return this.$_.contains(this.$store.getters['people/self'].permissions, type.toString())

		}

	}

}