<template>

<div class="item" v-on:click="$emit('click')" :class="{'is-active': active}">

	<div class="item-name">{{ name }}</div>
	<div class="item-icon"><i :class="{[iconClass]: true}"></i></div>

</div>

</template>

<script>

export default {

	props: ['icon', 'name', 'active'],

	computed: {

		iconClass: function() {

			return 'fa fa-' + this.icon

		}

	}

}

</script>

<style scoped>

.item {
	color: #333;
	padding: 10px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-top: 1px solid #ddd;
	height: 40px;
}

.item.is-active {
	background-color: #237DC6;
	border-color: #237DC6;
	color: #fff;
}

.item:first-child {
	border-radius: 4px 4px 0px 0px;
	border-top: 0px;
}

.item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.item:not(.is-active):hover {
	background-color: #eee;	
}

.item-name {
	font-size: 14px;
	font-weight: 300;
	line-height: 40px;
	cursor: pointer;
}

.item-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -8px;
	width: 24px;
	text-align: center;
	font-size: 16px;
	color: #237DC6;
}

.item.is-active .item-icon {
	color: #fff;
}

</style>
