<template>

<div class="station" :class="{'is-marking': isMarking}" :style="{paddingRight: markingWidth}">

	<div class="station-warning is-active" v-if="!$isActivePlaying && $isActiveReady">Paused</div>

	<template v-if="$isActivePlaying">

		<div class="station-warning" :style="{right: markingWidth}" v-if="isPrepareEnding" :class="{'is-active': isPrepareEnding}">{{ countdown(prepareEnd) }}</div>
		<div class="station-warning" :style="{right: markingWidth}" v-if="isRoleplayStarting" :class="{'is-active': isRoleplayStarting}">Roleplay</div>
		<div class="station-warning" :style="{right: markingWidth}" v-if="isRoleplayWarning" :class="{'is-active': isWarningActive}">{{ countdown(roleplayEnd) }}</div>
		<div class="station-warning" :style="{right: markingWidth}" v-if="isRoleplayEnding" :class="{'is-active': isRoleplayEnding}">{{ countdown(roleplayEnd) }}</div>
		<div class="station-warning" :style="{right: markingWidth}" v-if="isFeedbackStarting" :class="{'is-active': isFeedbackStarting}">Feedback</div>
		<div class="station-warning" :style="{right: markingWidth}" v-if="isFeedbackEnding" :class="{'is-active': isFeedbackEnding}">{{ countdown(feedbackEnd) }}</div>

	</template>

	<template v-if="!isPreparation">

		<app-room-status v-if="is.preloading && $isActivePlayback" :type="$constants.status.loading" text="Connecting, please wait..." />

		<app-room v-show="!is.preloading || !$isActivePlayback" v-on:canplay="onCanPlay" :item="schedule" :cards="cards" :pinsHidden="true" :pinsSpecial="true" :pinsEditable="false" />

	</template>

	<template v-if="isPreparation">

		<div v-if="!isRoleplayer" class="station-preparation" :class="{'is-split': isObserver || isExaminer || isLayExaminer, 'is-candidate': isCandidate, 'has-notepad': notepad}">

			<app-shredded class="station-preparation-text" :data="candidateBrief" :schedule="schedule.identity" :showNote="true" />

			<div class="station-preparation-label" v-if="isObserver || isExaminer || isLayExaminer">{{ $language.role.candidate }} brief</div>

		</div>

		<div v-if="!isCandidate && !isStationExaminerOnly" class="station-preparation" :class="{'is-split': isObserver || isExaminer || isLayExaminer}">

			<app-shredded class="station-preparation-text" :data="{type: 'station', brief: 'roleplayer', identity: schedule.station}" :schedule="schedule.identity" :showNote="true" />

			<div class="station-preparation-label" v-if="isObserver || isExaminer || isLayExaminer">{{ $language.role.roleplayer }} brief</div>

		</div>

		<div v-if="!isCandidate && isStationExaminerOnly" class="station-preparation" :class="{'is-split': isObserver || isExaminer || isLayExaminer}">

			<app-shredded class="station-preparation-text" :data="{type: 'station', brief: 'examiner', identity: schedule.station}" :schedule="schedule.identity" :showNote="true" />

			<div class="station-preparation-label" v-if="isObserver || isExaminer || isLayExaminer">{{ $language.role.examiner }} brief</div>

		</div>

        <app-room-notepad v-if="notepad" :item="{title: '', subtitle: ''}" class="station-notes" />

	</template>
	
</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				preloading: true,
				skipping: false
			},
			brief: {
				candidate: false,
				roleplayer: false,
				examiner: false
            },
            notepad: false
		}

	},

	created: function() {

        this.$pubsub.$on('action.brief', this.onReadBrief.bind(this))
        this.$pubsub.$on('action.notepad', this.onToggleNotepad.bind(this))

		/*if (this.isRoamingChaperone && this.isPreparation) {

			this.$store.commit('chaperone/chaperone', this.active.candidate)
			
			this.$chaperone.connect()

		}*/

        this.notepad = this.isCandidate && this.$store.getters['event/notes/enabled']

	},

	beforeDestroy: function() {

		this.$pubsub.$off('action.brief', this.onReadBrief.bind(this))
        this.$pubsub.$off('action.notepad', this.onToggleNotepad.bind(this))

		/*
		if (this.isRoamingChaperone) {

			this.$store.commit('chaperone/chaperone', false)
			
			this.$chaperone.disconnect()

		}
		*/

	},

	computed: {

		candidateBrief: function() {

			return (this.isCandidate) ? {type: 'brief', brief: 'candidate', identity: this.schedule.identity} : {type: 'station', brief: 'candidate', identity: this.schedule.station}

		},

		hasReadingTime: function() {

			return this.schedule.duration.preparation

		},

		markingWidth: function() {

			return (this.isMarking) ? this.$store.getters['marking/width'].toString() + 'px' : '0px'

		},

        isMarking: function() {

            return (this.isExaminer || this.isLayExaminer) && this.$store.getters['event/marking/enabled'] && !this.isPreparation && (this.schedule.candidate || this.$store.getters['event/marking/empty'])

        },

		cards: function() {

			var cards = []

			if (this.brief.candidate) {

				cards.push({
					type: this.$constants.card.type.file,
					identity: 'candidate',
					title: this.$language.role.candidate + ' brief',
					shredded: {
						type: (this.isCandidate) ? 'brief' : 'station',
						brief: 'candidate',
						identity: (this.isCandidate) ? this.schedule.identity : this.schedule.station
					}
                })

            }
            
            if (this.notepad) {
                
                cards.push({
                    type: this.$constants.card.type.notepad,
                    identity: 'notepad',
                    title: 'Your notes'
                })

            }

			if (this.brief.roleplayer) {

				cards.push({
					type: this.$constants.card.type.file,
					identity: 'roleplayer',
					title: this.$language.role.roleplayer + ' brief',
					shredded: {
						type: (this.isCandidate) ? 'brief' : 'station',
						brief: 'roleplayer',
						identity: (this.isCandidate) ? this.schedule.identity : this.schedule.station
					}
				})

			}

			if (this.brief.examiner) {

				cards.push({
					type: this.$constants.card.type.file,
					identity: 'examiner',
					title: this.$language.role.examiner + ' brief',
					shredded: {
						type: (this.isCandidate) ? 'brief' : 'station',
						brief: 'examiner',
						identity: (this.isCandidate) ? this.schedule.identity : this.schedule.station
					}
				})

			}

			if (this.brief.layexaminer) {

				cards.push({
					type: this.$constants.card.type.file,
					identity: 'layexaminer',
					title: this.$language.role.layexaminer + ' brief',
					shredded: {
						type: (this.isCandidate) ? 'brief' : 'station',
						brief: 'layexaminer',
						identity: (this.isCandidate) ? this.schedule.identity : this.schedule.station
					}
				})

			}

			this.$_.each(this.schedule.inventory, function(inventory) {

				var show = false

				if (inventory.availability.type === this.$constants.inventory.availability.always) {

					show = true

				} else if (inventory.availability.type === this.$constants.inventory.availability.time) {

					show = inventory.availability.timer <= this.roleplayElapsed

				} else if (inventory.availability.type === this.$constants.inventory.availability.trigger) {

					var isPermitted = false, revealed = false

					if (inventory.availability.permission === this.$constants.inventory.availability.permission.role) {

						isPermitted = this.$_.contains(inventory.availability.roles, this.$store.getters['people/self'].role)

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.user) {

						isPermitted = this.$_.contains(inventory.availability.logins, this.$store.getters['session/identity'])

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.lead) {

						isPermitted = this.isLead

					}

					revealed = this.$_.contains(this.$_.keys(this.schedule.triggered), inventory.id)

					if (isPermitted) {

						show = true

					} else {

						show = revealed

					}

					if (revealed) {

						if (this.schedule.triggered[inventory.id] !== this.$store.getters['session/identity']) {

							isPermitted = false

						}

					}

				}
				
				if (show) {

					cards.push({
						type: this.$constants.card.type.file,
						identity: inventory.id,
						observeIdentity: this.schedule.id + '-' + inventory.id,
						title: inventory.name,
						subtitle: 'Inventory',
						availability: inventory.availability,
						interaction: inventory.interaction,
						triggerer: this.schedule.triggered[inventory.id],
						revealed: revealed,
						image: inventory.image,
						video: inventory.video,
						shredded: (inventory.pdf) ? {
							type: 'inventory',
							identity: inventory.id
						} : false
					})
					
				}

			}.bind(this))

			return cards

		},

		isCandidate: function() {

			return this.schedule.candidate === this.$store.getters['session/identity']

		},

		isRoleplayer: function() {

			return this.schedule.roleplayer === this.$store.getters['session/identity']

		},

		schedule: function() {

			return this.$store.getters['schedule/active']

		},

		prepareEnd: function() {

			return this.schedule.roleplay

		},

		roleplayEnd: function() {

			return (this.schedule.feedback) ? this.schedule.feedback : this.schedule.end

		},

		feedbackEnd: function() {

			return this.schedule.end

		},

		isFeedback: function() {

			return this.schedule.feedback && this.time >= this.schedule.feedback && this.time <= this.schedule.end

		},

		isRoleplay: function() {

			return this.time >= this.schedule.roleplay && this.time <= this.roleplayEnd

		},

		isPreparation: function() {

			return this.time >= this.schedule.start && this.time <= this.schedule.roleplay && this.hasReadingTime

		},

		isPrepareEnding: function() {

			return this.countdown(this.prepareEnd, true) <= 5 && this.isPreparation && this.hasReadingTime

		},

		isRoleplayStarting: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return this.isRoleplay && (remaining >= (this.roleplayEnd - this.schedule.roleplay) - 3 && remaining < this.roleplayEnd - this.schedule.roleplay) && this.hasReadingTime

		},

		isRoleplayEnding: function() {

			return this.countdown(this.roleplayEnd, true) <= 5 && this.isRoleplay

		},

		isFeedbackStarting: function() {

			var remaining = this.countdown(this.feedbackEnd, true)
			return this.isFeedback && (remaining >= (this.schedule.end - this.schedule.feedback) - 3 && remaining < this.schedule.end - this.schedule.feedback)

		},

		isFeedbackEnding: function() {

			return this.countdown(this.feedbackEnd, true) <= 5 && this.isFeedback

		},

		warningPoint: function() {

			return this.$store.getters['event/stations/warning']

		},

		isRoleplayWarning: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 3 && remaining >= this.warningPoint - 3 && this.isRoleplay

		},

		isWarningActive: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 1 && remaining >= this.warningPoint - 1 && this.isRoleplay

		},

		roleplayElapsed: function() {

			return this.time - this.schedule.roleplay

		}

	},

	methods: {
		
		onCanPlay: function() {

			if (!this.schedule.playback.ready) {

				if (this.schedule.playback.position && !this.is.skipping) {

					this.is.skipping = true

					this.$pubsub.$emit('room.skip')

					this.schedule.start = this.$store.getters['time'] - this.schedule.playback.position
					this.schedule.roleplay = this.$store.getters['time'] - this.schedule.playback.position
					this.schedule.end = this.$store.getters['time'] - this.schedule.playback.position + this.schedule.duration.roleplay

					this.$pubsub.$emit('playback.skip', this.schedule.playback.position)

				} else {

					var difference = this.$store.getters['time'] - this.schedule.start - this.schedule.playback.position

					this.schedule.start += difference 
					this.schedule.roleplay += difference 
					this.schedule.end += difference 

					this.schedule.playback.ready = true

					this.is.preloading = false

				}

			}

			if (this.schedule.playback.can.autoplay && this.schedule.playback.ready) {

				this.$pubsub.$emit('playback.play')
				this.schedule.playback.playing = true
				
			}
			
		},

		onReadBrief: function(identity) {

			this.brief[identity] = !this.brief[identity]

        },
        
        onToggleNotepad: function() {

            this.notepad = !this.notepad

        }

	}

}

</script>

<style scoped>

.station {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.station-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 1;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.station-warning.is-active {
	opacity: 1;
}

.station-preparation {
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.station-preparation.is-candidate.has-notepad {
    height: calc(100% - 210px);
}

.station-preparation.is-split {
	height: calc(50% - 5px);
}

.station-preparation.is-split:last-child {
	margin-top: 10px;
}

.station-preparation-text {
	width: 100%;
	flex-grow: 1;
	border-radius: 4px;
	flex-basis: 0;
}

.station-preparation-label {
	flex-shrink: 0;
	padding: 10px;
	text-align: left;
	font-size: 16px;
	font-weight: 400;
	color: #333;
}

.station-notes {
    position: absolute;
    z-index: 2;
    left: 0px;
    bottom: 0px;
    right: 0px;
	height: 200px;
}

</style>