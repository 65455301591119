<template>

<div class="scene">

	<div class="scene-content">
		<com-waiting v-if="scene.type === 'waiting'" :scene="scene" :initTime="initTime" />
		<com-meeting v-if="scene.type === 'meeting'" :scene="scene" :initTime="initTime" />
		<com-station v-if="scene.type === 'station'" :scene="scene" :initTime="initTime" />
		<com-finished v-if="scene.type === 'finished'" :scene="scene" :initTime="initTime" />
		<com-break v-if="scene.type === 'break'" :scene="scene" :initTime="initTime" />
	</div>
	
	<com-sidebar :sidebar="scene.sidebar" :initTime="initTime" />
	<com-panel :panel="scene.panel" :initTime="initTime" />

	<com-foot />
	
</div>

</template>

<script>

import comWaiting from './scene/Waiting'
import comStation from './scene/Station'
import comMeeting from './scene/Meeting'
import comFoot from './scene/Foot'
import comSidebar from './scene/Sidebar'
import comPanel from './scene/Panel'
import comFinished from './scene/Finished'
import comBreak from './scene/Break'

export default {

	props: ['scene', 'initTime'],

	components: {
		'com-waiting': comWaiting,
		'com-station': comStation,
		'com-meeting': comMeeting,
		'com-finished': comFinished,
		'com-break': comBreak,
		'com-foot': comFoot,
		'com-sidebar': comSidebar,
		'com-panel': comPanel
	}

}

</script>

<style scoped>

.scene {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
}

.scene-content {
	position: absolute;
	left: 256px;
	top: 10px;
	right: 10px;
	bottom: 64px;
	user-select: none;
}

</style>