<template>

<div class="incidents">

	<div class="incidents-empty" v-if="!incidents.length">There have been no reported incidents.</div>

	<div class="incidents-item" v-for="incident in incidents" :key="incident.identity" :class="{'has-colour': $colour}" :style="{borderColor: $colour}">

		<div class="incidents-item-head">

			<div class="incidents-item-head-time">{{ incident.time | formatDate('HH:mm') }}</div>

			<div class="incidents-item-head-schedule">{{ schedule(incident) }}</div>

			<div class="incidents-item-head-user">{{ name(incident) }}<small>({{ role(incident) }})</small></div>

		</div>

		<div class="incidents-item-body">

			{{ incident.text }}

		</div>

	</div>

</div>

</template>

<script>

export default {

	computed: {

		incidents: function() {

			return this.$_.filter(this.$store.getters['incidents'], function(item) {
				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === 0
			}.bind(this))

		}

	},

	methods: {

		name: function(incident) {

			return this.$store.getters['people'][incident.user].name

		},

		role: function(incident) {

			return this.$store.getters['language/role'](this.$store.getters['people'][incident.user].role)

		},

		schedule: function(incident) {

			if (incident.schedule) {

				return ((this.$store.getters['overview/associated'][incident.schedule]) ? this.$store.getters['overview/associated'][incident.schedule].name : 'Unknown') + ((this.$store.getters['circuits/associated'][incident.circuit]) ? '(' + this.$store.getters['circuits/associated'][incident.circuit].name + ')' : '')

			} else if (incident.circuit) {

				return (this.$store.getters['circuits/associated'][incident.circuit]) ? this.$store.getters['circuits/associated'][incident.circuit].name : 'Unknown'

			} else {

				return 'General report'

			}

		}

	}

}

</script>

<style scoped>

.incidents {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	justify-content: flex-start;
}

.incidents-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 24px;
	color: #ddd;
	font-weight: 500;
	text-align: center;
	transform: translate(-50%, -50%);
}

.incidents-item {
	margin: 0px 10px 10px 0px;
	width: 100%;
}

.incidents-item.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.incidents-item > *:last-child {
	margin-bottom: 0px;
}

.incidents-item-head {
	background-color: #237DC6;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px 4px 0px 0px;
	display: flex;
	flex-direction: row;
	font-weight: 400;
}

.incidents-item.has-colour .incidents-item-head {
	border-radius: 0px 4px 0px 0px;
}

.incidents-item-head-time {
	width: 120px;
}

.incidents-item-head-user {
	width: 120px;
}

.incidents-item-head-schedule {
	width: 200px;
}

.incidents-item-head-user small {
	font-size: 12px;
	margin-left: 10px;
}

.incidents-item-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
	border-width: 0px 1px 1px 1px;
	overflow: hidden;
	padding: 10px;
	font-size: 16px;
	line-height: 22px;
	font-weight: 300;
	background-color: #fff;
}

.incidents-item.has-colour .incidents-item-body {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

</style>