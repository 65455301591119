<template>

<div class="current" :class="{'is-station': isStation}">
	
	<div class="current-item current-item-head">

		<div class="current-item-name">{{ current.name }}</div>

	</div>

	<div class="current-item-wrapper" :class="{'is-webinar': $isWebinar}">
	
		<div class="current-item is-active" v-tooltip="(isLead) ? 'View attendees' : false" v-if="$isWebinar" :class="{'is-clickable': isLead}" v-on:click="onUsersClick">

			<div class="current-item-name">Watching</div>
			<div class="current-item-counter" :class="{'is-disabled': !watching}">{{ watching }}</div>

		</div>
		
		<div class="current-item is-active" v-tooltip="(isLead) ? 'View hands raised' : false" v-if="$isWebinar" :class="{'is-clickable': isLead}" v-on:click="onUsersClick">

			<div class="current-item-name">Hands raised</div>
			<div class="current-item-counter is-blue" :class="{'is-disabled': !highlighted}">{{ highlighted }}</div>

		</div>
		
		<div class="current-item is-active" v-if="!isStation">

			<div class="current-item-name">In progress</div>
			<div class="current-item-countdown">{{ countdown(current.end - pauseOffset) }}</div>

		</div>
		
		<div class="current-item" :class="{'is-active': isStationPreparation, 'is-complete': isStationRoleplay}" v-if="isStation && hasReadingTime">

			<div class="current-item-name">Reading time</div>
			<div class="current-item-status">{{ durationPreparation }}</div>
			<div class="current-item-countdown" v-if="isStationPreparation">{{ countdownValue }}</div>
			<div class="current-item-check fa fa-check-circle" v-if="isStationRoleplay || isStationFeedback"></div>

		</div>
		
		<div class="current-item" :class="{'is-active': isStationRoleplay}" v-if="isStation">

			<div class="current-item-name">Roleplay</div>
			<div class="current-item-status">{{ durationRoleplay }}</div>
			<div class="current-item-countdown" v-if="isStationRoleplay">{{ countdownValue }}</div>
			<div class="current-item-check fa fa-check-circle" v-if="isStationFeedback"></div>

		</div>
		
		<div class="current-item" :class="{'is-active': isStationFeedback}" v-if="isStation && hasFeedback">

			<div class="current-item-name">Feedback</div>
			<div class="current-item-status">{{ durationFeedback }}</div>
			<div class="current-item-countdown" v-if="isStationFeedback">{{ countdownValue }}</div>

		</div>

	</div>

	<div class="current-item is-extension" v-if="isActive.extension">

		<div>The running time has been extended by {{ isActive.extension }} minute<template v-if="isActive.extension > 1">s</template>.</div>
		<div><i class="fa fa-clock" /></div>

	</div>

	<div class="current-item is-slim" v-if="!$isActivePlayback">

		<com-online />

	</div>

</div>

</template>

<script>

import comOnline from './Online'

export default {

	components: {
		'com-online': comOnline
	},

	data: function() {

		return {
			lastCountdownValue: ''
		}

	},

	watch: {

		countdownValue: function(n) {

			this.lastCountdownValue = n

		}

	},

	computed: {

		countdownValue: function() {

			if (this.$isActivePlaying) {

				return this.countdown(this.deadline - this.pauseOffset)

			} else {

				return this.lastCountdownValue

			}

		},
		
		watching: function() {

			return this.$_.intersection(this.isActive.observers, this.$store.getters['online']).length

		},

		highlighted: function() {

			return this.isActive.highlighted.length

		},

		pauseOffset: function() {

			return (this.isPaused) ? this.$store.getters['event/paused/duration'] : 0

		},

		current: function() {

			return this.$store.getters['schedule/active']

		},

		hasReadingTime: function() {

			return this.current.duration.preparation

		},

		deadline: function() {

			if (this.isStationPreparation) return this.current.roleplay
			if (this.isStationRoleplay && !this.hasFeedback) return this.current.end
			if (this.isStationRoleplay && this.hasFeedback) return this.current.feedback

			return this.current.end

		},

		durationPreparation: function() {

			var minutes = this.current.duration.preparation / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		durationRoleplay: function() {

			var minutes = this.current.duration.roleplay / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		durationFeedback: function() {

			var minutes = this.current.duration.feedback / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		hasFeedback: function() {

			return this.current.feedback !== false

		},

		isStationPreparation: function() {

			return this.time < this.current.roleplay

		},

		isStationRoleplay: function() {

			return this.time >= this.current.roleplay && (!this.hasFeedback || this.time < this.current.feedback)

		},

		isStationFeedback: function() {

			return this.hasFeedback && this.time >= this.current.feedback

		},

		isStation: function() {

			return this.current.type === this.$constants.schedule.type.station

		}

	},

	methods: {

		onUsersClick: function() {

			if (this.isLead) {

				this.$pubsub.$emit('users.toggle')

			}

		}

	}

}

</script>

<style scoped>

.current {
	user-select: none;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 4px;
	flex-shrink: 0;
	background-color: #fff;
	margin-top: 10px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.current-item {
	padding: 10px;
	height: 56px;
	display: flex;
	color: #333;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #ddd;
	border-width: 0px 1px 1px 1px;
	pointer-events: none;
}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar .current-item {
		height: auto;
		text-align: center;
	}

}

.current-item.is-clickable {
	cursor: pointer;
	pointer-events: initial;
}

.current-item.is-clickable:hover {
	background-color: #eee;
}

.current-item.is-slim {
	height: auto;
}

.current-item.is-extension {
	font-size: 12px;
    font-weight: 300;
	line-height: 14px;
	height: auto;
	background-color: #237DC6;
	border-color: #237DC6;
	color: #fff;
	flex-direction: row;
	display: flex;
	vertical-align: center;
}

.current-item.is-extension .fa {
	font-size: 26px;
	margin-left: 10px;
}

.current-item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.current-item-head {
	border-top: 0px;
	background-color: #237DC6;
	color: #fff;
	border-color: #237DC6;
	height: 40px;
}

.current-item.is-complete {
	color: #999;
}

.current-item-name {
	font-size: 16px;
	font-weight: 300;
}

@media (max-height: 799px) {

	.current-item-name {
		height: 32px;
		margin-bottom: 10px;
	}

}

.current-item-head .current-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

.current-item-status {
	font-size: 12px;
	font-weight: 300;
	margin-top: 4px;
}

.current-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #237DC6;
}

.current-item-counter {
	font-size: 16px;
	line-height: 32px;
	font-weight: 500;
	border-radius: 4px;
	min-width: 32px;
	text-align: center;
	position: absolute;
	color: #333;
	right: 10px;
	top: 12px;
}

.current-item-counter.is-blue {
	background-color: #237DC6;
	color: #fff;
}

.current-item-counter.is-disabled {
	color: #ccc;
}

.current-item-countdown {
	font-size: 16px;
	line-height: 36px;
	font-weight: 500;
	color: #333;
	position: absolute;
	right: 10px;
	top: 10px;
}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar .current-item-counter,
	.current-item-wrapper.is-webinar .current-item-countdown {
		position: relative;
		top: auto;
		right: auto;
	}

}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar {
		display: flex;
		flex-direction: row;
	}

}

</style>
