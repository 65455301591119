<template>

<div class="playback">

	<div class="playback-timeline" :class="{'is-disabled': !isReady, 'no-skip': !canSkip}">
		
		<div ref="timeline" class="playback-timeline-bar" v-on:mousemove="onTimelineMove" v-on:mouseenter="isOver= true" v-on:mouseleave="isOver = false">

			<div class="playback-timeline-bar-tooltip" v-tooltip="tooltip" :style="{left: tooltipLeft}" v-if="isOver" />

			<div class="playback-timeline-bar-item is-progress" :class="{'is-disabled': !canSkipBackward}" :style="{width: progressWidth}" v-on:click="onTimelineClick"></div>
			<div class="playback-timeline-bar-item is-viewed" :class="{'is-disabled': !canSkipBackward}" :style="{width: viewedWidth}" v-on:click="onTimelineClick"></div>
			<div class="playback-timeline-bar-item is-full" :class="{'is-disabled': !canSkipForward}" v-on:click="onTimelineClick"></div>

		</div>

	</div>

	<app-actions-icons class="playback-actions">

		<app-actions-icon icon="play" name="Start playback" :disabled="!canPlay" v-on:click="onPlayClick" />
		<app-actions-icon icon="pause" name="Pause playback" :disabled="!canPause" v-on:click="onPauseClick" />
		<app-actions-icon icon="stop" name="End playback" :disabled="!canStop" v-on:click="onStopClick" />

	</app-actions-icons>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			lastProgress: false,
			furthestViewed: 0,
			trackTimer: false,
			tooltipLeft: '0%',
			isOver: false
		}

	},

	watch: {

		progressWidth: function(n) {

			this.lastProgress = n

		},

		position: function(n) {

			if (n > this.furthestViewed) {
				
				this.furthestViewed = n

			}

			if (n == this.isActive.duration.roleplay) this.trackPosition()

		}

	},

	computed: {

		tooltip: function() {

			var seconds = parseInt((this.isActive.duration.roleplay / 100) * parseFloat(this.tooltipLeft))

			var minutes = Math.floor(seconds / 60)
			seconds = seconds % 60

			return {
				content: minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0'),
				show: true,
				autohide: false
			}

		},

		isPlaying: function() {

			return this.isActive.playback.playing

		},

		isReady: function() {

			return this.isActive.playback.ready

		},

		progressWidth: function() {

			if (this.isActive.playback.ready) {

				if (this.isActive.playback.playing) {

					return ((100 / this.isActive.duration.roleplay) * (this.$store.getters['time'] - this.isActive.start)).toString() + '%'

				} else {

					return this.lastProgress

				}

			} else {

				return '0%'

			}

		},

		viewedWidth: function() {

			if (this.isActive.playback.ready) {

				return ((100 / this.isActive.duration.roleplay) * this.furthestViewed).toString() + '%'

			} else {

				return '0%'

			}

		},

		hoverWidth: function() {

			return false

		},

		canPlay: function() {

			return !this.isActive.playback.playing && this.isActive.playback.ready

		},

		canPause: function() {

			return this.isActive.playback.playing && this.isActive.playback.can.pause

		},

		canSkipBackward: function() {

			return this.isActive.playback.ready && this.isActive.playback.can.skip.backward

		},

		canSkipForward: function() {

			return this.isActive.playback.ready && this.isActive.playback.can.skip.forward

		},

		canSkip: function() {

			return this.isActive.playback.ready && (this.isActive.playback.can.skip.backward || this.isActive.playback.can.skip.forward)

		},

		canStop: function() {

			return this.isActive.playback.ready

		},

		position: function() {

			return (this.isActive.playback.playing) ? this.$store.getters['time'] - this.isActive.start : this.furthestViewed

		}

	},

	beforeDestroy: function() {

		clearTimeout(this.trackTimer)

	},

	methods: {

		onTimelineMove: function(e) {

			var percent = (100 / this.$refs.timeline.getBoundingClientRect().width) * (e.x - this.$refs.timeline.getBoundingClientRect().left)

			this.tooltipLeft = percent.toString() + '%'

		},

		trackPosition: function() {

			clearTimeout(this.trackTimer)

			this.$api.request('playback/track', {
				identity: this.isActive.identity,
				position: this.position
			}).then(function(json) {

				this.$store.commit('playback/set', json.playback)

			}.bind(this))

			this.trackTimer = this.$_.delay(this.trackPosition, 10000)

		},

		onTimelineClick: function(e) {

			var percent = (100 / this.$refs.timeline.getBoundingClientRect().width) * (e.x - this.$refs.timeline.getBoundingClientRect().left)

			var position = parseInt((this.isActive.duration.roleplay / 100) * percent)

			if (
				(position < this.furthestViewed && this.isActive.playback.can.skip.backward)
				||
				(position > this.furthestViewed && this.isActive.playback.can.skip.forward)
			) {

				this.isActive.playback.playing = false

				this.$pubsub.$emit('playback.pause')
				this.$pubsub.$emit('room.skip')

				this.isActive.start = this.$store.getters['time'] - position
				this.isActive.roleplay = this.$store.getters['time'] - position
				this.isActive.end = this.$store.getters['time'] - position + this.isActive.duration.roleplay

				this.$pubsub.$emit('playback.skip', position)

				this.trackPosition()

			}

		},

		onPlayClick: function() {

			this.$pubsub.$emit('playback.play')

			var difference = this.$store.getters['time'] - (this.isActive.start + this.isActive.playback.position)

			this.isActive.start += difference
			this.isActive.roleplay += difference
			this.isActive.end += difference

			this.isActive.playback.playing = true	

		},

		onPauseClick: function() {

			this.$pubsub.$emit('playback.pause')

			this.isActive.playback.position = this.position
			this.isActive.playback.playing = false

			this.trackPosition()

		},

		onStopClick: function() {

			this.trackPosition()

			this.$store.commit('schedule/remove', this.isActive)

		}

	}

}

</script>

<style scoped>

.playback {
	margin-top: 10px;
}

.playback-timeline {
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom-width: 0px;
	padding: 10px;
}

.playback-timeline-bar {
	width: 100%;
	height: 10px;
}

.playback-timeline.no-skip {
	pointer-events: none;
}

.playback-timeline.is-disabled {
	pointer-events: none;
	opacity: 0.35;
}

.playback-timeline-bar-tooltip {
	width: 1px;
}

.playback-timeline-bar-item {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 10px;
	border-radius: 4px;
	cursor: pointer;
}

.playback-timeline-bar-item.is-disabled {
	pointer-events: none;
}

.playback-timeline-bar-item.is-progress {
	z-index: 3;
	background-color: #237DC6;
}

.playback-timeline-bar-item.is-viewed {
	z-index: 2;
	background-color: #999;
}

.playback-timeline-bar-item.is-full {
	z-index: 1;
	width: 100%;
	background-color: #ddd;
}

.playback-actions {
	margin-top: 0px!important;
}

.playback-actions >>> .icon {
	border-radius: 0px;
	margin-right: 0px;
	border-right-width: 0px;
	width: 33.3%;
	flex-shrink: 0;
	line-height: 44px!important;
}

.playback-actions >>> .icon:nth-child(1) {
	border-bottom-left-radius: 4px;
}

.playback-actions >>> .icon:nth-child(3) {
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

</style>
