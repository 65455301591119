<template>

<app-page class="page" :class="{'is-menu-open': is.menuOpen}">

	<template v-if="$isActivePlaying && isRoleplaying && (isExaminer || isLayExaminer)">

		<div class="station-warning" v-if="isRoleplayWarning" :class="{'is-active': isWarningActive}">{{ countdown(roleplayEnd, false, false, true) }}</div>
		<div class="station-warning" v-if="isRoleplayEnding" :class="{'is-active': isRoleplayEnding}">{{ countdown(roleplayEnd, false, false, true) }}</div>

	</template>

	<com-welcome v-if="!isAccepted" />

	<template v-if="isAccepted">
		
		<com-menu :section="currentSection" v-on:logoutClick="onLogoutClick" v-on:sectionChange="onSectionClick" />

		<com-head :locked="isRoleplaying" :marking="is.markingSheet" :section="currentSection" v-on:menuToggle="onMenuToggle" />

		<com-content v-on:click.native="is.menuOpen = false" v-if="!$_.contains([$constants.assistant.content.inventories, $constants.assistant.content.briefs], currentSection)">

			<com-content-schedule v-if="currentSection === $constants.assistant.content.schedule" />
			<com-content-marking v-if="currentSection === $constants.assistant.content.marking" />
			<com-content-incidents v-if="currentSection === $constants.assistant.content.incidents" />
			<com-content-marksheets v-if="currentSection === $constants.assistant.content.marksheets" v-on:sheetChange="onSheetChange" />

		</com-content>

		<com-content v-on:click.native="is.menuOpen = false" :noscroll="true" v-if="$_.contains([$constants.assistant.content.inventories, $constants.assistant.content.briefs], currentSection)">

			<com-content-briefs v-if="currentSection === $constants.assistant.content.briefs" />
			<com-content-inventories v-if="currentSection === $constants.assistant.content.inventories" />

		</com-content>

		<com-panel />
		<com-modal />

		<com-foot v-if="!is.markingSheet" />

	</template>

</app-page>

</template>

<script>

import comHead from './assistant/Head'
import comContent from './assistant/Content'
import comFoot from './assistant/Foot'
import comMenu from './assistant/Menu'

import comModal from './shared/Modal'
import comPanel from './shared/Panel'
import comWelcome from './shared/Welcome'

import comSectionSchedule from './assistant/content/Schedule'
import comSectionMarksheets from './assistant/content/Marksheets'
import comSectionMarking from './assistant/content/Marking'
import comSectionBriefs from './assistant/content/Briefs'
import comSectionInventories from './assistant/content/Inventories'
import comSectionIncidents from './assistant/content/Incidents'

import {Howl} from 'howler'

export default {

	components: {
		'com-head': comHead,
		'com-content': comContent,
		'com-foot': comFoot,
		'com-panel': comPanel,
		'com-modal': comModal,
		'com-welcome': comWelcome,
		'com-menu': comMenu,
		'com-content-schedule': comSectionSchedule,
		'com-content-marksheets': comSectionMarksheets,
		'com-content-briefs': comSectionBriefs,
		'com-content-inventories': comSectionInventories,
		'com-content-incidents': comSectionIncidents,
		'com-content-marking': comSectionMarking
	},

	data: function() {

		return {
			buzzer: false,
			currentSection: this.$constants.assistant.content.schedule,
			is: {
				menuOpen: false,
				markingSheet: false
			}
		}

	},

	watch: {

		isReading: function(n) {

			if (n) {

				this.buzzer.play()

			}

		},

		isRoleplaying: function(n) {

			if (n) {
				
				this.is.markingSheet = this.isActive.identity
				this.currentSection = this.$constants.assistant.content.marksheets

				this.buzzer.play()

			}

		},

		activeRounds: function(n, o) {

			if (n.length && n.length !== o.length) {

				this.buzzer.play()

			}

		},

		activeRoleplays: function(n, o) {

			if (n.length && n.length !== o.length) {

				this.buzzer.play()

			}

		}

	},

	created: function() {

		if (this.isRoleplaying) {
			
			this.is.markingSheet = this.isActive.identity
			this.currentSection = this.$constants.assistant.content.marksheets

		}

		this.buzzer = new Howl({
			src: [this.$api.base() + '../files/buzzer.mp3'],
			html5: true
		})

		this.cacheBrief()

	},

	computed: {

		activeRounds: function() {

			var rounds = []
			var alreadyLocked = {}

			this.$_.each(this.$store.getters['overview'], function(item) {

				if(!this.$_.contains(rounds, item.start + item.circuit) && !alreadyLocked[item.circuit]) {

					if (!item.active) alreadyLocked[item.circuit] = true

					if (item.start <= this.time && item.active) rounds.push(item.start + item.circuit)

				}

			}.bind(this))

			return rounds

		},

		activeRoleplays: function() {

			var rounds = []
			var alreadyLocked = {}

			this.$_.each(this.$store.getters['overview'], function(item) {

				if(!this.$_.contains(rounds, item.roleplay + item.circuit) && !alreadyLocked[item.circuit]) {

					if (!item.active) alreadyLocked[item.circuit] = true

					if (item.roleplay <= this.time && item.active) rounds.push(item.roleplay + item.circuit)

				}

			}.bind(this))

			return rounds

		},

		isReading: function() {

			return (this.isStation) ? this.time >= this.isActive.start && this.time <= this.isActive.roleplay : false

		},

		isRoleplaying: function() {

			return (this.isStation) ? this.time >= this.isActive.roleplay && this.time <= this.isActive.end : false

		},

		roleplayEnd: function() {

			return (this.isActive.feedback) ? this.isActive.feedback : this.isActive.end

		},

		warningPoint: function() {

			return this.$store.getters['event/stations/warning']

		},

		isWarningActive: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 1 && remaining >= this.warningPoint - 1 

		},

		isRoleplayWarning: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 3 && remaining >= this.warningPoint - 3 

		},

		isRoleplayEnding: function() {

			return this.countdown(this.roleplayEnd, true) <= 5 

		}

	},

	methods: {

		cacheBrief: async function() {

			if (this.isExaminer || this.isLayExaminer) {

				var station = this.$_.find(this.$store.getters['schedule'], function(item) {
					
					return item.type === this.$constants.schedule.type.station

				}.bind(this))

				if (station) {

					if (station.brief.candidate) await this.$store.dispatch('shredded/cache', {
						type: 'brief',
						brief: 'candidate',
						identity: station.identity
					})

					if (station.brief.roleplayer) await this.$store.dispatch('shredded/cache', {
						type: 'brief',
						brief: 'roleplayer',
						identity: station.identity
					})

					if (station.brief.examiner) await this.$store.dispatch('shredded/cache', {
						type: 'brief',
						brief: 'examiner',
						identity: station.identity
					})

					if (station.brief.layexaminer) await this.$store.dispatch('shredded/cache', {
						type: 'brief',
						brief: 'layexaminer',
						identity: station.identity
					})

				}

			}

		},

		onLogoutClick: function() {

			this.$pubsub.$emit('modal.logout')

		},

		onSectionClick: function(value) {

			this.currentSection = value

			this.is.menuOpen = false

		},

		onMenuToggle: function() {

			this.is.menuOpen = !this.is.menuOpen

		},

		onSheetChange: function(value) {

			this.is.markingSheet = value

		}

	}

}

</script>

<style scoped>

.page {
	display: flex;
	flex-direction: column;
	overflow: visible!important;
	transition: all 100ms linear;
	margin-left: 0px;
}

.page.is-menu-open {
	margin-left: 240px;
}

.station-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 10000;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.station-warning.is-active {
	opacity: 1;
}

</style>