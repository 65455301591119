import pusher from '@/service/pusher'
import Vue from 'vue'
import pubsub from '@/service/pubsub'

export default {

	namespaced: true,

	state: {

		event: false

	},

	getters: {

		get: function(state) {

			return state.event

		},

		type: function(state) {

			return (state.event) ? state.event.type : false
			
		},

		identity: function(state) {

			return (state.event) ? state.event.identity : false

		},

		paused: function(state, getters, rootState, rootGetters) {

			return (state.event) ? state.event.pause.start && (rootGetters['time/true'] <= state.event.pause.end || !state.event.pause.end) : false

		},

		'paused/start': function(state) {

			return (state.event) ? state.event.pause.start : 0

		},

		'paused/end': function(state) {

			return (state.event) ? state.event.pause.end : 0

		},

		'paused/duration': function(state) {

			if (state.event) {

				return (state.event.pause.end) ? state.event.pause.end - state.event.pause.start : 0

			} else {

				return 0

			}

		},

		'paused/updates': function(state) {

			return (state.event) ? state.event.pause.updates : []

		},

		'notes/enabled': function(state) {

			return (state.event) ? state.event.notes.enabled : false

        },
        
		'stations/warning': function(state) {

			return (state.event) ? state.event.stations.warning : false

		},
        
		'marking/enabled': function(state) {

			return (state.event) ? state.event.marking.enabled : false

		},
        
		'marking/empty': function(state) {

			return (state.event) ? state.event.marking.empty : false

		},
        
		'marking/unlocked': function(state) {

			return (state.event) ? state.event.marking.unlocked : false

		},
        
		'chaperones/enabled': function(state) {

			return (state.event) ? state.event.chaperones.enabled : false

		},
        
		'delivery': function(state) {

			return (state.event) ? state.event.delivery : false

		},
        
		'shredding/enabled': function(state) {

			return (state.event) ? state.event.shredding.enabled : false

		},

		'onboarding/enabled': function(state) {

			return (state.event) ? state.event.onboarding.enabled : false

		},
        
		'assistant/enabled': function(state) {

			return (state.event) ? state.event.assistant.enabled : false

		},
        
		'assistant/content': function(state) {

			return (state.event) ? state.event.assistant.content : []

		},
        
		'playback/marking': function(state) {

			return (state.event) ? state.event.playback.marking : {}

		},
        
		'playback': function(state) {

			return (state.event) ? state.event.playback : {}

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'event', data)

		}

	},

	actions: {

		logout: function(context, data) {

			pubsub.$emit('modal.logout', data)
			
		},

		refresh: function() {

			window.location.href = window.location.pathname + '?quick'
			
		},

		init: function(context, data) {

			context.commit('set', data)

			// subscribe event channel
			pusher.subscribe.event(context.getters['identity'])

			// listen for event updates
			pusher.on('event', 'update', function(data) {

				context.commit('set', data)

			})

			pusher.on('event', 'logout', function(data) {

				context.dispatch('logout', data)

			})

			pusher.on('event', 'refresh', function(data) {

				context.dispatch('refresh', data)

			})

			pusher.on('user', 'logout', function(data) {

				context.dispatch('logout', data)
				
			})

			pusher.on('user', 'refresh', function(data) {

				context.dispatch('refresh', data)
				
			})

		}

	}

}