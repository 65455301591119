<template>
    
<com-card :onboardingPrefix="onboardingPrefix" class="notepad" :expanded="expanded" :type="$constants.card.type.notepad" v-on:expand="$emit('expand')" :expandable="canExpand" :title="item.title" :subtitle="item.subtitle">

    <div class="notepad-empty" v-if="!notes && !is.focussed">Write some notes...</div>

    <textarea class="notepad-input" v-on:focus="is.focussed = true" spellcheck="false" v-on:blur="is.focussed = false" v-model="notes" v-on:keyup="onChange" v-on:change="onChange"></textarea>

</com-card>

</template>

<script>

import comCard from './Card'

export default {

	props: ['item', 'canExpand', 'expanded', 'onboardingPrefix'],

	components: {

		'com-card': comCard

	},

    data: function() {

        return {
            is: {
                focussed: false
            },
            notes: ''
        }

    },

    created: function() {

        this.notes = ''

    },

    methods: {

        onChange: function() {

			//

        }

    }

}

</script>

<style scoped>

.notepad {
	background-color: #fff;
}

.notepad-empty {
    font-size: 32px;
    font-weight: 500;
    color: #ccc;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.notepad-input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    line-height: 18px;
    padding: 10px;
    resize: none;
}

</style>