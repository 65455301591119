<template>
    
<div class="sheet" :class="{'is-incomplete': !isValid, 'is-complete': isValid, 'is-saved': isSaved && !isChanged}">

    <div class="sheet-head">

        <div class="sheet-head-title">Mark Sheet</div>

        <div class="sheet-head-status">
             <div class="sheet-head-status-item is-completed" v-if="!isValid">{{ required.total - required.answered }} left</div>
             <div class="sheet-head-status-item is-completed" v-if="isValid"><i class="fa fa-check"></i></div>
        </div>

    </div>

    <div class="sheet-context">

        <div>{{ date | formatDate('DD/MM/YYYY') }}</div>
        <div>{{ $language.role.candidate }} #{{ reference }}</div>

    </div>

    <app-scroll ref="scroll" class="sheet-body" v-on:scroll="onScroll">

		<template v-for="question in questions">

			<com-question v-on:expand="$emit('expand', identity + question.identity)" v-on:collapse="$emit('collapse')" :expanded="expanded === identity + question.identity" v-if="!expanded || expanded === identity + question.identity" :question="question" :value="sheet[question.identity]" :key="question.identity" v-on:change="onQuestionChange" />

		</template>

    </app-scroll>

    <div class="sheet-foot">

        <app-button v-on:click="onSaveClick" :disabled="!isValid || (!isChanged && isSaved)" :loading="is.saving" theme="white" class="sheet-foot-button">{{ buttonText }}</app-button>
        <app-button v-on:click="onResetClick" v-if="isSaved && isChanged" theme="plain" class="sheet-foot-button">Reset changes</app-button>

    </div>

</div>

</template>

<script>

import Vue from 'vue'

import comQuestion from './sheet/Question'

export default {

	props: ['candidate', 'identity', 'expanded', 'saved', 'session', 'validSave'],

    components: {
        'com-question': comQuestion
    },

    data: function() {

        return {
			timer: false,
            is: {
				expanded: false,
                valid: false,
                saving: false
			},
			sheet: {},
			scrollPosition: 0,
			lastScrollPosition: 0
        }

    },

    created: function() {

		this.setupCandidate()

    },

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	watch: {

		expanded: function(n) {

			if (n) {
				
				this.lastScrollPosition = this.scrollPosition

			} else {

				this.$nextTick(function() {
					
					this.$refs.scroll.scrollTo(this.lastScrollPosition)

				}.bind(this))

			}

		}

	},

    computed: {

		isChanged: function() {

			return !this.$_.isEqual(this.saved, this.session)

		},

		isSaved: function() {

			return this.validSave

		},

		isValid: function() {

			return this.required.total === this.required.answered

		},

		answered: function() {

			var answered = []

			this.$_.each(this.sheet, function(mark, identity) {

				if (mark || mark === 0) {

					answered.push(identity)

				}

			}.bind(this))

			return answered

		},

        buttonText: function() {

			if (this.isChanged) {

				return (this.isSaved) ? 'Save changes' : 'Save' 

			} else {

				return (this.isSaved) ? 'Saved' : 'Save' 

			}

        },

        reference: function() {

            return (this.candidate) ? this.$store.getters['people'][this.candidate].reference : false

        },

        questions: function() {

            return this.$store.getters['marking/questions'][this.identity]

        },

        total: function() {

            return this.questions.length

		},
		
		required: function() {

			var required = {
				total: 0,
				answered: 0
			}

			this.$_.each(this.questions, function(question) {

				if (question.required) {

					required.total++

					if (this.$_.contains(this.answered, question.identity)) {

						required.answered++

					}

				}

			}.bind(this))

			return required 

		},

        date: function() {

            return this.$store.getters['event'].date

        }

    },

    methods: {

		onScroll: function(value) {

			this.scrollPosition = value

		},

		autosave: function() {

			clearTimeout(this.timer)

			this.$api.request('marking/save', {
				identity: this.identity,
				marks: this.sheet,
				autosave: true
			})

		},

		setupCandidate: function() {

			this.is.saving = false

			this.sheet = (this.session) ? JSON.parse(JSON.stringify(this.session)) : {}

			// this.autosave()

		},

		onResetClick: function() {

			clearTimeout(this.timer)

			this.sheet = (this.saved) ? JSON.parse(JSON.stringify(this.saved)) : {}

			this.$emit('change', {
				sheet: this.sheet,
				schedule: this.identity
			})

			this.autosave()

		},

        onSaveClick: function() {

			clearTimeout(this.timer)

            this.is.saving = true

            this.$api.request('marking/save', {
                identity: this.identity,
				marks: this.sheet,
				autosave: false
            }).then(function() {

				this.is.saving = false
				
				this.$emit('saved', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})
				
            }.bind(this), function() {

                this.is.saving = false

            }.bind(this))

        },

        onQuestionChange: function(e) {

			if (e.value !== this.sheet[e.identity]) {

				clearTimeout(this.timer)

				Vue.set(this.sheet, e.identity, e.value)
				
				this.$emit('change', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})

				this.timer = this.$_.delay(this.autosave.bind(this), 5000)
				
			}

        }

    }

}

</script>

<style scoped>

.sheet {
	width: 100%;
	height: 100%;
	display: flex;
    background-color: #237DC6;
	transition: background-color 100ms linear;
    flex-direction: column;
}

.sheet.is-saved {
    background-color: #27a258;
}

.sheet-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sheet-head-title {
    flex-grow: 1;
}

.sheet-head-status {
    flex-shrink: 0px;
    display: flex;
    flex-direction: row;
}

.sheet-head-status-item {
    background-color: #fff;
    color: #237DC6;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
	white-space: nowrap;
    line-height: 25px;
	padding: 0px 5px;
    text-align: center;
    transition: all 100ms linear;
}

.sheet-head-status-item.is-completed {
    border-radius: 4px;
}

.sheet.is-incomplete .sheet-head-status-item.is-completed {
    color: #cc3939;
}

.sheet.is-complete .sheet-head-status-item.is-completed {
    color: #27a258;
}

.sheet-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.sheet-body {
    flex-grow: 1;
    flex-basis: 0;
}

.sheet-foot {
	padding: 10px;
    flex-shrink: 0;
}

.sheet-foot-button {
    width: 100%;
}

.sheet-foot-button.theme-plain {
	color: #fff!important;
	margin-top: 4px;
}

.sheet.is-saved .sheet-foot-button {
    color: #27a258;
}

</style>