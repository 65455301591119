<template>

<div class="room">

	<app-room-grid v-if="is.connected" :pinsSpecial="pinsSpecial" :pinsCurrent="pinned" :participantsCurrent="participants" :cardsCurrent="cards" :pinsLoading="is.pinning" :pinsEnabled="!pinsDisabled && !$_.contains(screenshares, is.expanded)" :pinsEditable="pinsEditable" pinsLimit="2" :pinsHidden="pinsHidden">

		<app-room-participant v-for="participant in participants" :miniview="isSharing && $_.contains(screenshares, is.expanded) && is.expanded !== participant" :expanded="is.expanded === participant" :expandable="$_.contains(screenshares, participant)" v-on:expand="onExpand(participant)" v-on:collapse="onCollapse(participant)" :allowFullsceen="$_.contains(screenshares, participant)" :showScreenshare="true" v-on:screenStart="onScreenStart(participant)" v-on:screenEnd="onScreenEnd(participant)" :key="participant" :person="people[participant]" :stream="streams[participant]" :speaking="participant === speaking" v-on:canplay="onCanPlay(participant)" v-on:pin="onPin(participant)" />

		<template v-for="card in cardsByType($constants.card.type.file)">

			<app-room-file :key="card.identity" :showNote="card.identity === is.expanded || (isMeeting && participants.length < 9)" :expanded="card.identity === is.expanded" :item="card" v-on:trigger="onTrigger(card.identity)" v-on:expand="onExpand(card.identity)" v-show="!$_.contains(screenshares, is.expanded)" />

		</template>

		<template v-for="card in cardsByType($constants.card.type.notepad)">

			<app-room-notepad :key="card.identity" :canExpand="true" :item="card" :expanded="card.identity === is.expanded" v-on:expand="onExpand(card.identity)" v-show="!$_.contains(screenshares, is.expanded)" />

		</template>

	</app-room-grid>

	<app-room-status v-if="is.disconnected" :type="$constants.status.loading" text="Disconnected, please refresh to re-join." />

	<app-room-status v-if="is.reconnecting" :type="$constants.status.loading" text="Reconnecting, please wait..." />

	<app-room-status v-if="is.connecting" :type="$constants.status.loading" text="Connecting, please wait..." />

	<app-room-status v-if="is.failed" :type="$constants.status.errored" text="Failed to connect, please refresh to try again." />

</div>

</template>

<script>

import Vue from 'vue'

import {connect} from 'twilio-video'

export default {

	props: ['item', 'cards', 'pinsDisabled', 'pinsHidden', 'pinsSpecial', 'forceLocal', 'pinsEditable', 'showLocalPinned'],

	data: function() {

		return {

			is: {
				connected: false,
				connecting: true,
				failed: false,
				disconnected: false,
				pinning: false,
				expanded: false,
				triggering: false
			},

			playbackReady:  [],
			screenshares: [],
			streams: {},
			mirrors: [],
			screen: false,
			speaking: false,
			room: false

		}

	},

	computed: {

		isMirrored: function() {

			return this.$isWebinar && this.totalMirrors > 1

		},

		totalMirrors: function() {

			var allRoamers = this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.observer && this.$_.contains([this.$constants.observer.scope.event, this.$constants.observer.scope.circuit, this.$constants.observer.scope.circuits], person.scope)

			}.bind(this))

			return Math.ceil((this.isActive.observers.length + this.isActive.participants.length + allRoamers.length) / this.$constants.mirror.perRoom)

		},

		orderedLogins: function() {

			var logins = this.$_.filter(this.$store.getters['people'], function(person) {

				return this.$_.contains(this.isActive.observers, person.identity) || this.$_.contains(this.isActive.participants, person.identity) || (person.role === this.$constants.role.observer && this.$_.contains([this.$constants.observer.scope.event, this.$constants.observer.scope.circuit, this.$constants.observer.scope.circuits], person.scope))

			}.bind(this))

			return this.$_.pluck(logins, 'identity')

		},

		localMirror: function() {

			var index = this.$_.indexOf(this.orderedLogins, this.$store.getters['people/self'].identity)

			return (index === -1) ? 1 : Math.ceil((index + 1) / this.$constants.mirror.perRoom)

		},

		isSharing: function() {

			return this.screenshares.length

		},

		pinned: function() {

			var pinned = []

			if (this.is.expanded) {

				pinned.push(this.is.expanded)

			} else {

				this.$_.each(this.item.pinned, function(participant) {

					if (participant !== this.$store.getters['session/identity'] || this.showLocalPinned) {

						pinned.push(participant)

					}

				}.bind(this))
					
				if (this.isStation) {

					if (this.$store.getters['people'][pinned[0]].role !== this.$constants.role.candidate) {

						pinned.reverse()

					}

				}

			}

			return pinned

		},

		participants: function() {

			var participants = []

			this.$_.each(this.item.participants, function(participant) {

				if (participant !== this.$store.getters['session/identity'] || this.$_.contains(this.pinned, participant) || this.forceLocal) {

					participants.push(participant)

				}

			}.bind(this))

			return participants

		},

		people: function() {

			return this.$store.getters['people']

		},

		localAudio: function() {

			return this.$store.getters['settings/audio']

		},

		localVideo: function() {

			return this.$store.getters['settings/video']

		},

		localScreen: function() {

			return this.$store.getters['device/screen']

		},

		roomIdentity: function() {

			return (this.isMirrored) ? this.item.identity + '-' + this.localMirror : this.item.identity

		},

		connected: function() {

			return this.is.connected

		}

	},

	watch: {

		connected: function(n) {

			if (this.$isWebinar && this.isParticipating) {

				if (n) {

					this.startMirror()

				} else {

					this.stopMirror()

				}

			}

		},

		participants: function() {
		
			if (this.is.expanded) {

				if (!this.$_.contains(this.participants, this.is.expanded) && !this.$_.contains(this.$_.pluck(this.cards, 'identity'), this.is.expanded)) this.is.expanded = false

			}

		},

		cards: function() {
		
			if (this.is.expanded) {

				if (!this.$_.contains(this.participants, this.is.expanded) && !this.$_.contains(this.$_.pluck(this.cards, 'identity'), this.is.expanded)) this.is.expanded = false

			}

		},

		localScreen: function() {

			if (!this.$isActivePlayback) this.setLocalScreen()

		},

		localAudio: function() {

			if (!this.$isActivePlayback) this.setLocalAudio()

		},

		localVideo: function() {

			if (!this.$isActivePlayback) this.setLocalVideo()

		},

		isParticipating: function(n) {

			if (this.$isWebinar) {

				if (n) {

					this.$store.commit('settings/video', true)
					this.$store.commit('settings/audio', true)

					if (this.isMirrored) this.startMirror()

				} else {

					this.$store.commit('settings/video', false)
					this.$store.commit('settings/audio', false)

					if (this.isMirrored) this.stopMirror()

				}

			}

		}

	},

	created: function() {
			
		if (this.$isActivePlayback) {

			this.$pubsub.$on('room.skip', this.onRoomSkip.bind(this))

		} else {

			this.$prefix = ['room', this.roomIdentity]

			var videoOn = false
			
			if (this.isStation) {

				if (this.isActive.permissions.video) {

					videoOn = this.isParticipating

				} else {

					videoOn = !this.isCandidate && !this.isRoleplayer && (!this.isExaminer || !this.isStationExaminerOnly) && this.isParticipating

				}

			} else {

				videoOn = this.isParticipating

			}

			this.$store.commit('settings/audio', (this.isStation) ? this.isCandidate || this.isRoleplayer || (this.isStationExaminerOnly && this.isExaminer) : false)
			this.$store.commit('settings/video', videoOn)

		}

		window.addEventListener('beforeunload', this.leave.bind(this))
		
		this.join()

	},

	beforeDestroy: function() {

		if(this.$isActivePlayback) {

			this.$pubsub.$off('room.skip', this.onRoomSkip.bind(this))

		}

		window.removeEventListener('beforeunload', this.leave.bind(this))

		this.leave()

	},
	
	methods: {

		onRoomSkip: function() {

			this.playbackReady = []

		},

		onCanPlay: function(participant) {

			this.playbackReady.push(participant)

			if (this.playbackReady.length === this.$isActivePlayback.count) this.$emit('canplay')

		},

		startMirror: function() {

			for (var i = 1; i <= this.totalMirrors; i++) {

				if (i !== this.localMirror) {

					var mirrorIndex = i

					var config = {
						name: this.item.identity + '-' + mirrorIndex.toString(),
						dominantSpeaker: true,
						audio: true,
						video: true,
						preferredVideoCodecs: [
							{ codec: 'VP8', simulcast: true }
						],
						networkQuality: {
							local: 1,
							remote: 1
						}
					}

					if (this.$store.getters['device/ios']) config.tracks = this.$store.getters['device/tracks']

					connect(this.$store.getters['session/token'], config).then(function(room) {

						this.$log('mirror connected', room.name)

						this.mirrors.push(room)

						room.on('disconnected', function() {

							this.$log('mirror disconnected', room.name)

						}.bind(this))

					}.bind(this), function(e) {

						this.$log('mirror failed', e)

					}.bind(this))

				}

			}

		},

		stopMirror: function() {

			this.$_.each(this.mirrors, function(mirror) {

				mirror.disconnect()

			})

			this.mirrors = []

		},

		onScreenStart: function(participant) {

			this.screenshares.push(participant)
			this.$store.commit('room/screensharing', true)
			if (participant !== this.$store.getters['session/identity']) this.is.expanded = participant

		},

		onScreenEnd: function(participant) {

			this.screenshares.splice(this.screenshares.indexOf(participant), 1)
			this.$store.commit('room/screensharing', false)
			this.is.expanded = (this.is.expanded === participant) ? false : this.is.expanded

		},

        cardsByType: function(type) {

            return this.$_.where(this.cards, {
                type: type
            })

		},
		
		setLocalScreen: function() {

			if (this.is.connected) {

				if (this.localScreen) {

					this.screen = this.localScreen

					this.room.localParticipant.publishTrack(this.screen)

					this.$_.each(this.mirrors, function(mirror) {

						mirror.localParticipant.publishTrack(this.screen)

					}.bind(this))

					this.onScreenStart(this.$store.getters['session/identity'])

				} else {

					this.room.localParticipant.unpublishTrack(this.screen)

					this.$_.each(this.mirrors, function(mirror) {

						mirror.localParticipant.unpublishTrack(this.screen)

					}.bind(this))

					this.screen.stop()
					this.screen = false
					this.onScreenEnd(this.$store.getters['session/identity'])

				}

			}

		},

		setLocalAudio: function() {

			if (this.is.connected) {

				this.room.localParticipant.audioTracks.forEach(function(publication) {

					if (this.localAudio) {

						if (!publication.isTrackEnabled) publication.track.enable()

					} else {

						if (publication.isTrackEnabled) publication.track.disable()

					}

				}.bind(this))

				this.$_.each(this.mirrors, function(mirror) {

					mirror.localParticipant.audioTracks.forEach(function(publication) {

						if (this.localAudio) {

							if (!publication.isTrackEnabled) publication.track.enable()

						} else {

							if (publication.isTrackEnabled) publication.track.disable()

						}

					}.bind(this))

				}.bind(this))

			} 

		},

		setLocalVideo: function() {

			if (this.is.connected) {

				this.room.localParticipant.videoTracks.forEach(function(publication) {

					if (this.localVideo) {

						if (!publication.isTrackEnabled) publication.track.enable()

					} else {

						if (publication.isTrackEnabled) publication.track.disable()

					}

				}.bind(this))

				this.$_.each(this.mirrors, function(mirror) {

					mirror.localParticipant.videoTracks.forEach(function(publication) {

						if (this.localVideo) {

							if (!publication.isTrackEnabled) publication.track.enable()

						} else {

							if (publication.isTrackEnabled) publication.track.disable()

						}

					}.bind(this))

				}.bind(this))
			
			} 

		},

		join: function() {

			if (this.$isActivePlayback) {

				this.is.connecting = false
				this.is.connected = true

			} else {

				this.is.connecting = true
				this.is.failed = false
				this.streams = {}

				this.$store.commit('room/reset')

				var video = {
					deviceId: this.$store.getters['device/video/current']
				}
				
				if (this.item.participants > 10) {

					video.width = 320

				}

				var audio = {
					deviceId: this.$store.getters['device/audio/current']
				}

				var config = {
					name: this.roomIdentity,
					dominantSpeaker: true,
					audio: audio,
					video: video,
					preferredVideoCodecs: [
						{ codec: 'VP8', simulcast: true }
					],
					networkQuality: {
						local: 1,
						remote: 1
					}
				}

				config.tracks = this.$store.getters['device/tracks']

				connect(this.$store.getters['session/token'], config).then(function(room) {

					this.$log('connected')

					this.room = room

					this.is.connecting = false
					this.is.connected = true

					this.setLocalVideo()
					this.setLocalAudio()

					Vue.set(this.streams, this.room.localParticipant.identity, this.room.localParticipant)

					this.$store.commit('room/streaming/add', this.$store.getters['session/identity'])

					this.room.localParticipant.on('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

					this.$store.commit('room/quality', this.room.localParticipant.networkQualityLevel)

					this.room.on('participantConnected', this.onParticipantConnected.bind(this))
					this.room.on('participantReconnected', this.onParticipantReconnected.bind(this))
					this.room.on('participantDisconnected', this.onParticipantDisconnected.bind(this))
					this.room.on('dominantSpeakerChanged', this.onSpeakerChanged.bind(this))

					this.room.on('reconnecting', this.onReconnecting.bind(this))
					this.room.on('reconnected', this.onReconnected.bind(this))
					this.room.on('disconnected', this.onDisconnected.bind(this))

					this.room.participants.forEach(function(participant) {

						Vue.set(this.streams, participant.identity, participant)

						this.$store.commit('room/streaming/add', participant.identity)

					}.bind(this))

				}.bind(this), function(e) {

					this.$log('failed', e)

					this.is.connecting = false
					this.is.reconnecting = false
					this.is.failed = true

				}.bind(this))

			}

		},

		onParticipantConnected: function(participant) {

			this.$log('participant.connected', participant.identity)

			this.$store.commit('room/streaming/add', participant.identity)

			Vue.set(this.streams, participant.identity, participant)

		},

		onParticipantReconnected: function(participant) {

			this.$log('participant.reconnected', participant.identity)

			this.$store.commit('room/streaming/add', participant.identity)

			Vue.set(this.streams, participant.identity, participant)

		},

		onParticipantDisconnected: function(participant) {

			this.$log('participant.disconnected', participant.identity)

			this.$store.commit('room/streaming/remove', participant.identity)

			this.streams[participant.identity] = false

		},

		onSpeakerChanged: function(participant) {

			this.$log('speaker.changed')

			this.speaking = (participant) ? participant.identity : false

		},

		onNetworkQualityLevelChange: function(level) {

			this.$store.commit('room/quality', (level) ? level : false)

		},

		onReconnecting: function() {

			this.is.connected = false
			this.is.reconnecting = true

		},

		onReconnected: function() {

			this.is.connected = true
			this.is.reconnecting = false

			this.$store.commit('room/streaming/add', this.$store.getters['session/identity'])

		},

		onDisconnected: function() {

			this.$log('disconnected')

			this.is.connected = false
			this.is.disconnected = true

			this.$store.commit('room/streaming/remove', this.$store.getters['session/identity'])

			this.leave()

		},
		
		onExpand: function(identity) {

			this.is.expanded = (this.is.expanded === identity) ? false : identity

		},
		
		onCollapse: function(identity) {

			this.is.expanded = (this.is.expanded === identity) ? false : this.is.expanded

		},

		onPin: function(participant) {

			if (!this.is.pinning) {

				this.is.pinning = participant

				this.$api.request('room/pin', {
					room: this.item.identity,
					participant: participant
				}).then(function() {

					this.is.pinning = false

				}.bind(this), function() {

					this.is.pinning = false

				}.bind(this))
			
			}

		},

		onTrigger: function(inventory) {

			if (!this.is.triggering) {

				this.is.triggering = inventory

				this.$api.request('room/trigger', {
					room: this.item.identity,
					inventory: inventory
				}).then(function() {

					this.is.triggering = false

				}.bind(this), function() {

					this.is.triggering = false

				}.bind(this))
			
			}

		},

		leave: function() {

			if (this.$isActivePlayback) {

				this.is.connected = false

			} else {

				if (this.is.connected) {

					if (this.room) this.room.disconnect()

					this.stopMirror()

					this.is.connected = false

				}

				if (this.room) {

					this.room.localParticipant.removeListener('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

					this.room.removeListener('participantConnected', this.onParticipantConnected.bind(this))
					this.room.removeListener('participantReconnected', this.onParticipantReconnected.bind(this))
					this.room.removeListener('participantDisconnected', this.onParticipantDisconnected.bind(this))
					this.room.removeListener('dominantSpeakerChanged', this.onSpeakerChanged.bind(this))

					this.room.removeListener('reconnecting', this.onDisconnected.bind(this))
					this.room.removeListener('reconnected', this.onDisconnected.bind(this))
					this.room.removeListener('disconnected', this.onDisconnected.bind(this))

					this.room = false

				}

				this.$store.commit('device/screen', false)
				this.$store.commit('room/quality', false)
				this.$store.commit('room/screensharing', false)
				
			}

			this.$store.commit('settings/video', true)
			this.$store.commit('settings/audio', true)

		}

	}

}

</script>

<style scoped>

.room {
	width: calc(100% + 10px);
	height: calc(100% + 10px);
	z-index: 1;	
}

.room.is-connecting {
	background-image: url('data:image/gif;base64,R0lGODlhKwALAPEAAP///yN9xpO/4iN9xiH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAAKAAEALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQACgACACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkEAAoAAwAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

</style>
