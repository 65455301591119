<template>

<div class="circuits">

	<div class="circuits-item" v-on:click="onClick(circuit.identity)" :class="{'is-active': circuit.identity === current}" v-for="circuit in circuits" :key="circuit.identity">

		<span class="circuits-item-colour" v-if="circuit.colour" :style="{backgroundColor: circuit.colour}"></span>{{ circuit.name }}

	</div>

</div>

</template>

<script>

export default {

	computed: {

		current: function() {

			return this.$store.getters['circuits/current']

		},

		circuits: function() {

			return this.$_.filter(this.$store.getters['circuits'], function(circuit) {

				return circuit.view === true

			})

		}

	},

	methods: {

		onClick: function(identity) {

			this.$store.commit('circuits/current', identity)

		}

	}

}

</script>

<style scoped>

.circuits {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 40px;
	z-index: 2;
}

.circuits-item {
	font-size: 16px;
	font-weight: 300;
	color: #333;
	min-width: 100px;
	height: 39px;
	cursor: pointer;
	line-height: 40px;
	padding: 0px 10px;
	display: flex;
	align-items: center;
}

.circuits-item.is-active {
	background-color: #fff;
	border-radius: 4px 4px 0px 0px;
	font-weight: 500;
	border: 1px solid #ddd;
	border-bottom-color: #fff;
	line-height: 39px;
	height: 41px;
}

.circuits-item-colour {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 6px;
	border-radius: 50%;
}

</style>