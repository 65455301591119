<template>
    
<div class="question" :class="{'is-answered': answer, 'is-row': question.type === $constants.marking.question.type.check}">

    <div class="question-text" v-html="question.text"></div>

    <div class="question-scale" v-if="question.type === $constants.marking.question.type.scale">

        <div class="question-scale-item" :class="{'is-active': parseInt(value) - 1 === answer}" v-for="value in question.scale + 1" :key="value - 1" v-on:click="onScaleClick(parseInt(value) - 1)">
            {{ value - 1 }}
        </div>

    </div>

    <div class="question-check" v-if="question.type === $constants.marking.question.type.check" :class="{'is-active': answer}" v-on:click="onCheckClick(parseInt(value))">
        <i class="fa fa-check"></i>    
    </div>

    <div class="question-toggle" v-if="question.type === $constants.marking.question.type.toggle">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === answer}" v-for="(text, value) in toggle" :key="value" v-on:click="onScaleClick(parseInt(value))">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle" v-if="question.type === $constants.marking.question.type.pbf">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === answer}" v-for="(text, value) in pbf" :key="value" v-on:click="onScaleClick(parseInt(value))">
            {{ text }}
        </div>
    </div>

    <div class="question-toggle" v-if="question.type === $constants.marking.question.type.epbf">
        <div class="question-toggle-item" :class="{'is-active': parseInt(value) === answer}" v-for="(text, value) in epbf" :key="value" v-on:click="onScaleClick(parseInt(value))">
            {{ text }}
        </div>
    </div>

    <textarea v-autogrow ref="textarea" class="question-textarea" spellcheck="false" v-if="question.type === $constants.marking.question.type.text" :class="{'is-active': answer}" v-on:change="onTextChange" v-on:keydown="onTextChange" v-on:up="onTextChange" v-model="answer"></textarea>

</div>

</template>

<script>

import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'

export default {

    props: ['question', 'value'],

    directives: {
		'autogrow': TextareaAutogrowDirective
    },

    data: function() {

        return {
            toggle: {
                1: 'Yes',
                2: 'No'
			},
			pbf: {
				1: 'Pass',
				2: 'Borderline',
				3: 'Fail'
			},
			epbf: {
				1: 'Excellent Pass',
				2: 'Pass',
				3: 'Borderline',
				4: 'Fail'
			},
			text: '',
            answer: false
        }

    },

    watch: {

        answer: function(n) {

            this.$emit('change', {
                identity: this.question.identity,
                value: n
            })

        },
        
        value: function(n) {

			this.answer = n
			this.text = n

        }

    },

    created: function() {

		this.answer = this.value
		this.text = this.value

    },

    methods: {

        onCheckClick: function() {

            this.answer = !this.answer

        },

        onScaleClick: function(value) {

            this.answer = (value === this.answer) ? null : value

        },

        onTextChange: function() {

            this.answer = this.$refs.textarea.value

        }

    }

}

</script>

<style scoped>

.question {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 20px;
    user-select: none;
    transition: all 100ms linear;
}

.question.is-row {
    flex-direction: row;
}

.question-text {
    font-size: 18px;
    line-height: 24px;
    color: #333;
    font-weight: 300;
    margin-bottom: 10px;
}

.question-text >>> p {
	margin-bottom: 16px;
}

.question.is-row .question-text {
    flex-grow: 1;
    margin-bottom: 0px;
}

.question-textarea {
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    resize: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    color: #333;
    padding: 8px 10px;
    transition: all 100ms linear;
}

.question-textarea.is-active {
    background-color: #ddd;
    color: #333;
}

.sheet.is-saved .question-textarea.is-active {
    background-color: #27a258;
    color: #fff;
}

.question-check {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 42px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    transition: all 100ms linear;
}

.question-check:hover,
.question-check.is-active {
    background-color: #237DC6;
    color: #fff;
}

.sheet.is-saved .question-check.is-active {
    background-color: #27a258;
}

.question-toggle,
.question-scale {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.question-toggle-item,
.question-scale-item {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 1px solid #fff;
    cursor: pointer;
    transition: all 100ms linear;
}

.question-toggle-item.is-active,
.question-toggle-item:hover,
.question-scale-item.is-active,
.question-scale-item:hover {
    color: #fff;
    background-color: #237DC6;
}

.sheet.is-saved .question-toggle-item:hover,
.sheet.is-saved .question-scale-item:hover,
.sheet.is-saved .question-toggle-item.is-active,
.sheet.is-saved .question-scale-item.is-active {
    background-color: #27a258;
}

.question.is-answered .question-toggle-item:not(.is-active):hover,
.question.is-answered .question-scale-item:not(.is-active):hover {
    background-color:rgba(0, 0, 0, 0.1);
}

.question-toggle-item:first-child,
.question-scale-item:first-child {
    border-radius: 4px 0px 0px 4px;
}

.question-toggle-item:last-child,
.question-scale-item:last-child {
    border-radius: 0px 4px 4px 0px;
}

.question-toggle-item.is-active,
.question-scale-item.is-active {
    border-radius: 4px;
}

</style>