<template>

<app-modal :active="is.active">

	<template v-slot:head>
		<h1>Create ad hoc station</h1>
	</template>

	<p>If a {{ $language.role.candidate }} needs to re-take a station you can add it here and their schedule will automatically update. You must also select the relevant {{ $language.role.roleplayer }}, {{ $language.role.examiner }}, and {{ $language.role.layexaminer }} too.</p>

	<select class="select" v-model="input.station" :disabled="is.loading" v-if="stations.length">
		<option value="0">Select the station...</option>
		<option v-for="station in stations" :key="station.identity" :value="station.identity">{{ station.name }}<template v-if="station.type === $constants.station.type.backup"> (Backup)</template></option>
	</select>

	<select class="select" v-model="input.candidate" :disabled="is.loading" v-if="candidates.length">
		<option value="0">Select the {{ $language.role.candidate }}...</option>
		<option v-for="candidate in candidates" :key="candidate.identity" :value="candidate.identity">{{ candidate.name }} ({{circuitName(candidate)}})</option>
	</select>

	<select class="select" v-model="input.examiner" :disabled="is.loading" v-if="examiners.length">
		<option value="0">Select the {{ $language.role.examiner }}...</option>
		<option v-for="examiner in examiners" :key="examiner.identity" :value="examiner.identity">{{ examiner.name }} ({{circuitName(examiner)}})</option>
	</select>

	<select class="select" v-model="input.roleplayer" :disabled="is.loading" v-if="roleplayers.length && hasRoleplayer">
		<option value="0">Select the {{ $language.role.roleplayer }}...</option>
		<option v-for="roleplayer in roleplayers" :key="roleplayer.identity" :value="roleplayer.identity">{{ roleplayer.name }} ({{circuitName(roleplayer)}})</option>
	</select>

	<select class="select" v-model="input.layexaminer" :disabled="is.loading" v-if="layexaminers.length && hasLayExaminer">
		<option value="0">Select the {{ $language.role.layexaminer }}...</option>
		<option v-for="layexaminer in layexaminers" :key="layexaminer.identity" :value="layexaminer.identity">{{ layexaminer.name }} ({{circuitName(layexaminer)}})</option>
	</select>

	<select class="select" v-model="input.observer" :disabled="is.loading" v-if="observers.length">
		<option value="0">Select an optional {{ $language.role.observer }}...</option>
		<option v-for="observer in observers" :key="observer.identity" :value="observer.identity">{{ observer.name }}</option>
	</select>

	<p>Ensure the time you enter does not overlap with any other existing scheduled stations for this {{ $language.role.candidate }}, {{ $language.role.roleplayer }}, {{ $language.role.examiner }}, or {{ $language.role.layexaminer }}.</p>

	<input class="input" v-model="input.time" :disabled="is.loading" placeholder="HH:MM" />

	<template v-slot:buttons>
		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Create</app-button>
		<app-button :disabled="is.loading" v-on:click="onClose">Cancel</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'adhoc',

			input: {
				roleplayer: 0,
				observer: 0,
				examiner: 0,
				layexaminer: 0,
				candidate: 0,
				station: 0,
				time: ''
			}

		}

	},

	computed: {

		isBackup: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].type === this.$constants.station.type.backup : false

		},

		hasRoleplayer: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].roleplayer : false

		},

		hasLayExaminer: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].layexaminer : false

		},

		candidates: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.candidate && !person.empty

			}.bind(this)), 'name')

		},

		roleplayers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.roleplayer

			}.bind(this)), 'name')

		},

		examiners: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.examiner

			}.bind(this)), 'name')

		},

		layexaminers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.layexaminer

			}.bind(this)), 'name')

		},

		observers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.observer

			}.bind(this)), 'name')

		},

		stations: function() {

			return this.$store.getters['stations']

		}

	},

	methods: {

		reset: function() {

			this.input.candidate = 0
			this.input.examiner = 0
			this.input.roleplayer = 0
			this.input.observer = 0
			this.input.station = 0
			this.input.time = ''

		},

		circuitName: function(person) {

			return this.$store.getters['circuits/associated'][person.circuit].name

		},

		onInputChange: function(n) {

			this.is.valid = n.candidate && n.station && n.time && (!this.isBackup || n.examiner) && (!this.isBackup || !this.hasRoleplayer || n.roleplayer)

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('adhoc/create', {
				candidate: this.input.candidate,
				roleplayer: this.input.roleplayer,
				examiner: this.input.examiner,
				layexaminer: this.input.layexaminer,
				observer: this.input.observer,
				station: this.input.station,
				time: this.input.time
			}).then(function() {

				this.onClose()
				this.$pubsub.$emit('observer.section', this.$constants.observer.schedule)

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
}

.input {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 40px;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
